import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { useStyles } from '../styles';
import useLongPress from '@hooks/longPressHook';
import { noteContent, defaultOptions } from './helpers';

const NoteComponent = ({
    note,
    deleting,
    setDeleting,
    handleAddOrRemoveFromList,
    deleteList,
}) => {
    const classes = useStyles();
    const history = useHistory();

    const onLongPress = () => {
        if (!deleting) {
            setDeleting(true);
        }
        handleAddOrRemoveFromList(note.id);
    };

    const onClick = () => {
        if (deleting) {
            handleAddOrRemoveFromList(note.id);
        } else {
            history.push(`notas/edit/${note.id}`);
        }
    };

    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    return (
        <Grid item xs={5} key={note.id} {...longPressEvent} note_id={note.id}>
            {deleting && (
                <div
                    className={classes.deleteIndicator}
                    style={{
                        backgroundColor: deleteList.includes(note.id)
                            ? 'blue'
                            : 'rgb(201, 201, 201, 0.5)',
                    }}
                />
            )}
            <Paper
                deleting={deleting}
                component='p'
                elevation={2}
                className={`${deleting ? classes.gridSelected : classes.grid}`}>
                {noteContent(note.text)}
            </Paper>
        </Grid>
    );
};

export default NoteComponent;

NoteComponent.propTypes = {
    note: PropTypes.object,
    deleting: PropTypes.bool,
    setDeleting: PropTypes.func,
    handleAddOrRemoveFromList: PropTypes.func,
    deleteList: PropTypes.array,
}