import React from 'react';
import PropTypes from 'prop-types';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { styles } from './styles';

const CuantityCont = ({
    count,
    classes,
    id = 'cuantityOfProducts',
    setCount,
}) => {

    return (
        <>
            <IconButton
                aria-label='Remove'
                onClick={() => setCount(count - 1)}>
                <Remove />
            </IconButton>
            <TextField
                id={id}
                type='number'
                value={count}
                onChange={e => setCount(e.target.value)}
                className={classes.numberField}
                InputLabelProps={{
                    shrink: true,
                }}
                margin='normal'
            />
            <IconButton
                aria-label='Add'
                onClick={() => setCount(count + 1)}>
                <Add />
            </IconButton>
        </>
    );
};

CuantityCont.propTypes = {
    count: PropTypes.number,
    classes: PropTypes.object,
    id: PropTypes.string,
    setCount: PropTypes.func,
}

export default withStyles(styles)(CuantityCont);