import React from 'react';
import PropTypes from 'prop-types'

import {
    Grid,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    withStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import distributionStates from '@constants/distributionStates';
import { DatePickerSetup } from '../../../../utils/ConfigSetup';
import { styles } from '../styles';

const DistributionFilters = ({
    deliveryMans,
    classes,
    date,
    setDate,
    deliveryMan,
    setDeliveryMan,
    deliveryState,
    setDeliveryState,
}) => {
    return (
        <Grid
            container
            spacing={2}
            direction='column'
            alignItems='center'
            className={classes.selector}>
            <Grid item xs={12}>
                <div className={classes.datePicker}>
                    <DatePicker
                        label='Fecha : '
                        value={date}
                        onChange={newDate => setDate(newDate)}
                        views={DatePickerSetup}
                        variant='dialog'
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant='outlined'>
                    <InputLabel id='deliveryManLabel'>Repartidor</InputLabel>
                    <Select
                        className={classes.inputWidth}
                        labelId='deliveryManLabel'
                        value={deliveryMan}
                        label='Repartidor'
                        onChange={event => {
                            setDeliveryMan(event.target.value);
                        }}>
                        {deliveryMans.map(deliveryMan => (
                            <MenuItem
                                key={deliveryMan.id}
                                value={deliveryMan.id}>
                                {deliveryMan.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant='outlined'>
                    <InputLabel id='deliveryStateLabel'>Estado</InputLabel>
                    <Select
                        className={classes.inputWidth}
                        labelId='deliveryStateLabel'
                        value={deliveryState}
                        label='Estado'
                        onChange={event =>
                            setDeliveryState(event.target.value)
                        }>
                        {distributionStates.map(state => (
                            <MenuItem key={state.id} value={state.id}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(DistributionFilters);

DistributionFilters.propTypes = {
    deliveryMans: PropTypes.array,
    classes: PropTypes.object,
    date: PropTypes.object,
    setDate: PropTypes.func,
    deliveryMan: PropTypes.object,
    setDeliveryMan: PropTypes.func,
    deliveryState: PropTypes.number,
    setDeliveryState: PropTypes.func,
}