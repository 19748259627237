import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import SpeedDialTooltipOpen from '@sharedComponents/FloatingButton/SpeedDial';
import useSpeedDialToolActions from './useSpeedDialToolActions';
import VerifyArray from '@sharedComponents/VerifyArray';
import Loading from '@sharedComponents/Loading';
import DeleteBoxModal from '../DeleteBoxModal';
import ListOfProducts from '../ListOfProducts';
import {
    CLEAR_NEW_EDITION_INBOX_MAGAZINE,
    CLEAR_OUTBOX_MAGAZINE,
    CREATE_NEW_EDITION_INBOX_MAGAZINE,
    SET_INBOX_MAGAZINE,
    SET_OUTBOX_MAGAZINE,
} from '@redux/types';
import {
    addEditionToBox,
    deleteBoxFromKiosk,
} from '@redux/actions/boxes';
import { styles } from './styles';

const BoxOpened = ({ location, history, classes }) => {
    //VARIABLES
    const dispatch = useDispatch();
    const {
        state: { date, provider, quantity, from, boxID, inBoxEditions },
    } = location;

    const { inBoxMagazine, outBoxMagazine } = useSelector(
        state => state.search
    );
    const { newEditionInbox, deleteBoxLoading } = useSelector(
        state => state.editions
    );
    const [editions, setEditions] = useState(
        inBoxEditions ? inBoxEditions : []
    );
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const fromIngreso = from === 'ingreso';
    const typeDispatcher = fromIngreso
        ? SET_INBOX_MAGAZINE
        : SET_OUTBOX_MAGAZINE;
    const actions = useSpeedDialToolActions({
        fromIngreso,
        typeDispatcher,
        setOpenDeleteModal,
        history,
    });

    //LIFECICLE
    useEffect(() => {
        if (fromIngreso && inBoxMagazine) {
            createNewEditionOfProduct(inBoxMagazine);
            dispatch({ type: SET_INBOX_MAGAZINE, payload: null });
        } else if (!fromIngreso && outBoxMagazine) {
            const inBoxEdition = {
                date: new Date(),
                editionID: outBoxMagazine.id,
                productID: outBoxMagazine.productID,
                productName: outBoxMagazine.productName,
                ingresedStock: 0,
                returnedStock: 0,
                editionNumber: outBoxMagazine.editionNumber,
            }; 
            
            addEditionToBox(boxID, inBoxEdition);
            setEditions(editions => [...editions, inBoxEdition]);
            dispatch({ type: CLEAR_OUTBOX_MAGAZINE });
        }
    }, [inBoxMagazine, outBoxMagazine]);

    useEffect(() => {
        if (newEditionInbox) {
            const inBoxEdition = {
                date: new Date(),
                editionID: newEditionInbox.id,
                productID: newEditionInbox.productID,
                productName: newEditionInbox.productName,
                ingresedStock: newEditionInbox.stock,
                returnedStock: 0,
                editionNumber: newEditionInbox.editionNumber,
            }; 

            addEditionToBox(boxID, inBoxEdition);
            setEditions(editions => [...editions, inBoxEdition]);
            dispatch({ type: CLEAR_NEW_EDITION_INBOX_MAGAZINE });
        }
    }, [newEditionInbox]);

    //FUNCTIONS
    const createNewEditionOfProduct = inBoxMagazine => {
        history.push({
            pathname: '/product/edition/detail/form',
            state: {
                product: inBoxMagazine,
                typeDispatcher: CREATE_NEW_EDITION_INBOX_MAGAZINE,
            },
        });
    };

    const handleCloseDeleteModal = () => setOpenDeleteModal(false);
    const handleAcceptDeleteModal = () => {
        setOpenDeleteModal(false);
        dispatch(deleteBoxFromKiosk(boxID, () => history.push('/ingreso')));
    };

    const deleteEdition = editionID => {
        setEditions(editions =>
            editions.filter(edition => edition.editionID !== editionID)
        );
    };

    const updateEdition = edition => {
        setEditions(editions =>
            editions.map(e => (e.editionID === edition.editionID ? edition : e))
        );
    };

    const handleCloseBox = () => {
        if (fromIngreso) {
            history.push('/ingreso');
        } else {
            history.push('/devoluciones');
        }
    };

    if (deleteBoxLoading) return <Loading />;

    return (
        <div className={classes.container}>
            <DeleteBoxModal
                open={openDeleteModal}
                handleClose={handleCloseDeleteModal}
                handleAccept={handleAcceptDeleteModal}
            />
            <Typography
                variant='h4'
                gutterBottom
                align='center'
                className={classes.title}>
                {provider}
            </Typography>

            <Typography variant='body2' gutterBottom>
                {date}
            </Typography>
            <VerifyArray
                array={editions}
                defaultItem='producto'
                redirect={{
                    pathname: '/search',
                    state: {
                        simpleList: fromIngreso,
                        typeDispatcher: typeDispatcher,
                    },
                }}>
                <ListOfProducts
                    items={editions}
                    quantity={quantity}
                    boxID={boxID}
                    updateEdition={updateEdition}
                    deleteEdition={deleteEdition}
                />
            </VerifyArray>

            <Button
                variant='contained'
                color='secondary'
                aria-label='Close'
                className={classes.margin}
                onClick={handleCloseBox}>
                <SaveAlt className={classes.extendedIcon} />
                Cerrar esta caja
            </Button>

            <SpeedDialTooltipOpen actions={actions} />
        </div>
    );
};

export default withStyles(styles)(BoxOpened);
