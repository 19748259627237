import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    grid: {
        paddingTop: theme.spacing(3),
    },
    a: {
        textDecoration: 'none',
    },
    selector: {
        padding: 0,
        margin: 0,
    },
    addButton: {
        color: theme.palette.secondary.main,
    },
    margin: {
        paddingTop: theme.spacing(5),
    },
    flex: {
        display: 'inline-flex',
    },
    emptyBox: {
        visibility: 'hidden',
        width: '48px',
    },
    textField: {
        width: '182px',
    },
    textFieldReduced: {
        width: '160px'
    },
    pubCant:{
        paddingTop: '7px',
        width: '42px',
    },
    table: {
        backgroundColor: "#E9E9E9",
        padding: theme.spacing(1),
    }
}));
