import React, { useMemo } from 'react';

import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const useSpeedDialToolActions = ({
    fromIngreso,
    typeDispatcher,
    setOpenDeleteModal,
    history,
}) => {
    const actions = useMemo(
        () => [
            {
                icon: <Add />,
                name: 'Nuevo producto',
                handleClick: () => {
                    history.push({
                        pathname: '/search',
                        state: {
                            simpleList: fromIngreso,
                            typeDispatcher: typeDispatcher,
                        },
                    });
                },
            },
            {
                icon: <DeleteIcon />,
                name: 'Eliminar caja',
                handleClick: () => setOpenDeleteModal(true),
            },
        ],
        [fromIngreso, typeDispatcher, setOpenDeleteModal, history]
    );
    return actions;
};

export default useSpeedDialToolActions;
