import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';

import {
    Button,
    Grid,
    InputLabel,
    Typography,
    TextField,
} from '@material-ui/core';

import Loading from '@sharedComponents/Loading';
import { schema } from './schema';
import useStyles from './styles';
import {
    editProviderFromStore,
    getProviderByIDFromStore,
} from '@redux/actions/providers';

const ProvidersEdit = () => {
    const classes = useStyles();
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { editProvidersLoading } = useSelector(state => state.providers);
    const [provider, setProvider] = useState({});

    const { register, handleSubmit, errors, formState, reset } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });
    const { isDirty, isValid } = formState;

    useEffect(() => {
        const prov = getProviderByIDFromStore(id);
        setProvider(prov);
        reset(prov);
    }, [id]);

    const onSubmit = data => {
        dispatch(
            editProviderFromStore(id, { ...provider, ...data }, () =>
                history.push('/proveedores')
            )
        );
    };

    if (editProvidersLoading) return <Loading />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Editar Proveedor</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='empresa'>Empresa</InputLabel>
                    <TextField
                        error={!!errors.company}
                        required
                        id='empresa'
                        type='text'
                        name='company'
                        inputRef={register}
                        helperText={errors.company && errors.company.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='nombreProveedor'>
                        Representante
                    </InputLabel>
                    <TextField
                        error={!!errors.representant}
                        required
                        id='nombre'
                        type='text'
                        name='representant'
                        inputRef={register}
                        helperText={
                            errors.representant && errors.representant.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='telefonoUno'>
                        Teléfono de contacto 1
                    </InputLabel>
                    <TextField
                        error={!!errors.telephone1}
                        required
                        id='telefonoUno'
                        type='string'
                        name='telephone1'
                        inputRef={register}
                        helperText={
                            errors.telephone1 && errors.telephone1.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='telefonoDos'>
                        Teléfono de contacto 2
                    </InputLabel>
                    <TextField
                        error={!!errors.telephone2}
                        id='telefonoDos'
                        type='string'
                        name='telephone2'
                        inputRef={register}
                        helperText={
                            errors.telephone2 && errors.telephone2.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='email'>Email</InputLabel>
                    <TextField
                        error={errors.email}
                        required
                        id='email'
                        type='text'
                        name='email'
                        inputRef={register}
                        helperText={errors.email && errors.email.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='direccionRetiro'>
                        Dirección de retiro
                    </InputLabel>
                    <TextField
                        error={errors.returnDir}
                        required
                        id='direccion'
                        type='text'
                        name='returnDir'
                        inputRef={register}
                        helperText={
                            errors.returnDir && errors.returnDir.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='comentarios'>Comentarios</InputLabel>
                    <TextField
                        multiline
                        fullWidth
                        error={errors.note}
                        id='comentarios'
                        type='text'
                        name='note'
                        inputRef={register}
                        helperText={errors.note && errors.note.message}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        disabled={!isValid || !isDirty}
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Editar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default ProvidersEdit;
