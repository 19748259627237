import React, { Component } from 'react';

import { getRouteSectionName } from '@router/routes';
import SwipeableTemporaryDrawer from './SideNavPage';
import { withRouter } from 'react-router';
import TopAppBar from './TopAppBar';
import SideList from './SideList';
import { connect } from 'react-redux';
import PublicTopAppBar from './PublicTopAppBar';
import BottomAppBar from './BottomAppBar';

class Layout extends Component {
    state = {
        isOpen: false,
        title: getRouteSectionName(this.props.location.pathname),
    };

    toggleDrawer = state => () => {
        this.setState({ isOpen: state });
    };

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({
                title: getRouteSectionName(this.props.location.pathname),
            });
        }
    }

    render() {
        const { title, isOpen } = this.state;
        if (this.props.user.current || this.props.user.loading) {
            if (
                !(
                    this.props.user.current &&
                    this.props.user.current.userExtraInfo.kiosk
                )
            ) {
                return (
                    <>
                        {this.props.children}

                        <BottomAppBar />
                    </>
                );
            } else {
                return (
                    <>
                        <TopAppBar
                            title={title}
                            callBack={this.toggleDrawer(true)}
                        />

                        <SwipeableTemporaryDrawer
                            sideList={<SideList />}
                            isOpen={isOpen}
                            callBack={this.toggleDrawer}
                        />

                        {this.props.children}

                        <BottomAppBar />
                    </>
                );
            }
        } else {
            return (
                <>
                    <PublicTopAppBar title={title} />
                    {this.props.children}
                </>
            );
        }
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(withRouter(Layout));
