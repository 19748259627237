import {
    SET_NEW_SELL_MAGAZINE_DAY_EDITIONS,
    SET_MAGAZINE_EDITIONS,
} from '../types';

const initialState = {
    magazineDayEditionsForSell: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_NEW_SELL_MAGAZINE_DAY_EDITIONS:
            return {
                ...state,
                magazineDayEditionsForSell: [
                    ...state.magazineDayEditionsForSell,
                    payload,
                ],
            };
        case SET_MAGAZINE_EDITIONS:
            return { ...state, magazineDayEditionsForSell: payload };
        default:
            return state;
    }
};
