import { deleteDeliveryManFromGroups } from '.';
import {
    addDeliveryMan,
    deleteDeliveryMan,
    editDeliveryMan,
} from '@services/deliveryMan';
import store from '../store';
import {
    ADD_EDIT_DELIVERY_MAN_ERROR,
    ADD_EDIT_DELIVERY_MAN_LOADING,
    ADD_EDIT_DELIVERY_MAN_SUCCESS,
    DELETE_DELIVERY_MAN_ERROR,
    DELETE_DELIVERY_MAN_LOADING,
    DELETE_DELIVERY_MAN_SUCCESS,
    SET_DELIVERY_MANS,
} from '../types';

export const editDeliveryManToKiosk = (deliveryManID, data, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_EDIT_DELIVERY_MAN_LOADING });
        try {
            const { deliveryMans } = store.getState().deliveryMan;
            await editDeliveryMan(deliveryManID, data);
            const newDeliveryMans = deliveryMans.map(deliveryMan => {
                if (deliveryMan.id === deliveryManID) {
                    return {
                        id: deliveryMan.id,
                        ...data,
                    };
                }
                return deliveryMan;
            });

            dispatch({
                type: ADD_EDIT_DELIVERY_MAN_SUCCESS,
                payload: newDeliveryMans,
            });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_EDIT_DELIVERY_MAN_ERROR });
        }
    };
};

export const addDeliveryManToKiosk = (data, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_EDIT_DELIVERY_MAN_LOADING });
        try {
            const { deliveryMans } = store.getState().deliveryMan;
            const deliveryMan = await addDeliveryMan(data);
            deliveryMans.push({ ...data, id: deliveryMan.id });

            dispatch({
                type: ADD_EDIT_DELIVERY_MAN_SUCCESS,
                payload: deliveryMans,
            });
            if (callback) {
                callback(deliveryMan);
            }
        } catch (error) {
            dispatch({ type: ADD_EDIT_DELIVERY_MAN_ERROR });
        }
    };
};

export const deleteDeliveryManFromKiosk = (deliveryManID, callback) => {
    return async dispatch => {
        dispatch({ type: DELETE_DELIVERY_MAN_LOADING });
        try {
            await deleteDeliveryMan(deliveryManID);
            await dispatch(deleteDeliveryManFromGroups(deliveryManID));
            dispatch(deleteDeliveryManFromStore(deliveryManID));
            dispatch({ type: DELETE_DELIVERY_MAN_SUCCESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: DELETE_DELIVERY_MAN_ERROR });
        }
    };
};

export const deleteDeliveryManFromStore = deliveryManID => {
    return dispatch => {
        const { deliveryMans } = store.getState().deliveryMan;
        const newDeliveryMans = deliveryMans.filter(
            deliveryMan => deliveryMan.id !== deliveryManID
        );
        dispatch({ type: SET_DELIVERY_MANS, payload: newDeliveryMans });
    };
};

export const getDeliveryManFromStore = deliveryManID => {
    const { deliveryMans } = store.getState().deliveryMan;
    return deliveryMans.find(deliveryMan => deliveryMan.id === deliveryManID);
};
