import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { DirectionsRenderer } from 'react-google-maps';

import { DELIVERY_COLORS, DELIVERY_ZINDEX } from '@constants/deliveryStates';

//Se necesita un wrapper para checkear cada vez que cambia la direction cambien bien los colores 
//  ya que el componente de react-google-maps no detecta el cambio si solo cambio la direction
//  por eso hago un contador que cambia las directions la cual si trigerea un render.

const DirectionsRendererWrapper = ({ directions, direction }) => {
    const [buildDirections, setBuildDirections] = useState({ routes: [] });
    const [counter, setCounter] = useState(0);
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions({
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: DELIVERY_COLORS[direction.state],
                zIndex: DELIVERY_ZINDEX[direction.state],
            },
        });
        setBuildDirections({ ...directions, counter });
        setCounter(counter => counter + 1); 
    }, [direction, directions]);

    return (
        <DirectionsRenderer directions={buildDirections} options={options} />
    );
};

export default DirectionsRendererWrapper;

DirectionsRendererWrapper.propTypes = {
    directions: PropTypes.object,
    direction: PropTypes.object,
}