import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';

import { deleteDistributionFromKiosk } from '@redux/actions/distributions';
import Loading from '@sharedComponents/Loading';

const DeleteDistributionModal = ({ open, setOpen, deleteID }) => {
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);
    const history = useHistory();

    const { deleteDistributionLoading } = useSelector(
        state => state.distributions
    );

    const handleDelete = () => {
        if (deleteID) {
            dispatch(deleteDistributionFromKiosk(deleteID, () => history.goBack()));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='eliminar-reparto'>
            <DialogTitle id='eliminar-reparto'>Eliminar reparto</DialogTitle>
            <DialogContent>
                {deleteDistributionLoading ? (
                    <Loading />
                ) : (
                    <DialogContentText id='eliminar-reparto'>
                        Esta seguro que desea eliminar el reparto? El mismo se
                        podra ver como historico si vuelves los dias atras,
                        incluyendo el dia de hoy, pero no se podra editar.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancelar
                </Button>
                <Button
                    disabled={deleteDistributionLoading}
                    onClick={handleDelete}
                    color='secondary'>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDistributionModal;

DeleteDistributionModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    deleteID: PropTypes.string,
};
