export const DELIVERY_STATES = {
    visited: 'visited',
    active: 'active',
    next: 'next',
}

export const DELIVERY_COLORS = {
    visited: '#757575',
    active: '#f50057',
    next: '#3f50b5',
}

export const DELIVERY_ZINDEX = {
    visited: 1,
    active: 3,
    next: 2,
}