import { db } from '../firebase';
import store from '@redux/store';

export const editUser = async data => {
    const { uid } = store.getState().auth.user.current.user;
    if (uid) {
        const docRef = db.collection('users').doc(uid);
        const updDocRef = await docRef.update({ ...data }).then(() => true);
        return updDocRef;
    } else {
        throw new Error('No hay usuario logueado');
    }
};
