import { wrapKioskQuery } from './kiosk';

const _getDistributions = async kioskReference => {
    const snapshot = kioskReference.collection('distributions').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getDistributionsByClient = async (clientID, kioskReference) => {
    const snapshot = kioskReference.collection('distributions').where('clientID', '==', clientID).get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getDistribution = async (id, kioskReference) => {
    const docRef = kioskReference.collection('distributions').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editDistribution = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('distributions').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

export const _addDistribution = async (data, kioskReference) => {
    const distributionsRef = kioskReference.collection('distributions');
    const docRef = await distributionsRef.add({ ...data });
    return docRef;
};

export const getDistributions = wrapKioskQuery(_getDistributions);
export const getDistribution = wrapKioskQuery(_getDistribution);
export const editDistribution = wrapKioskQuery(_editDistribution);
export const addDistribution = wrapKioskQuery(_addDistribution);
export const getDistributionsByClient = wrapKioskQuery(_getDistributionsByClient);