import {
    SET_KIOSK_REFERENCE,
    ADD_KIOSK_LOADING,
    ADD_KIOSK_SUCCESS,
    ADD_KIOSK_FAILED,
    LINK_KIOSK_LOADING,
    LINK_KIOSK_SUCCESS,
    LINK_KIOSK_FAILED,
    SET_KIOSK
} from '../types';

const initialState = {
    kioskReference: null,
    addKioskLoading: false,
    addKioskError: false,
    kiosk: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_KIOSK_REFERENCE:
            return { ...state, kioskReference: payload };
        case ADD_KIOSK_LOADING:
            return { ...state, addKioskLoading: true, addKioskError: false };
        case ADD_KIOSK_SUCCESS:
            return {
                ...state,
                addKioskLoading: false,
                addKioskError: false,
                kiosk: payload,
            };
        case ADD_KIOSK_FAILED:
            return { ...state, addKioskLoading: false, addKioskError: payload };
        case LINK_KIOSK_LOADING:
            return { ...state, linkKioskLoading: true, linkKioskError: false };
        case LINK_KIOSK_SUCCESS:
            return {
                ...state,
                linkKioskLoading: false,
                linkKioskError: false,
                kioskReference: payload,
            };
        case LINK_KIOSK_FAILED:
            return {
                ...state,
                linkKioskLoading: false,
                linkKioskError: payload,
            };
        case SET_KIOSK: 
            return { ...state, kiosk: payload };
        default:
            return state;
    }
};
