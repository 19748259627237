import * as yup from 'yup';

export const schema = yup.object().shape({
    price: yup
        .number()
        .typeError('Debe ser un numero')
        .positive('Debe ser positivo')
        .required('Ingrese un precio'),
    stock: yup
        .number()
        .typeError('Debe ser un numero')
        .required('Ingrese cantidad')
        .positive('Debe ser positiva'),
    editionNumber: yup
        .string()
        .required('Ingrese un nombre / numero de edicion')
        .max(25, 'Máximo 25 caracteres'),
});
