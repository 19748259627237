import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles';
import VerifyArray from '@sharedComponents/VerifyArray';

const history = [
    {
        date: '18/12/2019',
        purchases: [
            {
                name: 'Capital',
                subsription: false,
                price: 35,
            },
            {
                name: 'Popular',
                subsription: false,
                price: 50,
            },
        ],
    },
    {
        date: '17/12/2019',
        purchases: [
            {
                name: 'Capital',
                subsription: false,
                price: 35,
            },
            {
                name: 'Cronista',
                subsription: true,
                price: 45,
            },
        ],
    },
    {
        date: '16/12/2019',
        purchases: [
            {
                name: 'Capital',
                subsription: true,
                price: 35,
            },
            {
                name: 'Popular',
                subsription: false,
                price: 50,
            },
        ],
    },
];

const ClientHistory = () => {
    const classes = useStyles();
    return (
        <VerifyArray array={history} defaultItem='historial'>
            {history.map(item => (
                <div key={`day[${item.date}]`}>
                    <List
                        subheader={
                            <ListSubheader component='div'>
                                {item.date}
                            </ListSubheader>
                        }>
                        <VerifyArray
                            array={item.purchases}
                            defaultItem='compra'
                            femenine>
                            {item.purchases.map(element => (
                                <ListItem
                                    className={classes.margin}
                                    key={`item[${element.name}]`}>
                                    <ListItemText
                                        primary={element.name}
                                        secondary={
                                            <>
                                                ${element.price} <br />
                                                {element.subsription && (
                                                    <Typography
                                                        component='span'
                                                        variant='body2'>
                                                        Subscripcion
                                                    </Typography>
                                                )}
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </VerifyArray>
                    </List>
                </div>
            ))}
        </VerifyArray>
    );
};

export default ClientHistory;
