import React from 'react'
import NavTabs from '@sharedComponents/NavTab';
import ProductEditionList from '../../Edition/ProductEditionList';
import ProductDetail from '../ProductDetail';

const tabItems = [
    {
        label: 'Publicacion',
        href: 'page1',
        component: ProductDetail,
    },
    {
        label: 'Ediciones',
        href: 'page2',
        component: ProductEditionList,
    },
];


const ShowProduct = () => {
     return <NavTabs tabsData={tabItems} />;
}

export default ShowProduct;