import React from 'react';

import { useStyles } from './styles';

const ModalBody = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.modalBody}>{children}</div>;
};

export default ModalBody;
