import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textField: {
        width: '100%',
        margin: theme.spacing(2),
        minHeight: theme.spacing(10)
    },
    paper: {
        display: 'block',
        padding: 0,
        minWidth: '300px',
        width: '100%',
        textAlign: 'center',
    },
    note: {
        display: 'flex',
        padding: 0,
        minWidth: '300px',
        width: '100%',
    },
    infoSeparator: {
        display: 'inline',
        width: '100%',
        color: 'black',
    },
    a: {
        marginLeft: theme.spacing(1),
        color: 'black',
        textDecoration: 'none',
    },
    background: {
        backgroundColor: theme.palette.background.backLight,
        marginTop: theme.spacing(2),
        height: '100vh'
    },
}));

export default useStyles;
