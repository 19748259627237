import {
    SET_CLIENTS,
    EDIT_CLIENT_LOADING,
    EDIT_CLIENT_SUCCSESS,
    EDIT_CLIENT_ERROR,
    ADD_CLIENT_LOADING,
    ADD_CLIENT_SUCCSESS,
    ADD_CLIENT_ERROR,
    DELETE_CLIENT_LOADING,
    DELETE_CLIENT_SUCCSESS,
    DELETE_CLIENT_ERROR,
} from '../types';

import { addClient, editClient, } from '@services/clients';
import store from '../store';
import { deleteDistributionsOfClientFromKiosk } from './distributions';

export const editClientFromStore = (id, data, callback) => {
    return async dispatch => {
        dispatch({ type: EDIT_CLIENT_LOADING });
        try {
            await editClient(id, data);
            let { clients } = store.getState().clients;
            const clientIndex = clients.findIndex(client => client.id === id);
            clients[clientIndex] = { ...data, id };
            dispatch({ type: EDIT_CLIENT_SUCCSESS, payload: clients });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: EDIT_CLIENT_ERROR, payload: error });
        }
    };
};

export const addClientFromStore = (data, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_CLIENT_LOADING });
        try {
            const client = await addClient(data);
            const { clients } = store.getState().clients;
            clients.push({ ...data, id: client.id });
            dispatch({ type: ADD_CLIENT_SUCCSESS, payload: clients });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_CLIENT_ERROR, payload: error });
        }
    };
};

export const deleteClientFromKiosk = (id, callback) => {
    return async dispatch => {

        dispatch({ type: DELETE_CLIENT_LOADING });
        try {
            const client = getClientByIDFromStore(id)
            client.active = false;
            client.endDate = new Date();
            delete client.id;
            await dispatch(deleteDistributionsOfClientFromKiosk(id))
            await editClient(id, client);
            dispatch(editClientFromStoreWhitOutLoading(id, client));
            dispatch({ type: DELETE_CLIENT_SUCCSESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: DELETE_CLIENT_ERROR, payload: error });
        }
    };
}

export const editClientFromStoreWhitOutLoading = (clientID, client) => {
    return dispatch => {
        const { clients } = store.getState().clients;
        const newClients = [...clients];
        const index = newClients.findIndex(
            item => item.id === clientID
        );
        newClients[index] = {...client, id: clientID};
        dispatch({ type: SET_CLIENTS, payload: newClients });
    };
}


export const getClientByIDFromStore = (id) => {
    const { clients } = store.getState().clients;
    return clients.find(client => client.id === id)
}