import firebase from 'firebase/app'
import Geocode from "react-geocode";
import 'firebase/auth'
import 'firebase/firestore'

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyBfCgBOZYAREEGpkePYP7zHHZ6jJuwg9Mc");

// set response language. Defaults to english.
Geocode.setLanguage("es");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("ar");
// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
//Geocode.enableDebug();

const app = firebase.initializeApp({
    apiKey: "AIzaSyDu3JkViVLJAr5YadnoPF1_f8lV1fi5EVM",
    authDomain: "caniya-27840.firebaseapp.com",
    projectId: "caniya-27840",
    storageBucket: "caniya-27840.appspot.com",
    messagingSenderId: "32421172654",
    appId: "1:32421172654:web:48615f48c573d817c7643f"
});

firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  });

firebase.firestore().enablePersistence()
  .catch((err) => {
      if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
  });
// Subsequent queries will use persistence, if it was enabled successfully

export const auth = app.auth();
export const db = app.firestore();
export default app;