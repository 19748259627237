const distributionStates = [
    { id: 1, name: 'No entregado' },
    { id: 2, name: 'Entregado' },
    { id: 3, name: 'Reprogramado' },
    { id: 4, name: 'Rechazado' },
];

export const NOT_DELIVERED = distributionStates[0].id;
export const DELIVERED = distributionStates[1].id;
export const REPROGRAMMED = distributionStates[2].id;
export const REJECTED = distributionStates[3].id;

export default distributionStates