import {
    CREATE_NEW_EDITION_INBOX_MAGAZINE,
    NEW_EDITION_LOADING,
    NEW_EDITION_ERROR,
    CLEAR_NEW_EDITION_INBOX_MAGAZINE,
    DELETE_EDITION_ERROR,
    DELETE_EDITION_LOADING,
    DELETE_EDITION_OK,
} from '../types';

const initialState = {
    newEditionInbox: null,
    newEditionLoading: false,
    newEditionError: false,
    deleteBoxLoading: false,
    deleteBoxError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case NEW_EDITION_LOADING:
            return {
                ...state,
                newEditionLoading: true,
                newEditionError: false,
            };
        case NEW_EDITION_ERROR:
            return {
                ...state,
                newEditionLoading: false,
                newEditionError: payload,
            };
        case CREATE_NEW_EDITION_INBOX_MAGAZINE:
            return {
                ...state,
                newEditionInbox: payload,
                newEditionLoading: false,
                newEditionError: false,
            };
        case CLEAR_NEW_EDITION_INBOX_MAGAZINE:
            return {
                ...state,
                newEditionInbox: null,
            };
        case DELETE_EDITION_LOADING:
            return {
                ...state,
                deleteBoxLoading: true,
                deleteBoxError: false,
            };
        case DELETE_EDITION_ERROR:
            return {
                ...state,
                deleteBoxLoading: false,
                deleteBoxError: payload,
            };
        case DELETE_EDITION_OK:
            return {
                ...state,
                deleteBoxLoading: false,
                deleteBoxError: false,
            };
        default:
            return state;
    }
};
