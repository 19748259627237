import {
    SIGNUP_LOADING,
    SIGNUP_SUCCESSFULL,
    SIGNUP_FAILED,
    LOGIN_LOADING,
    LOGIN_SUCCESSFULL,
    LOGIN_FAILED,
    SIGNOUT_LOADING,
    SIGNOUT_SUCCESSFULL,
    SIGNOUT_FAILED,
} from '../types';
import { auth, db } from '../../firebase';

const signupErrors = [
    { code: 'auth/email-already-in-use', message: 'El correo ya está en uso' },
    { code: 'auth/invalid-email', message: 'El correo no es válido' },
    { code: 'auth/operation-not-allowed', message: 'No se puede registrar' },
    { code: 'auth/weak-password', message: 'La contraseña es muy débil' },
];

// SIGNUP //
export const signup = (data, callback) => {
    return async dispatch => {
        dispatch({ type: SIGNUP_LOADING });
        try {
            const userCreated = await auth.createUserWithEmailAndPassword(
                data.email,
                data.password
            );
            await createUserData(userCreated, dispatch, data);
            if (callback) {
                callback();
            }
        } catch (error) {
            const errorMessage = signupErrors.find(
                errorMessage => errorMessage.code === error.code
            );
            dispatch({ type: SIGNUP_FAILED, payload: errorMessage });
        }
    };
};

const createUserData = async (userCreated, dispatch, data) => {
    try {
        await db
            .collection('users')
            .doc(userCreated.user.uid)
            .set({
                name: data.nombre,
                lastName: data.apellido,
                email: data.email,
            });

        dispatch({ type: SIGNUP_SUCCESSFULL });
    } catch(error) {
        eliminateUserToRemoveUserIncoherences(error, dispatch, data);
    }
};

const eliminateUserToRemoveUserIncoherences = (error, dispatch, data) => {
    auth.signInWithEmailAndPassword(data.email, data.password)
        .then(async userCredentials => {
            let user = userCredentials.user;
            await user.delete();
            dispatch({
                type: SIGNUP_FAILED,
                payload: error,
            });
        })
        .catch(error =>
            dispatch({
                type: SIGNUP_FAILED,
                payload: error,
            })
        );
};
// END SIGNUP //

export const login = ({ email, password }) => {
    return async dispatch => {
        dispatch({ type: LOGIN_LOADING });
        try {
            await auth.signInWithEmailAndPassword(email, password);
            dispatch({ type: LOGIN_SUCCESSFULL });
        } catch (error) {
            dispatch({ type: LOGIN_FAILED, payload: error });
        }
    };
};

export const logout = () => {
    return async dispatch => {
        dispatch({ type: SIGNOUT_LOADING });
        try {
            await auth.signOut();
            dispatch({ type: SIGNOUT_SUCCESSFULL });
        } catch (error) {
            dispatch({ type: SIGNOUT_FAILED, payload: error });
        }
    };
};

export const resetPassword = () => {};

export const updatePassword = () => {};

export const updateEmail = () => {};
