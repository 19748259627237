export const styles = theme =>({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    textLink: {
        color: 'inherit',
        textDecoration: 'inherit'
    },

})