import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    flex: {
        display: 'inline-flex',
    },
    textField: {
        width: '182px',
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));
