import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    Button,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { login } from '@redux/actions';
import { schema } from './schema';
import useStyles from './styles';

const Login = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    
    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const { isDirty, isValid } = formState;
    const onSubmit = data => dispatch(login(data));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Inicia Sesion</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='email'>Correo electronico</InputLabel>
                    <Input
                        required
                        id='email'
                        type='email'
                        name='email'
                        inputRef={register}
                    />
                    <FormHelperText className={classes.FormHelperText}>
                        {errors.email && errors.email.message}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='password'>Contraseña</InputLabel>
                    <Input
                        required
                        name='password'
                        inputRef={register}
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        className={classes.password}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={handleShowPassword}>
                                    {showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText className={classes.FormHelperText}>
                        {errors.password && errors.password.message}
                    </FormHelperText>
                    <FormHelperText
                        id='password'
                        component='div'
                        className={classes.formHelper}>
                        <Link className={classes.textLink} to='/'>
                            Olvido su contraseña?
                        </Link>
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={!isValid || !isDirty}
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Ingresar
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.register}>
                    No tiene una cuenta?{' '}
                    <Link
                        className={clsx(classes.textLink, classes.linkRegister)}
                        to='/register'>
                        Registrate
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;
