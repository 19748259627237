import { isSameDay } from 'date-fns';
import { getDateFromDateOrTimestamp } from '../utils/utils';
import { wrapKioskQuery } from './kiosk';

const _getDistributionStates = async (distributionID, kioskReference) => {
    const snapshot = kioskReference
        .collection('distributions')
        .doc(distributionID)
        .collection('states')
        .get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getDistributionStateForDate = async (id, date, kioskReference) => {
    const snapshot = kioskReference
        .collection('distributions')
        .doc(id)
        .collection('states')
        .get();

    const states = (await snapshot).docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }));
    
    const distState = states.filter(state =>
        isSameDay(getDateFromDateOrTimestamp(state.date), date)
    );
    return distState[0];
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editDistributionState = async (
    distributionID,
    stateID,
    data,
    kioskReference
) => {
    const docRef = kioskReference
        .collection('distributions')
        .doc(distributionID)
        .collection('states')
        .doc(stateID);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef;
};

export const _addDistributionState = async (
    distributionID,
    data,
    kioskReference
) => {
    const distributionsRef = kioskReference
        .collection('distributions')
        .doc(distributionID)
        .collection('states');
    const docRef = await distributionsRef.add({ ...data });
    return docRef;
};

export const getDistributionStates = wrapKioskQuery(_getDistributionStates);
export const getDistributionStateForDate = wrapKioskQuery(
    _getDistributionStateForDate
);
export const editDistributionState = wrapKioskQuery(_editDistributionState);
export const addDistributionState = wrapKioskQuery(_addDistributionState);
