import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClientFromKiosk } from '@redux/actions/clients';
import Loading from '@sharedComponents/Loading';

const DeleteClientModal = ({ open, setOpen, deleteID }) => {
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);

    const { deleteDeliveryGroupLoading } = useSelector(
        state => state.deliveryGroups
    );

    const handleDelete = () => {
        if (deleteID) {
            dispatch(deleteClientFromKiosk(deleteID, handleClose));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='eliminar-cliente'>
            <DialogTitle id='eliminar-cliente'>Eliminar cliente</DialogTitle>
            <DialogContent>
                {deleteDeliveryGroupLoading ? (
                    <Loading />
                ) : (
                    <DialogContentText id='eliminar-cliente'>
                        Esta seguro que desea eliminar el cliente? El mismo se
                        podra ver como historico si vuelves los dias atras,
                        incluyendo el dia de hoy, pero no se podra editar.
                        Tambien se daran de baja todos los repartos que tenga asignados.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancelar
                </Button>
                <Button
                    disabled={deleteDeliveryGroupLoading}
                    onClick={handleDelete}
                    color='secondary'>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteClientModal;

DeleteClientModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    deleteID: PropTypes.string,
};
