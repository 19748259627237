import React from 'react';
import PropTypes from 'prop-types';

import Boxes from '../Boxes';
import Loading from '@sharedComponents/Loading';
import useBoxes from './useBoxes';

const BoxesOnInventory = ({ section, from }) => {
   
    const { boxes, isLoading } = useBoxes({ from });

    if (isLoading || !boxes) return <Loading />;

    return (
        <div>
            <Boxes boxes={boxes} section={section} from={from} />
        </div>
    );
};

export default BoxesOnInventory;

BoxesOnInventory.propTypes = {
    section: PropTypes.string,
    from: PropTypes.string,
};
