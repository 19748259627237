import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';

import DistPopover from './DistPopover';

function DistListItem({ data, setSelectedItems, checked }) {
    const handleItemClick = () => {
        if (checked) {
            setSelectedItems(items =>
                items.filter(item => item.id !== data.id)
            );
        } else {
            setSelectedItems(items => [...items, { ...data }]);
        }
    };
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpenPopover = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <ListItem ContainerComponent='ul' button onClick={handleItemClick}>
                <ListItemIcon>
                    <Checkbox
                        edge='start'
                        checked={checked}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText
                    primary={data.address}
                    secondary={data.inDistProductRows
                        .map(
                            inDistProductRow =>
                                `${inDistProductRow.inDistProduct.productName} (${inDistProductRow.amount})`
                        )
                        .join(', ')}
                />
                <ListItemSecondaryAction>
                    <IconButton onClick={handleOpenPopover}>
                        <MoreVert />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <DistPopover
                distID={data.id}
                isActive={data.active}
                anchorEl={anchorEl}
                handleClose={handleClose}
            />
        </>
    );
}

DistListItem.propTypes = {
    data: PropTypes.object.isRequired,
    setSelectedItems: PropTypes.func,
    checked: PropTypes.bool,
};

export default DistListItem;
