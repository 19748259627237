import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Paper, Popover } from '@material-ui/core';

import useStyles from './styles';

const DistPopover = ({ distID, anchorEl, handleClose, isActive }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Popover
            id={`popover-distribution-list-${distID}`}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <Paper className={classes.paper}>
                <Button
                    disabled={!isActive}
                    color='secondary'
                    onClick={() => history.push(`/editDist/${distID}`)}>
                    Editar Reparto
                </Button>
            </Paper>
        </Popover>
    );
};

export default DistPopover;

DistPopover.propTypes = {
    distID: PropTypes.string,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func,
    isActive: PropTypes.bool,
}