export const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 80,
        height: 80,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
        marginLeft: '10px'
    },
    container: {
        margin: theme.spacing(1),
        textDecoration : 'none',
        color: 'inherit'
    },
    moreIcon: {
        paddingLeft: 0,
    },

});
