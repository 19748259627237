import { AppBar, NoSsr, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { useStyles } from './styles';

const FastNavTab = ({ tabsData, props }) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <NoSsr>
            <div className={classes.root}>
                <AppBar position='static' className={classes.appbar}>
                    <Tabs
                        variant='fullWidth'
                        value={value}
                        onChange={handleChange}>
                        {tabsData.map((element, index) => {
                            return (
                                <Tab
                                    label={element.label}
                                    key={index}
                                    onClick={event => event.preventDefault()}
                                />
                            );
                        })}
                    </Tabs>
                </AppBar>
                {tabsData.map((element, index) => {
                    return (
                        <div
                            key={index}
                            style={{
                                display: value === index ? 'inherit' : 'none',
                            }}>
                            {<element.component {...props}/>}
                        </div>
                    );
                })}
            </div>
        </NoSsr>
    );
};

export default FastNavTab;
