import React, { useEffect, useState } from 'react';

import useStyles from './styles';
import { Card, MenuItem, Select } from '@material-ui/core';
import TransitModeIcon from './TransitModeIcon/index.js';
import { SupervisorAccount } from '@material-ui/icons';
import WrappedMap from './Map';
import { useSelector } from 'react-redux';
import { buildActiveRouteGroup } from '@redux/actions/delivery.js';

const DistributionMap = () => {
    const classes = useStyles();
    const { deliveries } = useSelector(state => state.delivery);

    const [groupID, setGroupID] = useState('');
    const [transitMode, setTransitMode] = useState('DRIVING');
    const [activeRouteGroup, setActiveRouteGroup] = useState([]);

    useEffect(() => {
        if (groupID === '') {
            setGroupID(deliveries.length > 0 ? deliveries[0].id : '');
        } else {
            const routes = buildActiveRouteGroup(deliveries, groupID);
            setActiveRouteGroup(routes);
        }
    }, [JSON.stringify(deliveries), groupID]);

    return (
        <div className={classes.mapWrapper}>
            <Card className={classes.transitModeSelectorDiv} component='div'>
                <TransitModeIcon transitMode={transitMode} />
                <Select
                    id='transitModeSelector'
                    value={transitMode}
                    onChange={e => setTransitMode(e.target.value)}>
                    <MenuItem value='DRIVING'>Conduciendo</MenuItem>
                    <MenuItem value='WALKING'>A pie</MenuItem>
                    <MenuItem value='BICYCLING'>En bicicleta</MenuItem>
                    <MenuItem value='TRANSIT'>Trasporte publico</MenuItem>
                </Select>
            </Card>

            <Card className={classes.groupSelectorDiv} component='div'>
                <span className={classes.iconSpan}>
                    <SupervisorAccount />
                </span>
                <Select
                    id='groupSelector'
                    value={groupID}
                    onChange={e => setGroupID(e.target.value)}>
                    {deliveries.map(delivery => (
                        <MenuItem value={delivery.id} key={delivery.id}>
                            {delivery.name}
                        </MenuItem>
                    ))}
                </Select>
            </Card>

            <WrappedMap
                activeRouteGroup={activeRouteGroup}
                transitMode={transitMode}
                containerElement={
                    <div className={classes.mapContainerElement} />
                }
                mapElement={<div className={classes.mapElement} />}
                loadingElement={
                    <div className={classes.mapLoadingElement}>CARGANDO</div>
                }
            />
        </div>
    );
};

export default DistributionMap;
