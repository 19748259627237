import {
    CLEAR_OUTBOX_MAGAZINE,
    SET_INBOX_MAGAZINE,
    SET_OUTBOX_MAGAZINE,
    SET_SELL_MAGAZINE,
    CLEAR_SELL_MAGAZINE
} from '../types';

const initialState = {
    inBoxMagazine: null,
    outBoxMagazine: null,
    sellMagazine: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_INBOX_MAGAZINE:
            return { ...state, inBoxMagazine: payload };
        case SET_OUTBOX_MAGAZINE:
            return { ...state, outBoxMagazine: payload };
        case CLEAR_OUTBOX_MAGAZINE:
            return { ...state, outBoxMagazine: null };
        case SET_SELL_MAGAZINE:
            return { ...state, sellMagazine: payload };
        case CLEAR_SELL_MAGAZINE:
            return { ...state, sellMagazine: null };
        default:
            return state;
    }
};
