import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDeliveryManFromKiosk } from '@redux/actions';
import Loading from '@sharedComponents/Loading';

const DeliveryManDelete = ({ open, setOpen, deleteID }) => {
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);

    const { deleteDeliveryManLoading } = useSelector(
        state => state.deliveryMan
    );

    const handleDelete = () => {
        if (deleteID) {
            dispatch(deleteDeliveryManFromKiosk(deleteID, handleClose));
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='sm'
                aria-labelledby='eliminar-repartidor'>
                <DialogTitle id='eliminar-repartidor'>
                    Eliminar repartidor
                </DialogTitle>
                <DialogContent>
                    {deleteDeliveryManLoading ? (
                        <Loading />
                    ) : (
                        <DialogContentText id='eliminar-repartidor'>
                            Esta seguro que desea eliminar el repartidor? Esta
                            accion es irreversible. Los grupos en los que este
                            repartidor trabajaba quedaran sin repartidor
                            asignado.
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancelar
                    </Button>
                    <Button
                        disabled={deleteDeliveryManLoading}
                        onClick={handleDelete}
                        color='secondary'>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeliveryManDelete;

DeliveryManDelete.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    deleteID: PropTypes.string,
};
