import { getLastEdition } from '@services/editions';
import { addProduct, editProduct } from '@services/products';
import store from '../store';
import {
    ADD_PRODUCT_LOADING,
    ADD_PRODUCT_SUCCSESS,
    ADD_PRODUCT_ERROR,
    SET_PRODUCTS,
} from '../types';

export const addProductFromStore = (data, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_PRODUCT_LOADING });
        try {
            const product = await addProduct(data);
            const { products } = store.getState().products;
            products.push({ ...data, id: product.id, editions: [] });
            dispatch({ type: ADD_PRODUCT_SUCCSESS, payload: products });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_PRODUCT_ERROR, payload: error });
        }
    };
};

const getProductIdAndLastEdition = async productID => {
    const lastEdition = await getLastEditionOfProduct(productID);
    if (lastEdition) {
        return { productID, editionID: lastEdition.id };
    }
    return null;
};

export const getLastEditionOfProduct = async productID => {
    const editionsOrdered = await getLastEdition(productID);
    return editionsOrdered[0];
}

export const getLastEditionOfProducts = async listOfProductsIDS => {
    const editionPromises = listOfProductsIDS.map(productID =>
        getProductIdAndLastEdition(productID)
    );
    let lastEditionOfProducts = [];
    if (editionPromises.length > 0) {
        const promises = editionPromises.map(p => p.catch(e => e));
        await Promise.all(promises).then(responses => {
            lastEditionOfProducts = responses;
        });
    }
    return lastEditionOfProducts;
};

export const editProductFromStoreAndKiosk = product => {
    return async dispatch => {
        dispatch(editProductFromStore({...product}));
        const productID = product.id;
        delete product.id;
        delete product.editions;
        return await editProduct(productID, product)
    }
}


export const editProductFromStore = product => {
    return dispatch => {
        const { products } = store.getState().products;
        const index = products.findIndex(p => p.id === product.id);
        products[index] = product;
        dispatch({ type: SET_PRODUCTS, payload: products });
    };
};

export const getProductByIDFromStore = productID => {
    const { products } = store.getState().products;
    return products.find(p => p.id === productID);
};

export const getInDistProductsFromStore = () => { 
    const { products } = store.getState().products;
    return products.map(p => ({ productID: p.id, productName: p.name }));
}