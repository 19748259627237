import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    grid: { textAlign: 'center', fontSize: '14px' },
    dateText: { fontSize: '19px' },
    priceCant: { fontSize: '20px' },
    seeMore: { marginTop: 10, marginBottom: 20 },
    link: { textDecoration: 'none' }
}));

export default useStyles