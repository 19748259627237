import React from 'react';
import MonthlyGainings from './MonthlyGainings';
import useStyles from './styles'
function Reports() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <h6 className={classes.welcome}>
                Hemos preparado una secuencia de estadísticas y reportes para
                que veas de acuerdo a tu kiosko!
            </h6>

            <MonthlyGainings />


        </div>
    );
}

export default Reports;
