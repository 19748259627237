import {
    addDeliveryGroup,
    editDeliveryGroup,
} from '@services/deliveryGroups';
import store from '../store';
import {
    ADD_EDIT_DELIVERY_GROUP_ERROR,
    ADD_EDIT_DELIVERY_GROUP_LOADING,
    ADD_EDIT_DELIVERY_GROUP_SUCCESS,
    DELETE_DELIVERY_GROUP_ERROR,
    DELETE_DELIVERY_GROUP_LOADING,
    DELETE_DELIVERY_GROUP_SUCCESS,
    SET_GROUPS,
} from '../types';
import { deleteDistributionsOfGroupFromKiosk } from './distributions';

export const editDeliveryGroupToKiosk = (deliveryGroupID, data) => {
    return async dispatch => {
        dispatch({ type: ADD_EDIT_DELIVERY_GROUP_LOADING });
        try {
            const { deliveryGroups } = store.getState().deliveryGroups;
            await editDeliveryGroup(deliveryGroupID, data);
            const newDeliveryGroups = deliveryGroups.map(group => {
                if (group.id === deliveryGroupID) {
                    return {
                        id: group.id,
                        ...data,
                    };
                }
                return group;
            });
            dispatch({ type: ADD_EDIT_DELIVERY_GROUP_SUCCESS, payload: newDeliveryGroups });
        } catch (error) {
            dispatch({ type: ADD_EDIT_DELIVERY_GROUP_ERROR });
        }
    };
};

export const addDeliveryGroupToKiosk = data => {
    return async dispatch => {
        dispatch({ type: ADD_EDIT_DELIVERY_GROUP_LOADING });
        try {
            const { deliveryGroups } = store.getState().deliveryGroups;
            const deliveryGroup = await addDeliveryGroup(data);
            deliveryGroups.push({ ...data, id: deliveryGroup.id });

            dispatch({
                type: ADD_EDIT_DELIVERY_GROUP_SUCCESS,
                payload: deliveryGroups,
            });
        } catch (error) {
            dispatch({ type: ADD_EDIT_DELIVERY_GROUP_ERROR });
        }
    };
};

export const deleteDeliveryGroupFromKiosk = (deliveryGroupID, callback) => {
    return async dispatch => {
        dispatch({ type: DELETE_DELIVERY_GROUP_LOADING });
        try {
            const deliveryGroup = getDeliveryGroupFromStore(deliveryGroupID);
            deliveryGroup.active = false;
            deliveryGroup.endDate = new Date();
            delete deliveryGroup.id;

            await dispatch(
                deleteDistributionsOfGroupFromKiosk(deliveryGroupID)
            );
            await editDeliveryGroup(deliveryGroupID, deliveryGroup);
            dispatch(
                editDeliveryGroupFromStoreWithoutLoading(
                    deliveryGroupID,
                    deliveryGroup
                )
            );
            dispatch({ type: DELETE_DELIVERY_GROUP_SUCCESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: DELETE_DELIVERY_GROUP_ERROR });
        }
    };
};

export const deleteDeliveryManFromGroups = deliveryManID => {
    return async dispatch => {
        const { deliveryGroups } = store.getState().deliveryGroups;

        let deliveryManDeleteFromGroupsPromises = [];
        deliveryGroups.forEach(deliveryGroup => {
            if (deliveryGroup.deliveryManID === deliveryManID) {
                deliveryManDeleteFromGroupsPromises.push(
                    removeDeliveryManFromGroupWithoutDispatch(deliveryGroup)
                );
            }
        });
        if (deliveryManDeleteFromGroupsPromises.length > 0) {
            const promises = deliveryManDeleteFromGroupsPromises.map(p =>
                p.catch(e => e)
            );
            await Promise.all(promises);
            const removedDeliveryManFromGroups = deliveryGroups.map(group => {
                if (group.deliveryManID === deliveryManID) {
                    group.deliveryManID = '';
                }
                return group;
            });
            dispatch({
                type: SET_GROUPS,
                payload: removedDeliveryManFromGroups,
            });
        }
    };
};

export const removeDeliveryManFromGroupWithoutDispatch = async deliveryGroup => {
    const copyDeliveryGroup = { ...deliveryGroup };
    const id = copyDeliveryGroup.id;
    delete copyDeliveryGroup.id;
    copyDeliveryGroup.deliveryManID = '';
    await editDeliveryGroup(id, copyDeliveryGroup);
};

export const editDeliveryGroupFromStoreWithoutLoading = (
    deliveryGroupID,
    deliveryGroup
) => {
    return dispatch => {
        const { deliveryGroups } = store.getState().deliveryGroups;
        const newDeliveryGroups = [...deliveryGroups];
        const index = newDeliveryGroups.findIndex(
            item => item.id === deliveryGroupID
        );
        newDeliveryGroups[index] = { ...deliveryGroup, id: deliveryGroupID };
        dispatch({ type: SET_GROUPS, payload: newDeliveryGroups });
    };
};

export const getDeliveryGroupFromStore = deliveryGroupID => {
    const { deliveryGroups } = store.getState().deliveryGroups;
    return deliveryGroups.find(
        deliveryGroup => deliveryGroup.id === deliveryGroupID
    );
};
