import {
    ADD_EDIT_DELIVERY_MAN_LOADING,
    ADD_EDIT_DELIVERY_MAN_ERROR,
    ADD_EDIT_DELIVERY_MAN_SUCCESS,
    DELETE_DELIVERY_MAN_LOADING,
    DELETE_DELIVERY_MAN_ERROR,
    DELETE_DELIVERY_MAN_SUCCESS,
    SET_DELIVERY_MANS,
} from '../types';

const initialState = {
    addEditDeliveryManLoading: false,
    addEditDeliveryManError: false,
    deliveryMans: [],
    deleteDeliveryManLoading: false,
    deleteDeliveryManError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_EDIT_DELIVERY_MAN_LOADING:
            return {
                ...state,
                addEditDeliveryManLoading: true,
                addEditDeliveryManError: false,
            };
        case ADD_EDIT_DELIVERY_MAN_ERROR:
            return {
                ...state,
                addEditDeliveryManLoading: false,
                addEditDeliveryManError: payload,
            };
        case ADD_EDIT_DELIVERY_MAN_SUCCESS:
            return {
                ...state,
                addEditDeliveryManLoading: false,
                addEditDeliveryManError: false,
                deliveryMans: [...payload]
            };
        case SET_DELIVERY_MANS:
            return {
                ...state,
                deliveryMans: [...payload],
            };
        case DELETE_DELIVERY_MAN_LOADING:
            return {
                ...state,
                deleteDeliveryManLoading: true,
                deleteDeliveryManError: false,
            };
        case DELETE_DELIVERY_MAN_ERROR:
            return {
                ...state,
                deleteDeliveryManLoading: false,
                deleteDeliveryManError: payload,
            };
        case DELETE_DELIVERY_MAN_SUCCESS:
            return {
                ...state,
                deleteDeliveryManLoading: false,
                deleteDeliveryManError: false,
            };
        default:
            return state;
    }
};
