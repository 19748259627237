import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

import ModalBody from '@sharedComponents/ModalBody';
import { SET_CATEGORY_FILTER } from '@redux/types';
import { categories } from '@constants/categories';
import useStyles from './styles';

const CategoryModal = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleClose = () => setOpen(false);

    const { category: _category } = useSelector(state => state.inventory);
    const [category, setCategory] = useState(_category);

    const handleFilter = () => {
        dispatch({ type: SET_CATEGORY_FILTER, payload: category });
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='category-modal'>
            <DialogTitle id='label-modal'>Filtrar por Categoria</DialogTitle>
            <DialogContent>
                <ModalBody>
                    <FormControl variant='standard'>
                        <InputLabel id='categoriesLabel'>Categorias</InputLabel>
                        <Select
                            className={classes.inputWidth}
                            labelId='categoriesLabel'
                            value={category}
                            label='Categorias'
                            onChange={event => {
                                setCategory(event.target.value);
                            }}>
                            <MenuItem value={0}>Todos</MenuItem>
                            {categories.map(cat => (
                                <MenuItem key={cat.id} value={cat.id}>
                                    {cat.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ModalBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancelar
                </Button>
                <Button onClick={handleFilter} color='secondary'>
                    Filtrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CategoryModal;

CategoryModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};
