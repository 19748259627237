import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: { paddingTop: 10, backgroundColor: '#F7F7F7' },
    paper: { padding: 10 },
    body: { marginTop: 10 },
    gridItem: { padding: 10, paddingLeft: 0 },
    gridItemR: { padding: 10, paddingRight: 0 },
    number: { fontSize: '1.25rem' },
    shoppingCart: {
        backgroundColor: 'orange',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        padding: 5,
    },
    attachMoney: {
        backgroundColor: 'green',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        padding: 5,
    },
    store: {
        backgroundColor: '#3f50b5',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        padding: 5,
    },
    moveToInbox: {
        backgroundColor: '#f50057',
        color: 'white',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        padding: 5,
    },
}));

export default useStyles;
