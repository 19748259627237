// key: ID, value: NAME
export const categoryValues = {
    1: 'Diario',
    2: 'Periodico',
    3: 'Revista',
};

export const categories = [
    { id: 1, name: 'Diario' },
    { id: 2, name: 'Periodico' },
    { id: 3, name: 'Revista' },
];

