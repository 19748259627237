const checkErrorPublicationsSelector = publicationRows => {
    if (
        publicationRows &&
        publicationRows.length > 0 &&
        publicationRows.every(
            publicationRow =>
                publicationRow.amount &&
                publicationRow.amount > 0 &&
                publicationRow.inDistProduct &&
                publicationRow.inDistProduct.productID &&
                typeof publicationRow.inDistProduct.productID === 'string'
        )
    ) {
        return {};
    } else {
        return {
            publications: 'Debe seleccionar por lo menos un producto valido',
        };
    }
};

export default checkErrorPublicationsSelector;
