import React from 'react';
import { useSelector } from 'react-redux';

import useInventorySearch from '@hooks/inventorySearchHook';
import VerifyArray from '@sharedComponents/VerifyArray';
import NavigationOnInventory from '../NavigationOnInventory';
import CategorySubtitle from '../CategorySubtitle';
import ListOfItems from '../ListOfItems';



const Inventory = () => {
    const { products } = useSelector(state => state.products)
    const { category, tags } = useSelector(state => state.inventory);
    const [filteredItems, setSearch] = useInventorySearch(products, category, tags);

    return (
        <>
            <NavigationOnInventory setSearch={setSearch} />
            <CategorySubtitle category={category} />
            <VerifyArray array={filteredItems} defaultItem='producto'>
                <ListOfItems items={filteredItems} />
            </VerifyArray>
        </>
    );
};

export default Inventory;
