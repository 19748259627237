import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { updateReturnsForInBoxEdition } from '@redux/actions/boxes';
import { getEditionFromStore } from '@redux/actions/editions';
import { useDispatch } from 'react-redux';
import CuantityCont from '@sharedComponents/CuantityCont';

const Product = ({
    boxID,
    quantity,
    inBoxEdition,
    updateEdition,
    handleOpenDeleteModal,
}) => {
    const dispatch = useDispatch();
    const handleSetCount = value => {
        value = Math.ceil(value);
        if (value < 0) value = 0;
        else if (value > 999) value = 999;

        const diferential =
            inBoxEdition.returnedStock > value
                ? inBoxEdition.returnedStock - value
                : value - inBoxEdition.returnedStock;

        let _stock =
            inBoxEdition.returnedStock > value
                ? stock + diferential
                : stock - diferential;

        if (_stock < 0) {
            value = value + _stock;
            _stock = 0;
        }

        const _returns =
            inBoxEdition.returnedStock > value
                ? returns - diferential
                : returns + diferential;

        updateEdition({ ...inBoxEdition, returnedStock: value });
        setStock(_stock);
        setReturns(_returns);
        dispatch(
            updateReturnsForInBoxEdition(
                value,
                _stock,
                _returns,
                diferential,
                boxID,
                inBoxEdition
            )
        );
    };

    const [stock, setStock] = useState(0);
    const [returns, setReturns] = useState(0);

    useEffect(() => {
        if (quantity) {
            const edition = getEditionFromStore(
                inBoxEdition.productID,
                inBoxEdition.editionID
            );
            if (edition) {
                setStock(edition.stock);
                setReturns(edition.returns);
            }
        }
    }, []);

    const handleDelete = () => handleOpenDeleteModal(inBoxEdition);

    return (
        <ListItem>
            <ListItemText
                primary={inBoxEdition.productName}
                secondary={`Edicion: ${inBoxEdition.editionNumber}, ${
                    quantity
                        ? 'Stock: ' + stock
                        : 'Cantidad ingresada: ' + inBoxEdition.ingresedStock
                }`}
            />
            <ListItemSecondaryAction>
                {quantity ? (
                    <CuantityCont
                        count={inBoxEdition.returnedStock}
                        setCount={handleSetCount}
                    />
                ) : (
                    <IconButton aria-label='Edit'>
                        <Edit />
                    </IconButton>
                )}
                <IconButton aria-label='Delete' onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default Product;

Product.propTypes = {
    element: PropTypes.object,
    quantity: PropTypes.bool,
    handleOpenDeleteModal: PropTypes.func,
};
