import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    tags: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(15),
        paddingRight: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageInput: {
        display: 'none',
    },
    headerImage: {
        width: '100%',
        height: '250px',
    },
    photoButton: {
        top: '270px',
        right: theme.spacing(5),
        position: 'absolute',
    },
    photoSVG: {
        color: theme.palette.primary.contrastText,
    },
    note: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    grid: {
        marginTop: theme.spacing(5),
    },
    inputWidth: {
        width: '182px',
    },
    notesInput: {
        width: '182px',
    },
    chipsContainer: {
        maxWidth: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));
