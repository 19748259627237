import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import {
    Button,
    Grid,
    InputLabel,
    Typography,
    TextField,
} from '@material-ui/core';

import { addProviderFromStore } from '@redux/actions/providers';
import Loading from '@sharedComponents/Loading';
import { schema } from './schema';
import useStyles from './styles';

const ProvidersForm = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { addProvidersLoading } = useSelector(state => state.providers);

    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const onSubmit = data => {
        dispatch(
            addProviderFromStore(data, () => history.push('/proveedores'))
        );
    };

    if(addProvidersLoading) return <Loading />

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Crear Proveedor</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='company'>Empresa</InputLabel>
                    <TextField
                        error={!!errors.company}
                        required
                        id='company'
                        type='text'
                        name='company'
                        inputRef={register}
                        helperText={errors.company && errors.company.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='nombreProveedor'>
                        Representante
                    </InputLabel>
                    <TextField
                        error={!!errors.representant}
                        required
                        id='representant'
                        type='text'
                        name='representant'
                        inputRef={register}
                        helperText={
                            errors.representant && errors.representant.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='telephone1'>
                        Teléfono de contacto 1
                    </InputLabel>
                    <TextField
                        error={!!errors.telephone1}
                        required
                        id='telephone1'
                        type='string'
                        name='telephone1'
                        inputRef={register}
                        helperText={
                            errors.telephone1 && errors.telephone1.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='telephone2'>
                        Teléfono de contacto 2
                    </InputLabel>
                    <TextField
                        error={!!errors.telephone2}
                        id='telephone2'
                        type='string'
                        name='telephone2'
                        inputRef={register}
                        helperText={
                            errors.telephone2 && errors.telephone2.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='email'>Email</InputLabel>
                    <TextField
                        error={errors.email}
                        required
                        id='email'
                        type='text'
                        name='email'
                        inputRef={register}
                        helperText={errors.email && errors.email.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='direccionRetiro'>
                        Dirección de retiro
                    </InputLabel>
                    <TextField
                        error={errors.returnDir}
                        required
                        id='returnDir'
                        type='text'
                        name='returnDir'
                        inputRef={register}
                        helperText={
                            errors.returnDir && errors.returnDir.message
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='note'>Comentarios</InputLabel>
                    <TextField
                        multiline
                        fullWidth
                        error={errors.note}
                        id='note'
                        type='text'
                        name='note'
                        inputRef={register}
                        helperText={errors.note && errors.note.message}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        disabled={!isValid || !isDirty}
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Crear
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default ProvidersForm;
