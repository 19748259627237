import { db } from '../../firebase';
import { createKiosk, linkKiosk } from '@services/kiosk';
import { editUser } from '@services/user';
import {
    ADD_KIOSK_FAILED,
    ADD_KIOSK_LOADING,
    ADD_KIOSK_SUCCESS,
    LINK_KIOSK_LOADING,
    LINK_KIOSK_SUCCESS,
    LINK_KIOSK_FAILED,
} from '../types';

export const addKioskFromStore = (data, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_KIOSK_LOADING });
        try {
            const kiosk = await createKiosk(data);
            const kioskReference = db.collection('kiosks').doc(kiosk.id);
            await editUser({ kiosk: kioskReference });
            dispatch({
                type: LINK_KIOSK_SUCCESS,
                payload: kioskReference,
            });
            dispatch({ type: ADD_KIOSK_SUCCESS, payload: kiosk });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_KIOSK_FAILED, payload: error });
        }
    };
};

export const linkKioskToUserFromStore = (referenceCode, callback) => {
    return async dispatch => {
        dispatch({ type: LINK_KIOSK_LOADING });
        try {
            const kioskReference = await linkKiosk(referenceCode);
            if (kioskReference) {
                await editUser({ kiosk: kioskReference });
                dispatch({
                    type: LINK_KIOSK_SUCCESS,
                    payload: kioskReference,
                });
                if (callback) {
                    callback();
                }
            } else {
                dispatch({
                    type: LINK_KIOSK_FAILED,
                    payload: 'Codigo de referencia inválido',
                });
            }
        } catch (error) {
            dispatch({ type: LINK_KIOSK_FAILED, payload: error.message });
        }
    };
};
