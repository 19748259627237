import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Check from '@material-ui/icons/Check';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import { addProductFromStore } from '@redux/actions/products';
import Loading from '@sharedComponents/Loading';
import { categories } from '@constants/categories';
import { MenuProps, getStyles } from './helpers';
import tags from '@constants/tags';
import { useStyles } from './styles';
import WorkingOnImages from '../../../../assets/images/working-on-images.png';

function NewProduct({ history }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { addProductsLoading } = useSelector(state => state.products);
    const { providers } = useSelector(state => state.providers);

    const [tag, setTag] = useState([]);
    const [note, setNote] = useState('');
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [provider, setProvider] = useState('');
    const [gainings, setGainings] = useState('');
    const [returns, setReturns] = useState('');

    const handleGainings = value => {
        if (value < 0) {
            value = 0;
        } else if (value > 100) {
            value = 100;
        }
        setGainings(value);
    };

    const handleReturns = value => {
        if (value < 0) {
            value = 0;
        } else if (value > 365) {
            value = 365;
        }
        setReturns(value);
    };

    const confirmProduct = () => {
        const product = {
            tag,
            name,
            category,
            provider,
            gainings: Number(gainings),
            returns: Number(category === 1 ? 1 : returns),
            note,
            existences: 0,
            lastPrice: 0,
            selled: 0,
        };

        dispatch(addProductFromStore(product, () => history.goBack()));
    };

    if (addProductsLoading) return <Loading />;

    return (
        <>
            <div>
                <img
                    src={WorkingOnImages}
                    className={classes.headerImage}
                    alt='imagenheader'
                />
                <FloatingActionButton
                    tooltip='Añadir imagen'
                    clickHandler={() => {}}
                    position='image'
                    color='primary'
                    style={classes.photoButton}
                    icon={
                        <IconButton>
                            <label
                                htmlFor='publicationFile'
                                className={classes.photoSVG}>
                                <AddPhotoAlternate />
                            </label>
                            <input
                                type='file'
                                id='publicationFile'
                                accept='image/png, image/jpeg'
                                className={classes.imageInput}
                            />
                        </IconButton>
                    }
                />
            </div>
            <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}
                spacing={3}>
                <Grid item xs={12}>
                    <InputLabel htmlFor='name'>Nombre</InputLabel>
                    <Input
                        id='name'
                        type='text'
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined'>
                        <InputLabel id='categoriesLabel'>Categorias</InputLabel>
                        <Select
                            className={classes.inputWidth}
                            labelId='categoriesLabel'
                            value={category}
                            label='Categorias'
                            onChange={event => {
                                setCategory(event.target.value);
                            }}>
                            {categories.map(cat => (
                                <MenuItem key={cat.id} value={cat.id}>
                                    {cat.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant='outlined'>
                        <InputLabel id='providerLabel'>Proovedor</InputLabel>
                        <Select
                            className={classes.inputWidth}
                            labelId='providerLabel'
                            value={provider}
                            label='Proovedor'
                            onChange={event => {
                                setProvider(event.target.value);
                            }}>
                            {providers.map(provider => (
                                <MenuItem key={provider.id} value={provider.id}>
                                    {provider.company}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor='gainings'>Ganancia</InputLabel>
                    <Input
                        type='number'
                        id='gainings'
                        value={gainings}
                        onChange={event => handleGainings(event.target.value)}
                        className={classes.inputWidth}
                        endAdornment={
                            <InputAdornment position='end'>%</InputAdornment>
                        }
                        aria-describedby='gainings-input'
                        inputProps={{
                            'aria-label': 'gainings',
                        }}
                    />
                </Grid>
                {category !== 1 && (
                    <Grid item xs={12}>
                        <InputLabel htmlFor='returns'>
                            Dias para la devolucion
                        </InputLabel>
                        <Input
                            type='number'
                            id='returns'
                            value={returns}
                            onChange={event =>
                                handleReturns(event.target.value)
                            }
                            aria-describedby='returns-input'
                            inputProps={{
                                'aria-label': 'returns',
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <InputLabel htmlFor='notes'>Notas de producto</InputLabel>
                    <Input
                        id='notes'
                        type='text'
                        multiline
                        className={classes.notesInput}
                        value={note}
                        onChange={event => setNote(event.target.value)}
                    />
                </Grid>
            </Grid>
            <div className={classes.chipsContainer}>
                <FormControl className={classes.tags} fullWidth={true}>
                    <InputLabel htmlFor='select-multiple-checkbox'>
                        Etiquetas
                    </InputLabel>
                    <Select
                        multiple
                        value={tag}
                        onChange={e => setTag(e.target.value)}
                        input={<Input />}
                        renderValue={selected => (
                            <div className={classes.chips}>
                                {selected.map(value => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        className={classes.chip}
                                    />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}>
                        {tags.map(name => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, tag, theme)}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FloatingActionButton
                    aria='accept'
                    tooltip='Confirmar Producto'
                    icon={<Check />}
                    clickHandler={confirmProduct}
                />
            </div>
        </>
    );
}

NewProduct.propTypes = {
    classes: PropTypes.object,
};

export default NewProduct;
