import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Add from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import Publication from '../Publication';
import PublicationSingleLine from '../PublicationSingleLine';
import { styles } from './styles';

import SearchComponent from '@sharedComponents/SearchComponent';
import useSearch from '@hooks/searchHook';
import VerifyArray from '@sharedComponents/VerifyArray';
import { useDispatch, useSelector } from 'react-redux';

function MagazineSeachList({ classes, location, history }) {
    const { products } = useSelector(state => state.products);
    
    const [filteredMagazines, setSearch] = useSearch(products, 'name');
    const dispatch = useDispatch();
    const { state } = location;
    const simpleList = state.simpleList ? state.simpleList : false;

    const handleClickPublication = magazine => {
        dispatch({ type: state.typeDispatcher, payload: magazine });
        history.goBack();
    };

    const makeMagazines = () => {
        return (
            <>
                {filteredMagazines.map((magazine, index) => {
                    return (
                        <div key={index}>
                            {simpleList ? (
                                <PublicationSingleLine
                                    magazine={magazine}
                                    clickHandler={() =>
                                        handleClickPublication(magazine)
                                    }
                                />
                            ) : (
                                <Publication
                                    magazine={magazine}
                                    clickHandler={handleClickPublication}
                                />
                            )}
                            {index + 1 !== filteredMagazines.length ? <Divider /> : null}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                spacing={3}
                className={classes.grid}>
                <div className={classes.reduce}>
                    <SearchComponent title='producto' setSearch={setSearch} />
                </div>
                <div className={classes.list}>
                    <List
                        component='nav'
                        subheader={
                            <ListSubheader component='div' align='center'>
                                Inventario de publicaciones
                            </ListSubheader>
                        }>
                        <VerifyArray
                            array={filteredMagazines}
                            defaultItem='publicacion'
                            femenine={true}
                            redirect='/product/Nuevo/edit'>
                            {makeMagazines()}
                        </VerifyArray>
                    </List>
                </div>
                {simpleList && (
                    <Link to='/product/Nuevo/edit'>
                        <FloatingActionButton
                            color='secondary'
                            icon={<Add />}
                            tooltip='Añade un nuevo producto'
                            aria='add a product'
                            clickHandler={() => {}}
                        />
                    </Link>
                )}
            </Grid>
        </>
    );
}

MagazineSeachList.propTypes = {
    classes: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
};

export default withStyles(styles)(MagazineSeachList);
