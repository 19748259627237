import React from 'react';
import PropTypes from 'prop-types';

import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    IconButton,
    Paper,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { useStyles } from '../styles';
import { getInDistProductsFromStore } from '@redux/actions/products';

const publicationData = {
    inDistProduct: null,
    amount: '',
};

const EditDistributionPublications = ({
    publicationRows,
    setPublicationRows,
}) => {
    const classes = useStyles();
    const inDistProducts = getInDistProductsFromStore()

    const handleAddPublicationRow = () =>
        setPublicationRows([...publicationRows, { ...publicationData }]);

    const handleRemovePublicationRow = index => {
        const values = [...publicationRows];
        values.splice(index, 1);
        setPublicationRows(values);
    };

    const handleChangeInput = (index, eventName, newValue) => {
        const values = [...publicationRows];
        values[index][eventName] = newValue;
        setPublicationRows(values);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label='publications'>
                <TableHead>
                    <TableCell component='tr'>
                        <td>Producto</td>
                    </TableCell>
                    <TableCell component='tr'>
                        <td>Cant.</td>
                    </TableCell>
                    <TableCell component='tr' />
                    <TableCell component='tr' />
                </TableHead>
                <TableBody>
                    {publicationRows.map((inDistProductRow, index) => (
                        <TableRow key={`publicationRow-${index}`}>
                            <TableCell scope='row'>
                                <Autocomplete
                                    id={`pub-${index}`}
                                    options={inDistProducts}
                                    getOptionLabel={option => option.productName}
                                    onChange={(event, newValue) =>
                                        handleChangeInput(
                                            index,
                                            'inDistProduct',
                                            newValue
                                        )
                                    }
                                    className={classes.flex}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            className={classes.textFieldReduced}
                                            label='Producto'
                                        />
                                    )}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type='number'
                                    className={classes.pubCant}
                                    value={inDistProductRow.amount}
                                    name='amount'
                                    onChange={event =>
                                        handleChangeInput(
                                            index,
                                            event.target.name,
                                            event.target.value
                                        )
                                    }
                                    id={`pamount-${index}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin='normal'
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={handleAddPublicationRow}
                                    className={classes.addButton}>
                                    <Add />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                {index !== 0 && (
                                    <IconButton
                                        onClick={() =>
                                            handleRemovePublicationRow(index)
                                        }
                                        className={classes.addButton}>
                                        <Remove />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EditDistributionPublications;


EditDistributionPublications.propTypes = {
    publicationRows: PropTypes.array,
    setPublicationRows: PropTypes.func,
}