import React, { useState, useMemo, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { useStyles } from './styles';

const autocompleteService = { current: null };

const AdressPlaceSelector = ({
    value,
    setValue,
    initialInputValue,
    fullWidth,
    city
}) => {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState(
        initialInputValue ? initialInputValue : ''
    );
    const [options, setOptions] = useState([]);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(
                    request,
                    callback
                );
            }, 200),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const input = city ? inputValue + ', ' + city : inputValue 

        fetch({ input }, results => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, city]);

    return (
        <Autocomplete
            id='google-map-demo'
            className={classes.flex}
            fullWidth={fullWidth}
            getOptionLabel={option =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={x => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label='Dirección'
                    fullWidth={fullWidth}
                    className={fullWidth ? '' : classes.textField}
                />
            )}
            renderOption={option => {
                const matches =
                    option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map(match => [
                        match.offset,
                        match.offset + match.length,
                    ])
                );

                return (
                    <Grid container alignItems='center'>
                        <Grid item>
                            <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}>
                                    {part.text}
                                </span>
                            ))}

                            <Typography variant='body2' color='textSecondary'>
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

export default AdressPlaceSelector