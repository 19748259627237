import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

const ListOfDeliveryMan = ({ deliveryMans, handleEdit, handleDelete }) => {
    return (
        <List>
            {deliveryMans.map((deliveryMan, index) => (
                <div key={`group[${deliveryMan.name}]`}>
                    <ListItem button component={Link} to=''>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h6',
                            }}
                            primary={deliveryMan.name}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge='end'
                                aria-label='edit'
                                onClick={() => handleEdit(deliveryMan.id)}>
                                <Edit />
                            </IconButton>
                            <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => handleDelete(deliveryMan.id)}>
                                <Delete />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {index !== deliveryMans.length - 1 && <Divider />}
                </div>
            ))}
        </List>
    );
};

export default ListOfDeliveryMan;

ListOfDeliveryMan.propTypes = {
    deliveryMans: PropTypes.array,
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func,
}