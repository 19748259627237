import React from 'react';
import PropTypes from 'prop-types'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';

import ModalBody from '@sharedComponents/ModalBody';

const DeleteEditionInBoxModal = ({ open, handleClose, handleAccept }) => {
    return (
        <Dialog
            open={open}
            setOpen={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='delete-edition-modal'>
            <DialogTitle id='label-modal'>Eliminar una edicion</DialogTitle>
            <DialogContent>
                <ModalBody>
                    <Typography variant='body1'>
                        Estas seguro que quieres eliminar esta edicion? esta
                        accion eliminara la edicion tanto de la caja que la creo
                        como del sistema.
                    </Typography>
                </ModalBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancelar
                </Button>
                <Button onClick={handleAccept} color='secondary'>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteEditionInBoxModal;

DeleteEditionInBoxModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAccept: PropTypes.func,
}