import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import { removeEditionFromKiosk } from '@redux/actions/editions';
import DeleteEditionInBoxModal from '../DeleteEditionInBoxModal';
import { styles } from './styles';
import Product from '../Product';

const ListOfProducts = ({ classes, items, quantity = false, boxID, deleteEdition, updateEdition }) => {
    const dispatch = useDispatch();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteModalProduct, setDeleteModalProduct] = useState(null);

    const handleOpenDeleteModal = product => {
        setDeleteModalProduct(product);
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setDeleteModalProduct(null);
    };

    const handleAcceptDeleteModal = () => {
        dispatch(
            removeEditionFromKiosk(
                deleteModalProduct.productID,
                deleteModalProduct.id,
                boxID
            )
        );
        deleteEdition(deleteModalProduct.id)
        handleCloseDeleteModal();
    };

    return (
        <div>
            <Grid item xs={12} md={6}>
                <DeleteEditionInBoxModal
                    open={openDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    handleAccept={handleAcceptDeleteModal}
                />
                <div className={classes.demo}>
                    <List dense={false}>
                        {items.map((inBoxEdition, index) => {
                            return (
                                <Product
                                    handleOpenDeleteModal={
                                        handleOpenDeleteModal
                                    }
                                    boxID={boxID}
                                    key={index}
                                    updateEdition={updateEdition}
                                    inBoxEdition={inBoxEdition}
                                    quantity={quantity}
                                    classes={classes}
                                />
                            );
                        })}
                    </List>
                </div>
            </Grid>
        </div>
    );
};

ListOfProducts.propTypes = {
    classes: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    quantity: PropTypes.bool,
    boxID: PropTypes.string,
    deleteEdition: PropTypes.func,
};

export default withStyles(styles)(ListOfProducts);
