import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Done from '@material-ui/icons/Done';

import Loading from '@sharedComponents/Loading';
import useStyles from './styles';
import {
    editNoteFromStore,
    getNoteByIDFromStore,
} from '@redux/actions/notes';

const NoteEdit = () => {
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch()

    const { editNotesLoading } = useSelector(state => state.notes);
    const [disabled, setDisabled] = useState(true);
    const [text, setText] = useState('');
    const [note, setNote] = useState([]);

    useEffect(() => {
        const findedNote = getNoteByIDFromStore(id);
        if (findedNote) {
            setNote(findedNote);
            setText(findedNote.text);
        }
    }, [id]);

    useEffect(() => {
        if (text !== note.text) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [text]);

    const handleClickDone = () => {
        dispatch(editNoteFromStore(id, text, () => history.push('/notas')));
    };

    if (editNotesLoading) return <Loading />;

    return (
        <>
            <div className={classes.helpBar}>
                <IconButton
                    onClick={handleClickDone}
                    aria-label='done'
                    color='secondary'
                    disabled={disabled}>
                    <Done />
                </IconButton>
            </div>
            <div className={classes.textField}>
                <TextField
                    id='note-text'
                    multiline
                    fullWidth
                    autoFocus
                    margin='normal'
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
            </div>
        </>
    );
};

export default NoteEdit;
