import { useState, useEffect } from 'react';

const useSearch = (items, key = 'name') => {
    const [search, setSearch] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);

    const onSearch = string => {
        setSearch(string.trim());
    };

    useEffect(() => {
        if (items) {
            setFilteredItems(
                items.filter(
                    element => element[key].toLowerCase().indexOf(search) !== -1
                )
            );
        }
    }, [search, items]);

    return [filteredItems, onSearch];
};

export default useSearch;
