import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Grid, InputLabel, Typography, Input } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import { getProviderByIDFromStore } from '@redux/actions/providers'
import useStyles from './styles';

const ProvidersDetail = () => {
    const classes = useStyles();
    const { id } = useParams();
    const [provider, setProvider] = useState(null);

    useEffect(() => {
        const prov = getProviderByIDFromStore(id);
        setProvider(prov);
    }, [id]);

    if (!provider) return <></>;
    return (
        <>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Detalle de Proveedor</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='empresa'>Empresa</InputLabel>
                    <Input
                        id='empresa'
                        type='text'
                        name='empresa'
                        readOnly
                        defaultValue={provider.company}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='nombreProveedor'>
                        Representante
                    </InputLabel>
                    <Input
                        readOnly
                        id='nombre'
                        type='text'
                        name='nombre'
                        defaultValue={provider.representant}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='telefonoUno'>
                        Teléfono de contacto 1
                    </InputLabel>
                    <Input
                        readOnly
                        id='telefonoUno'
                        type='string'
                        name='telefonoUno'
                        defaultValue={provider.telephone1}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='telefonoDos'>
                        Teléfono de contacto 2
                    </InputLabel>
                    <Input
                        readOnly
                        id='telefonoDos'
                        type='string'
                        name='telefonoDos'
                        defaultValue={provider.telephone2}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='email'>Email</InputLabel>
                    <Input
                        readOnly
                        id='email'
                        type='text'
                        name='email'
                        defaultValue={provider.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='direccionRetiro'>
                        Dirección de retiro
                    </InputLabel>
                    <Input
                        readOnly
                        id='direccion'
                        type='text'
                        name='direccion'
                        defaultValue={provider.returnDir}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='comentarios'>Comentarios</InputLabel>
                    <Input
                        multiline
                        fullWidth
                        id='comentarios'
                        type='text'
                        name='comentarios'
                        readOnly
                        defaultValue={provider.note}
                    />
                </Grid>
            </Grid>
            <Link to={`/proveedores/editar/${id}`}>
                <FloatingActionButton
                    icon={<Edit />}
                    aria='Add a provider'
                    tooltip='Add a new Provider'
                    clickHandler={() => {}}
                />
            </Link>
        </>
    );
};

export default ProvidersDetail;
