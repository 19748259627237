import { useState, useEffect } from 'react';
import useSearch from './searchHook';

const inventorySearchHook = (items, category, tags) => {
    const [filteredItems, setFilteredItems] = useState(items);
    const [useSearchItems, setUseSearch] = useSearch(filteredItems);

    useEffect(() => {
        //category
        let categoryFiltered = [];
        if (category) {
            categoryFiltered = items.filter(item => item.category === category);
        } else {
            categoryFiltered = items;
        }

        //tags
        let tagsFiltered = [];

        if (tags.length > 0) {
            tagsFiltered = categoryFiltered.filter(item =>
                item.tag.some(itemTag => tags.includes(itemTag))
            );
        } else {
            tagsFiltered = categoryFiltered;
        }

        setFilteredItems(tagsFiltered);
    }, [items, category, tags]);

    return [useSearchItems, setUseSearch];
};

export default inventorySearchHook;
