import { wrapKioskQuery } from './kiosk';

const _getProviders = async kioskReference => {
    const snapshot = kioskReference.collection('providers').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getProviderById = async (id, kioskReference) => {
    const docRef = kioskReference.collection('providers').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editProvider = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('providers').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

const _addProvider = async (data, kioskReference) => {
    const providersRef = kioskReference.collection('providers');
    const docRef = await providersRef.add({ ...data });
    return docRef;
};

export const getProviders = wrapKioskQuery(_getProviders);
export const getProviderById = wrapKioskQuery(_getProviderById);
export const editProvider = wrapKioskQuery(_editProvider);
export const addProvider = wrapKioskQuery(_addProvider);
