import { wrapKioskQuery } from './kiosk';

const _getProducts = async kioskReference => {
    const snapshot = kioskReference.collection('products').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getProductsAndEditions = async kioskReference => {
    const snapshot = kioskReference.collection('products').get();
    const products = (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
    const editions = await Promise.all(products.map(async product => {
        const editionsSnapshot = kioskReference.collection('products').doc(product.id).collection('editions').get();
        return (await editionsSnapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
    }));

    return products.map((product, index) => ({ ...product, editions: editions[index] }));
};

const _getProductById = async (id, kioskReference) => {
    const docRef = kioskReference.collection('products').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editProduct = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('products').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

const _addProduct = async (data, kioskReference) => {
    const productsRef = kioskReference.collection('products');
    const docRef = await productsRef.add({ ...data });
    return docRef;
};

export const getProducts = wrapKioskQuery(_getProducts);
export const getProductById = wrapKioskQuery(_getProductById);
export const editProduct = wrapKioskQuery(_editProduct);
export const addProduct = wrapKioskQuery(_addProduct);
export const getProductsAndEditions = wrapKioskQuery(_getProductsAndEditions);