import React from 'react';
import PropTypes from 'prop-types';
import DefaultMessage from './DefaultMessage';

const VerifyArray = ({
    array,
    children,
    message,
    defaultItem,
    redirect,
    femenine,
}) => {
    if (array && array.length > 0) {
        return children;
    } else {
        if (message) {
            return message;
        } else {
            return (
                <DefaultMessage
                    item={defaultItem}
                    redirect={redirect}
                    femenine={femenine}
                />
            );
        }
    }
};

VerifyArray.propTypes = {
    array: PropTypes.array,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    message: PropTypes.any,
    defaultItem: PropTypes.string,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    femenine: PropTypes.bool
};

export default VerifyArray;
