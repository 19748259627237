import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    
    headerImage: {
        width: '100%',
        height: '250px',
        zIndex: 1,
    },
    background: {
        backgroundColor: theme.palette.background.backLight,
    },
    firstPaper: {
        display: 'block',
        position: 'absolute',
        top: '300px',
        left: '5%',
        zIndex: 2,
        width: '90%',
    },
    paperPadding: { padding: '10px' },
    chip: {margin: 2},
    notes: {marginTop: 20},
    chips: {marginTop: 10},
    bigNumber: {
        fontSize: '30px',
        marginRight: '5px',
    },
    paper: {
        display: 'block',
        position: 'absolute',
        left: '5%',
        zIndex: 2,
        width: '90%',
    }
}));