import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: 'red',
        maxWidth: 182,
    },
}));

export default useStyles;
