import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Add from '@material-ui/icons/Add';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import SearchComponent from '@sharedComponents/SearchComponent';
import VerifyArray from '@sharedComponents/VerifyArray';
import useSearch from '@hooks/searchHook';
import ClientList from '../ClientList';


function Clients() {

    const { clients } = useSelector(state => state.clients);
    const [filteredClients, setSearch] = useSearch(clients);

    return (
        <>
            <SearchComponent title='cliente' setSearch={setSearch} flex />
            <VerifyArray
                array={filteredClients}
                defaultItem='cliente'
                redirect='/clientes/add'>
                <ClientList clients={filteredClients} />
            </VerifyArray>
            <Link to='/clientes/add'>
                <FloatingActionButton
                    icon={<Add />}
                    aria='Add a client'
                    tooltip='Añadir un nuevo cliente'
                />
            </Link>
        </>
    );
}

export default Clients;
