export const SET_CLIENTS = 'SET_CLIENTS'

export const EDIT_CLIENT_LOADING = 'EDIT_CLIENT_LOADING'
export const EDIT_CLIENT_SUCCSESS = 'EDIT_CLIENT_SUCCSESS'
export const EDIT_CLIENT_ERROR = 'EDIT_CLIENT_ERROR'

export const ADD_CLIENT_SUCCSESS = 'ADD_CLIENT_SUCCSESS'
export const ADD_CLIENT_LOADING = 'ADD_CLIENT_LOADING'
export const ADD_CLIENT_ERROR = 'ADD_CLIENT_ERROR'

export const DELETE_CLIENT_LOADING = 'DELETE_CLIENT_LOADING'
export const DELETE_CLIENT_SUCCSESS = 'DELETE_CLIENT_SUCCSESS'
export const DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR'