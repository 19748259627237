import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    sendButton: {
        marginTop: theme.spacing(3),
    },
    grid: {
        marginTop: theme.spacing(3),
    },

    title: {
        marginBottom: theme.spacing(1),
    },
}));

export default useStyles;
