import { wrapKioskQuery } from './kiosk';

const _getDeliveryMans = async kioskReference => {
    const snapshot = kioskReference.collection('deliveryMans').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getDeliveryMan = async (id, kioskReference) => {
    const docRef = kioskReference.collection('deliveryMans').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
const _editDeliveryMan = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('deliveryMans').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

const _addDeliveryMan = async (data, kioskReference) => {
    const deliveryMansRef = kioskReference.collection('deliveryMans');
    const docRef = await deliveryMansRef.add({ ...data });
    return docRef;
};

export const _deleteDeliveryMan = async (id, kioskReference) => {
    const docRef = kioskReference.collection('deliveryMans').doc(id);
    await docRef.delete();
};

export const getDeliveryMans = wrapKioskQuery(_getDeliveryMans);
export const getDeliveryMan = wrapKioskQuery(_getDeliveryMan);
export const editDeliveryMan = wrapKioskQuery(_editDeliveryMan);
export const addDeliveryMan = wrapKioskQuery(_addDeliveryMan);
export const deleteDeliveryMan = wrapKioskQuery(_deleteDeliveryMan);