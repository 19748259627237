import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Done from '@material-ui/icons/Done';

import Loading from '@sharedComponents/Loading';
import useStyles from './styles';
import { addNoteFromStore } from '@redux/actions/notes';
import { useDispatch, useSelector } from 'react-redux';

const Note = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { addNotesLoading } = useSelector(state => state.notes);
    const [disabled, setDisabled] = useState(true);
    const [text, setText] = useState('');

    useEffect(() => {
        if (text && text !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [text]);

    const handleClickDone = () => {
        dispatch(addNoteFromStore(text, () => history.push('/notas')));
    };

    if (addNotesLoading) return <Loading />;

    return (
        <>
            <div className={classes.helpBar}>
                <IconButton
                    onClick={handleClickDone}
                    aria-label='done'
                    color='secondary'
                    disabled={disabled}>
                    <Done />
                </IconButton>
            </div>
            <div className={classes.textField}>
                <TextField
                    id='note-text'
                    multiline
                    fullWidth
                    autoFocus
                    margin='normal'
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
            </div>
        </>
    );
};

export default Note;
