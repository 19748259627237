import React from 'react';

import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import AuthWrapper from '../AuthWrapper';
import DatePickerProvider from '../DatePickerProvider';
import Layout from '../Layout';
import { ReturnRoutes } from '@router';
import theme from './theme';
import { Provider } from 'react-redux';
import store from '@redux/store';
import MainLoader from '../MainLoader';

const App = () => (
    <MuiThemeProvider theme={theme}>
        <DatePickerProvider>
            <Provider store={store}>
                <Router>
                    <AuthWrapper>
                        <MainLoader>
                            <Layout >
                                <Switch>{ReturnRoutes()}</Switch>
                            </Layout>
                        </MainLoader>
                    </AuthWrapper>
                </Router>
            </Provider>
        </DatePickerProvider>
    </MuiThemeProvider>
);

export default App;
