import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDeliveryGroupFromKiosk } from '@redux/actions';
import Loading from '@sharedComponents/Loading';

const GroupDelete = ({ open, setOpen, deleteID }) => {
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);

    const { deleteDeliveryGroupLoading } = useSelector(
        state => state.deliveryGroups
    );

    const handleDelete = () => {
        if (deleteID) {
            dispatch(deleteDeliveryGroupFromKiosk(deleteID, handleClose));
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='eliminar-grupo'>
            <DialogTitle id='eliminar-grupo'>Eliminar grupo</DialogTitle>
            <DialogContent>
                {deleteDeliveryGroupLoading ? (
                    <Loading />
                ) : (
                    <DialogContentText id='eliminar-grupo'>
                        Esta seguro que desea eliminar el grupo? Esta accion es
                        irreversible.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancelar
                </Button>
                <Button
                    disabled={deleteDeliveryGroupLoading}
                    onClick={handleDelete}
                    color='secondary'>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GroupDelete;

GroupDelete.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    deleteID: PropTypes.string,
};
