import React, { useState } from 'react';
import PropTypes from 'prop-types'

import {
    Card,
    Grid,
    Select,
    MenuItem,
    IconButton,
    withStyles,
} from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';

import { styles } from '../styles';
import distributionStates from '@constants/distributionStates';
import { changeDeliveryStates } from '@redux/actions/delivery';
import { useDispatch } from 'react-redux';

const DistributionStateSelector = ({
    classes,
    selectedItems,
    setSelectedItems,
    deliveryState,
}) => {
    const dispatch = useDispatch();
    const [deliveryNewState, setDeliveryNewState] = useState(deliveryState);

    const handleClickConfirm = () => {
        dispatch(
            changeDeliveryStates(selectedItems,deliveryState, deliveryNewState, () =>
                setSelectedItems([])
            )
        );
    };
    const handleClickCancel = () => setSelectedItems([]);

    return (
        <div className={classes.selectorContainer}>
            <Card className={classes.selectedItem}>
                <Grid
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'>
                    <Grid item>
                        <span className={classes.selectedCuantitySpan}>
                            {selectedItems.length} seleccionado
                        </span>
                        <Select
                            variant='standard'
                            name='Nuevo Estado'
                            value={deliveryNewState}
                            onChange={e => setDeliveryNewState(e.target.value)}>
                            {distributionStates.map(state => (
                                <MenuItem
                                    value={state.id}
                                    key={state.id}
                                    className={classes.selectedMenuItem}>
                                    {state.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item>
                        <IconButton
                            disabled={deliveryNewState === deliveryState}
                            onClick={handleClickConfirm}
                            aria-label='confirmar'>
                            <Check />
                        </IconButton>
                        <IconButton
                            aria-label='cancelar'
                            onClick={handleClickCancel}>
                            <Clear />
                        </IconButton>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default withStyles(styles)(DistributionStateSelector);

DistributionStateSelector.propTypes = {
    classes: PropTypes.object,
    selectedItems: PropTypes.array,
    setSelectedItems: PropTypes.func,
    deliveryState: PropTypes.number,
}