import { useSelector } from 'react-redux';
import React from 'react';

import {
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Typography,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

import useStyles from './styles';

const Configurations = () => {
    const { kiosk } = useSelector(state => state.kiosk);
    const classes = useStyles();

    const copyReferenceCode = () => {
        navigator.clipboard.writeText(kiosk.referenceCode);
    };

    return (
        <>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Kiosko</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='name'>Nombre</InputLabel>
                    <Input
                        id='name'
                        type='text'
                        name='name'
                        readOnly
                        defaultValue={kiosk.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='city'>Ciudad</InputLabel>
                    <Input
                        id='city'
                        type='text'
                        name='city'
                        readOnly
                        defaultValue={kiosk.city}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='referenceCode'>
                        Codigo de Referencia
                    </InputLabel>
                    <Input
                        id='referenceCode'
                        type='text'
                        name='referenceCode'
                        readOnly
                        className={classes.referenceCode}
                        defaultValue={kiosk.referenceCode}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='copiar codigo de referencia'
                                    onClick={copyReferenceCode}>
                                    <FileCopy />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Configurations;
