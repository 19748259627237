import { addBox, deleteBox, editBox, getBox } from '@services/boxes';
import { deleteEdition } from '@services/editions';
import {
    DELETE_BOX_ERROR,
    DELETE_BOX_LOADING,
    DELETE_BOX_SUCCESS,
    NEW_BOX_ERROR,
    NEW_BOX_LOADING,
} from '../types';
import { editEditionFromKioskAndStore, getEditionFromStore } from './editions';
import {
    editProductFromStoreAndKiosk,
    getProductByIDFromStore,
} from './products';

export const addBoxToKiosk = (box, callback) => {
    return async dispatch => {
        dispatch({ type: NEW_BOX_LOADING });
        try {
            const { id, inBoxEditions } = await addBox(box);
            callback(id, inBoxEditions);
        } catch (error) {
            dispatch({ type: NEW_BOX_ERROR, palyoad: error });
        }
    };
};

export const addEditionToBox = async (boxID, inBoxEdition) => {
    try {
        const box = await getBox(boxID);
        box.inBoxEditions.push(inBoxEdition);
        delete box.id;
        await editBox(boxID, box);
    } catch (error) {
        console.error(error);
    }
};

export const deleteBoxFromKiosk = (boxID, callback) => {
    return async dispatch => {
        dispatch({ type: DELETE_BOX_LOADING });
        try {
            const box = await getBox(boxID);
            for (const edition of box.inBoxEditions) {
                await deleteEdition(edition.productID, edition.id);
            }
            await deleteBox(boxID);
            dispatch({ type: DELETE_BOX_SUCCESS });
            callback();
        } catch (error) {
            dispatch({ type: DELETE_BOX_ERROR, payload: error });
        }
    };
};

export const updateReturnsForInBoxEdition = (
    returnedStock,
    stock,
    returns,
    diferential,
    boxID,
    inBoxEdition
) => {
    return async dispatch => {
        const edition = getEditionFromStore(
            inBoxEdition.productID,
            inBoxEdition.editionID
        );
        const product = getProductByIDFromStore(inBoxEdition.productID);

        const productExistences =
            inBoxEdition.returnedStock > returnedStock
                ? product.existences + diferential
                : product.existences - diferential;

        product.existences = productExistences;

        await dispatch(editProductFromStoreAndKiosk(product));
        editInBoxEdition(boxID, { ...inBoxEdition, returnedStock });
        await dispatch(
            editEditionFromKioskAndStore({ ...edition, returns, stock: stock })
        );
    };
};

export const editInBoxEdition = async (boxID, inBoxEdition) => {
    const box = await getBox(boxID);
    const index = box.inBoxEditions.findIndex(
        edition =>
            edition.productID === inBoxEdition.productID &&
            edition.editionID === inBoxEdition.editionID
    );
    box.inBoxEditions[index] = inBoxEdition;
    delete box.id;
    await editBox(boxID, box);
};
