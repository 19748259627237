export const SIGNUP_LOADING = 'SIGNUP_LOADING';
export const SIGNUP_SUCCESSFULL = 'SIGNUP_SUCCESSFULL';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const USER_SETED = 'USER_SETED';
export const USER_MISSING = 'USER_MISSING';
export const SIGNOUT_LOADING = 'SIGNOUT_LOADING';
export const SIGNOUT_SUCCESSFULL = 'SIGNOUT_SUCCSESSFULL';
export const SIGNOUT_FAILED = 'SIGNOUT_FAILED';

