import { wrapKioskQuery } from './kiosk';

const _getDeliveryGroups = async kioskReference => {
    const snapshot = kioskReference.collection('deliveryGroups').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getDeliveryGroup = async (id, kioskReference) => {
    const docRef = kioskReference.collection('deliveryGroups').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
const _editDeliveryGroup = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('deliveryGroups').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

const _addDeliveryGroup = async (data, kioskReference) => {
    const deliveryGroupsRef = kioskReference.collection('deliveryGroups');
    const docRef = await deliveryGroupsRef.add({ ...data });
    return docRef;
};

export const _deleteDeliveryGroup = async (id, kioskReference) => {
    const docRef = kioskReference.collection('deliveryGroups').doc(id);
    await docRef.delete();
};

export const getDeliveryGroups = wrapKioskQuery(_getDeliveryGroups);
export const getDeliveryGroup = wrapKioskQuery(_getDeliveryGroup);
export const editDeliveryGroup = wrapKioskQuery(_editDeliveryGroup);
export const addDeliveryGroup = wrapKioskQuery(_addDeliveryGroup);
export const deleteDeliveryGroup = wrapKioskQuery(_deleteDeliveryGroup);