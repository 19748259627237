import React from 'react';
import { useStyles } from '../styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';

import InputError from '@sharedComponents/InputError';

import { Autocomplete } from '@material-ui/lab';
import { IconButton, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';


const EditDistributionClientSelector = ({ client, setClient, error }) => {
    const classes = useStyles();
    const { clients } = useSelector(state => state.clients);

    return (
        <>
            <Autocomplete
                id='client'
                options={clients}
                getOptionLabel={option => option.name}
                className={classes.flex}
                value={client}
                onChange={(event, newValue) => setClient(newValue)}
                renderInput={params => (
                    <TextField
                        {...params}
                        className={classes.textField}
                        label='Cliente'
                    />
                )}
            />
            <Link to='/clientes/add'>
                <IconButton
                    aria-label='add-client'
                    className={classes.addButton}>
                    <Add />
                </IconButton>
            </Link>
            {error && <InputError message={error} />}
        </>
    );
};

export default EditDistributionClientSelector;

EditDistributionClientSelector.propTypes = {
    client: PropTypes.object,
    setClient: PropTypes.func,
    error: PropTypes.string
}
