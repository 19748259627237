import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button,
    Collapse,
    Divider,
    Grid,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import {
    formatDateTimeToDmy,
    getDateFromDateOrTimestamp,
} from '../../../../../utils/utils';

const ProductEdition = ({ edition, productID }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={`Edicion: ${edition.editionNumber}`} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'
                    className={classes.grid}>
                    <Grid item xs={4}>
                        <Typography variant='caption'>Fecha</Typography>
                        <Typography className={classes.dateText}>
                            {formatDateTimeToDmy(
                                getDateFromDateOrTimestamp(edition.date)
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='caption'>Precio</Typography>
                        <Typography className={classes.priceCant}>
                            ${edition.price}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='caption'>Cantidad</Typography>
                        <Typography className={classes.priceCant}>
                            {edition.stock}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.seeMore}>
                        <Link
                            to={`/product/${productID}/edition/show/${edition.id}`}
                            className={classes.link}>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'>
                                Ver Mas
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Collapse>
            <Divider />;
        </>
    );
};

export default ProductEdition;
