import * as yup from 'yup';

export const schema = yup.object().shape({
    nombre: yup
        .string()
        .required('Ingrese un nombre')
        .matches(/^([^0-9]*)$/, 'El nombre no debe contener numeros')
        .matches(/^[aA-zZ\s]+$/, 'Solo letras estan permitidas'),
    apellido: yup
        .string()
        .required('Ingrese un nombre')
        .matches(/^([^0-9]*)$/, 'El nombre no debe contener numeros')
        .matches(/^[aA-zZ\s]+$/, 'Solo letras estan permitidas'),
    email: yup
        .string()
        .email('Ingrese un email valido')
        .required('Ingrese un email')
        .max(50, 'Máximo 50 caracteres'),
    password: yup.string().required('Ingrese una contraseña'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Las contraseñas deben coincidir'),
});
