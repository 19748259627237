import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import { List, ListSubheader } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import ProductEdition from './ProductEdition';
import { useSelector } from 'react-redux';

const ProductEditionList = () => {
    const { id } = useParams();
    const { products } = useSelector(state => state.products);
    const [product, setProduct] = useState();

    useEffect(() => {
        const prod = products.find(_prod => _prod.id === id);
        setProduct(prod);
    }, []);

    return (
        <div>
            <List
                component='nav'
                aria-labelledby='product-list'
                subheader={
                    <ListSubheader component='div' align='center'>
                        Ediciones del producto
                    </ListSubheader>
                }>
                {product && product.editions &&
                    product.editions.map(edition => (
                        <ProductEdition edition={edition} key={edition.id} productID={id} />
                    ))}
            </List>
            <Link to='/product/edition/detail/form'>
                <FloatingActionButton
                    icon={<Add />}
                    aria='mas'
                    tooltip='añadir una edicion'
                    clickHandler={() => {}}
                />
            </Link>
        </div>
    );
};

export default ProductEditionList;
