import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';

import { Grid, InputLabel, TextField, Typography, Button } from '@material-ui/core';

import { linkKioskToUserFromStore } from '@redux/actions/kiosk';
import Loading from '@sharedComponents/Loading';
import { schema } from './schema';
import useStyles from './styles';

const LinkExistentKiosk = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { linkKioskLoading, linkKioskError } = useSelector(state => state.kiosk);

    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const onSubmit = data => {
        dispatch(linkKioskToUserFromStore(data.referenceCode, () => history.push('/inventario')));
    };

    if (linkKioskLoading) return <Loading />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Vincular con kiosko</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='referenceCode'>Codigo de referencia</InputLabel>
                    <TextField
                        error={!!errors.referenceCode}
                        required
                        id='referenceCode'
                        type='text'
                        name='referenceCode'
                        inputRef={register}
                        helperText={errors.referenceCode && errors.referenceCode.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    {linkKioskError && (
                        <Typography variant='body1' color='error'>
                            {linkKioskError}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={!isValid || !isDirty}
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Vincular
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default LinkExistentKiosk;
