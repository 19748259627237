import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import useStyles from '../styles';

const ClientNote = ({ note }) => {
    const classes = useStyles();
    return (
        <Paper square className={classes.note}>
            <div className={classes.textField}>
                <Typography component='p' variant='body2'>
                    {note}
                </Typography>
            </div>
        </Paper>
    );
};

export default ClientNote;

ClientNote.propTypes = {
    note: PropTypes.string,
}