import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const ClientList = ({ clients }) => {
    return (
        <List>
            {clients.map((item, index) => {
                return (
                    <div key={`user[${item.id}]`}>
                        <ListItem
                            button
                            component={Link}
                            to={{
                                pathname: `/clientes/${item.id}/perfil`,
                            }}>
                            <ListItemText
                                primaryTypographyProps={{
                                    variant: 'h6',
                                }}
                                primary={item.name}
                            />
                        </ListItem>
                        {index !== clients.length - 1 && <Divider />}
                    </div>
                );
            })}
        </List>
    );
};

ClientList.propTypes = {
    clients: PropTypes.array,
};

export default ClientList;
