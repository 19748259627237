import store from '@redux/store';
import { db } from '../firebase';
import { v4 as uuid } from 'uuid';

export function wrapKioskQuery(query) {
    function wrappedFunction() {
        const { kioskReference } = store.getState().kiosk;
        let newArgs = [...arguments];
        newArgs.push(kioskReference);
        return query(...newArgs);
    }
    return wrappedFunction;
}

export const getDocByPath = async path => {
    const docRef = await db.doc(path);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

export const createKiosk = async ({ name, city }) => {
    const referenceCode = uuid();
    const kiosk = await db
        .collection('kiosks')
        .add({ name, city, referenceCode });
    return kiosk;
};

export const linkKiosk = async (referenceCode) => {
    const kiosk = await db
        .collection('kiosks')
        .where('referenceCode', '==', referenceCode)
        .get();

    if (kiosk.empty) {
        return null;
    }

    const kioskId = kiosk.docs[0].id;
    const kioskReference = db.collection('kiosks').doc(kioskId);
    return kioskReference;
};

const _getKiosk = async (kioskReference) => {
    const kiosk = await kioskReference.get();
    if (kiosk.exists) {
        return { ...kiosk.data(), id: kiosk.id };
    }
}

export const getKiosk = wrapKioskQuery(_getKiosk);