import React from 'react';

import useStyles from './styles';

const InputError = ({ message }) => {
    const classes = useStyles();
    return <div className={classes.container}>{message}</div>;
};

export default InputError;
