import {
    SET_PRODUCTS,
    ADD_PRODUCT_LOADING,
    ADD_PRODUCT_SUCCSESS,
    ADD_PRODUCT_ERROR,
} from '../types';

const initialState = {
    products: [],
    addProductsLoading: false,
    addProductsError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PRODUCTS:
            return { ...state, products: payload };
        case ADD_PRODUCT_SUCCSESS:
            return {
                ...state,
                providers: [...payload],
                addProductsLoading: false,
                addProductsError: false,
            };
        case ADD_PRODUCT_LOADING:
            return {
                ...state,
                addProductsLoading: true,
                addProductsError: false,
            };
        case ADD_PRODUCT_ERROR:
            return {
                ...state,
                addProductsLoading: false,
                addProductsError: { ...payload },
            };
        default:
            return state;
    }
};
