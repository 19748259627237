import React from 'react';
import PropTypes from 'prop-types';

import { Card, Grid, IconButton } from '@material-ui/core';
import { Clear, Delete } from '@material-ui/icons';

import { useStyles } from '../styles';
import { useDispatch } from 'react-redux';
import { deleteNotesArray } from '@redux/actions/notes';

const NotesDeleteSelector = ({ selectedItems, setSelectedItems, deleteNotesSucceeded }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClickDelete = () => {
        dispatch(deleteNotesArray(selectedItems, deleteNotesSucceeded));
    };

    const handleClickCancel = () => setSelectedItems([]);

    return (
        <div className={classes.selectorContainer}>
            <Card className={classes.selectedItem}>
                <Grid
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'>
                    <Grid item>
                        <span className={classes.selectedCuantitySpan}>
                            {selectedItems.length} seleccionado
                        </span>
                    </Grid>

                    <Grid item>
                        <IconButton
                            onClick={handleClickDelete}
                            aria-label='eliminar'>
                            <Delete />
                        </IconButton>
                        <IconButton
                            aria-label='cancelar'
                            onClick={handleClickCancel}>
                            <Clear />
                        </IconButton>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default NotesDeleteSelector;

NotesDeleteSelector.propTypes = {
    selectedItems: PropTypes.array,
    setSelectedItems: PropTypes.func,
    deleteNotesSucceeded: PropTypes.func,
}