import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';

const Loading = () => {
    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            style={{ marginTop: '45%' }}>
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    );
};

export default Loading;
