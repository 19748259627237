import React from 'react'
import PropTypes from 'prop-types'

import DirectionsCar from '@material-ui/icons/DirectionsCar'
import DirectionsByke from '@material-ui/icons/DirectionsBike'
import DirectionsWalk from '@material-ui/icons/DirectionsWalk'
import DirectionsBus from '@material-ui/icons/DirectionsBus'
import useStyles from './styles';

const TransitModeIcon = ({ transitMode }) => {
    const classes = useStyles();
    const icons = {
        DRIVING: <DirectionsCar />,
        WALKING: <DirectionsWalk />,
        BICYCLING: <DirectionsByke />,
        TRANSIT: <DirectionsBus />
    }

    return (
        <span className={classes.iconSpan}>
            {icons[transitMode]}
        </span>
    )
}

export default TransitModeIcon

TransitModeIcon.propTypes = {
    transitMode: PropTypes.string
}