import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import VerifyArray from '@sharedComponents/VerifyArray';
import Loading from '@sharedComponents/Loading';

import GridCorrection from './GridCorrection';
import { getNotesSorted } from '@redux/actions/notes';
import NoteComponent from './NoteComponent';
import NotesDeleteSelector from './NotesDeleteSelector';
import { Clear } from '@material-ui/icons';

function NotesComponent() {
    const dispatch = useDispatch();

    const [deleting, setDeleting] = useState(false);
    const [deleteList, setDeleteList] = useState([]);

    const { notes, notesLoading, deleteNotesLoading } = useSelector(
        state => state.notes
    );

    const deleteNotesSucceeded = () => {
        dispatch(getNotesSorted());
        setDeleteList([]);
        setDeleting(false);
    };

    const handleAddOrRemoveFromList = noteID => {
        if (!deleteList.includes(noteID)) {
            setDeleteList(deleteList => [...deleteList, noteID]);
        } else {
            setDeleteList(deleteList =>
                deleteList.filter(note => note !== noteID)
            );
        }
    };

    useEffect(() => {
        dispatch(getNotesSorted());
    }, []);

    if (notesLoading || deleteNotesLoading) return <Loading />;

    return (
        <div>
            <Grid
                container
                direction='row'
                justify='space-evenly'
                alignItems='center'
                spacing={0}>
                <VerifyArray
                    array={notes}
                    defaultItem='nota'
                    femenine
                    redirect='/notas/add'>
                    {notes.map(note => (
                        <NoteComponent
                            note={note}
                            key={note.id}
                            deleting={deleting}
                            deleteList={deleteList}
                            setDeleting={setDeleting}
                            handleAddOrRemoveFromList={
                                handleAddOrRemoveFromList
                            }
                        />
                    ))}
                </VerifyArray>
                <GridCorrection notes={notes} />
            </Grid>
            {deleteList.length > 0 ? (
                <NotesDeleteSelector
                    selectedItems={deleteList}
                    setSelectedItems={setDeleteList}
                    deleteNotesSucceeded={deleteNotesSucceeded}
                />
            ) : deleting ? (
                <FloatingActionButton
                    color='secondary'
                    icon={<Clear />}
                    aria='cancel'
                    tooltip='Cancelar'
                    clickHandler={() => setDeleting(false)}
                />
            ) : (
                <Link to='/notas/add'>
                    <FloatingActionButton
                        color='secondary'
                        icon={<AddIcon />}
                        aria='addNote'
                        tooltip='Añade una nota'
                    />
                </Link>
            )}
        </div>
    );
}

export default NotesComponent;
