import {
    MAIN_LOADER_LOADING,
    MAIN_LOADER_ERROR,
    MAIN_LOADER_COMPLETE,
} from '../types';

const initialState = {
    isComplete: false,
    isLoading: false,
    isError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case MAIN_LOADER_LOADING:
            return {
                ...state,
                isComplete: false,
                isLoading: true,
                isError: false,
            };
        case MAIN_LOADER_ERROR:
            return {
                ...state,
                isComplete: false,
                isLoading: false,
                isError: { ...payload },
            };
        case MAIN_LOADER_COMPLETE:
            return {
                ...state,
                isComplete: true,
                isLoading: false,
                isError: false,
            };
        default:
            return state;
    }
};
