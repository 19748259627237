import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';

import { List } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';

import VerifyArray from '@sharedComponents/VerifyArray';
import DistListItem from '../DistListItem';
import { styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { GET_DELIVERIES_SUCSESS } from '@redux/types';

function DistList({ listData, classes, setSelectedItems, selectedItems }) {
    const dispatch = useDispatch();

    const [list, setList] = useState(listData);

    useEffect(() => {
        setList(listData);
    }, [listData]);

    const { orders, deliveries } = useSelector(state => state.delivery);

    const onSortEnd = (index, oldIndex, newIndex) => {
        let correspondingDeliveryGroup;
        //Cambio de la lista local, me guardo cual cambie asi lo modifico despues
        setList(
            list.map((group, i) => {
                return i === index
                    ? (function() {
                          const newGroup = {
                              ...group,
                              dist: arrayMove(group.dist, oldIndex, newIndex),
                          };
                          correspondingDeliveryGroup = newGroup;
                          return newGroup;
                      })()
                    : group;
            })
        );

        //Aca armo la lista de grupos y los ordenes cambiando solo el correspondiente
        const newDeliveryGroups = deliveries.map(group => {
            return group.id === correspondingDeliveryGroup.id
                ? {
                      ...group,
                      dist: arrayMove(group.dist, oldIndex, newIndex),
                  }
                : group;
        });

        const newOrders = orders.map(order => {
            return order.groupID === correspondingDeliveryGroup.id
                ? {
                      ...order,
                      order: correspondingDeliveryGroup.dist.map(
                          distribution => distribution.id
                      ),
                  }
                : order;
        });

        dispatch({
            type: GET_DELIVERIES_SUCSESS,
            payload: { groups: newDeliveryGroups, orders: newOrders },
        });
    };

    const SortableItem = SortableElement(({ data }) => {
        const checked = selectedItems.some(item => item.id === data.id);
        return (
            <DistListItem
                data={data}
                setSelectedItems={setSelectedItems}
                checked={checked}
            />
        );
    });

    const SortableList = SortableContainer(({ dist }) => {
        return (
            <div>
                {dist &&
                    dist.map((element, index) => {
                        return (
                            <SortableItem
                                key={`item-${element.id}`}
                                index={index}
                                data={element}
                            />
                        );
                    })}
            </div>
        );
    });

    const makeSorteablesLists = listArray => {
        const list = [];
        listArray.forEach((element, index) => {
            if (element.dist.length > 0) {
                list.push(
                    <div key={element.id}>
                        <ListSubheader
                            component='div'
                            align='center'
                            className={classes.hour}>
                            {element.name}
                        </ListSubheader>
                        <SortableList
                            dist={element.dist}
                            onSortEnd={({ oldIndex, newIndex }) => {
                                onSortEnd(index, oldIndex, newIndex);
                            }}
                            pressDelay={200}
                        />
                        {index + 1 !== list.length ? <Divider /> : null}
                    </div>
                );
            }
        });
        return list;
    };

    return (
        <VerifyArray array={list} defaultItem='reparto'>
            <List> {makeSorteablesLists(list)} </List>
        </VerifyArray>
    );
}

DistList.propTypes = {
    listData: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    setSelectedItems: PropTypes.func,
    selectedItems: PropTypes.array,
};

export default withStyles(styles)(DistList);
