import React from 'react';
import { Link } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import BoxesOnInventory from '../../InFolder/BoxesOnInventory';
import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';

const Returns = () => (
    <>
        <BoxesOnInventory section='devoluciones' from='returns' />
        <Link to={{ pathname: '/form/first', state: { from: 'returns' } }}>
            <FloatingActionButton
                aria='add'
                icon={ <AddIcon /> }
                tooltip='Abre una nueva caja para devolucion'
                clickHandler={() => {}}
            />
        </Link>
    </>
)

export default Returns;
