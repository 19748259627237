import { getClients } from '@services/clients';
import { getKiosk } from '@services/kiosk';
import { getProductsAndEditions } from '@services/products';
import { getProviders } from '@services/providers';
import {
    MAIN_LOADER_COMPLETE,
    MAIN_LOADER_ERROR,
    MAIN_LOADER_LOADING,
    SET_CLIENTS,
    SET_KIOSK,
    SET_PRODUCTS,
    SET_PROVIDERS,
} from '../types';

export const getStartData = () => {
    return async dispatch => {
        dispatch({ type: MAIN_LOADER_LOADING });
        try {
            const products = await getProductsAndEditions();
            const clients = await getClients();
            const providers = await getProviders();
            const kiosk = await getKiosk();
            dispatch({ type: SET_PRODUCTS, payload: products });
            dispatch({ type: SET_CLIENTS, payload: clients });
            dispatch({ type: SET_PROVIDERS, payload: providers });
            dispatch({ type: SET_KIOSK, payload: kiosk });
            dispatch({ type: MAIN_LOADER_COMPLETE });
        } catch (error) {
            console.error(error)
            dispatch({ type: MAIN_LOADER_ERROR, payload: error });
        }
    };
};
