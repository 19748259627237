const dateExistsAlredy = (date, formatedBoxes) => {
    return formatedBoxes.findIndex(
        dayBox => dayBox.date.seconds === date.seconds
    );
};

export const formatBoxes = (data, from) => {
    const formatedBoxes = [];
    data.forEach(box => {
        if (
            box.inbox === (from === 'ingreso') ||
            !box.inbox === (from === 'returns')
        ) {
            const dayBoxIndex = dateExistsAlredy(box.date, formatedBoxes);
            if (dayBoxIndex !== -1) {
                formatedBoxes[dayBoxIndex].boxes.push(box);
            } else {
                formatedBoxes.push({ date: box.date, boxes: [box] });
            }
        }
    });

    return formatedBoxes;
};