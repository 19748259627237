import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    nameInput: {
        marginTop: '10px', marginBottom: '20px'
    },
    addressInput: {
        marginTop: '20px', marginBottom: '20px'
    },
    deliveryManInput: {
        marginTop: '10px', marginBottom: '20px'
    }
}));
