import { wrapKioskQuery } from './kiosk';

const _getNotes = async kioskReference => {
    const snapshot = kioskReference.collection('notes').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getNote = async (id, kioskReference) => {
    const docRef = kioskReference.collection('notes').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editNote = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('notes').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

export const _addNote = async (data, kioskReference) => {
    const notesRef = kioskReference.collection('notes');
    const docRef = await notesRef.add({ ...data });
    return docRef;
};

//Delete es diferente ya que set no devuelve nada cuando se completa
export const _deleteNote = async (id, kioskReference) => {
    const docRef = kioskReference.collection('notes').doc(id);
    const updDocRef = await docRef.delete().then(() => true);
    return updDocRef; 
};

export const getNotes = wrapKioskQuery(_getNotes);
export const getNote = wrapKioskQuery(_getNote);
export const editNote = wrapKioskQuery(_editNote);
export const addNote = wrapKioskQuery(_addNote);
export const deleteNote = wrapKioskQuery(_deleteNote);