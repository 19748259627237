import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Delete } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import AdressPlaceSelector from '@sharedComponents/AdressPlaceSelector';
import { editClientFromStore } from '@redux/actions/clients';
import Loading from '@sharedComponents/Loading';
import DeleteClientModal from '../DeleteClientModal';
import { schema } from './schema';
import useStyles from './styles';

const EditClient = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { client } = useLocation();
    const { kiosk: { city } } = useSelector(state => state.kiosk);

    const [place, setPlace] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { editClientLoading } = useSelector(state => state.clients);

    const { register, handleSubmit, errors, formState, reset } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    useEffect(() => {
        if (client) {
            reset(client);
        }
    }, [client]);

    const onSubmit = data => {
        dispatch(
            editClientFromStore(client.id, data, () =>
                history.push('/clientes')
            )
        );
    };

    if (editClientLoading) return <Loading />;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    direction='column'
                    justify='center'
                    alignItems='center'
                    spacing={3}>
                    <Grid item xs className={classes.margin}>
                        <TextField
                            name='name'
                            label='Nombre'
                            required
                            error={!!errors.name}
                            inputRef={register}
                            helperText={errors.name && errors.name.message}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            name='telephone'
                            label='Telefono'
                            error={!!errors.telephone}
                            inputRef={register}
                            helperText={
                                errors.telephone && errors.telephone.message
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <AdressPlaceSelector
                            city={city}
                            value={place}
                            setValue={setPlace}
                            initialInputValue={client.address}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            className={classes.note}
                            name='note'
                            label='Nota'
                            multiline
                            error={!!errors.note}
                            inputRef={register}
                            helperText={errors.note && errors.note.message}
                        />
                    </Grid>
                    <Grid item xs>
                        <Button
                            disabled={!isValid || !isDirty || editClientLoading}
                            type='submit'
                            variant='contained'
                            color='secondary'
                            size='large'
                            className={classes.sendButton}>
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <DeleteClientModal open={openDeleteModal} setOpen={setOpenDeleteModal} deleteID={client.id} />
            <FloatingActionButton
                color='secondary'
                aria='delete'
                icon={<Delete />}
                tooltip='Inactivar el cliente'
                clickHandler={() => setOpenDeleteModal(true)}
            />
        </>
    );
};

EditClient.propTypes = {};

export default EditClient;
