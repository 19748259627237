import * as yup from 'yup';

export const schema = yup.object().shape({
    company: yup.string().max(25, 'Máximo 25 caracteres'),
    representant: yup
        .string()
        .required('Ingrese un nombre de proveedor')
        .max(25, 'Máximo 25 caracteres'),
    email: yup
        .string()
        .email('Ingrese un email valido')
        .max(100, 'Máximo 100 caracteres')
        .required('Ingrese un email'),
    telephone1: yup
        .string()
        .required('ingrese un telefono')
        .max(15, 'Máximo 15 caracteres'),
    telephone2: yup
        .string()
        .max(15, 'Máximo 15 caracteres'),
    returnDir: yup.string().max(50, 'Máximo 50 caracteres'),
    note: yup.string().max(255, 'Máximo 255 caracteres'),
});
