const routes = {
    ingreso: '/ingreso',

    ventas: '/ventas',

    devoluciones: '/devoluciones',

    reparto: '/reparto',
    editDist: '/editDist/:id',
    addDist: '/addDist',

    clientes: '/clientes',
    newClient: '/clientes/add',
    clientProfile: '/clientes/:id/perfil',
    editClient: '/clientes/edit',

    proovedores: '/proveedores',
    crearProveedor: '/proveedores/crearproveedor',
    detalleProveedor: '/proveedores/:id',
    editarProveedor: '/proveedores/editar/:id',

    newKiosk: '/kiosk/add',
    chooseKiosk: '/kiosk/choose',
    linkKiosk: '/kiosk/link',

    reportes: '/reportes',

    nota: '/notas/add',
    notas: '/notas',
    notaEdit: '/notas/edit/:id',

    config: '/config',

    inventario: '/inventario',
    newProduct: '/product/:name/edit',
    showProduct: '/product/show/:id',
    formEditionDetail: '/product/edition/detail/form',
    showEditionDetail: '/product/:productID/edition/show/:editionID',

    firstStage: '/form/first',
    boxOpened: '/caja',
    search: '/search',
    register: '/register',
    login: '/login',
    develop: '/develop',

    index: '/',
};

const routeSection = [
    {
        name: 'Inventario',
        routes: [
            routes.inventario,
            routes.newProduct,
            routes.showProduct,
            routes.formEditionDetail,
            routes.showEditionDetail,
            routes.productDetailForm,
        ],
    },
    {
        name: 'Ingreso',
        routes: [routes.ingreso],
    },
    {
        name: 'Ventas',
        routes: [routes.ventas],
    },
    {
        name: 'Devoluciones',
        routes: [routes.devoluciones],
    },
    {
        name: 'Reparto',
        routes: [routes.reparto, routes.editDist],
    },
    {
        name: 'Clientes',
        routes: [
            routes.clientes,
            routes.newClient,
            routes.clientProfile,
            routes.editClient,
        ],
    },
    {
        name: 'Proovedores',
        routes: [routes.proovedores, routes.crearProveedor],
    },
    {
        name: 'Reportes',
        routes: [routes.reportes],
    },
    {
        name: 'Notas',
        routes: [routes.nota, routes.notas],
    },
    {
        name: 'Kiosko',
        routes: [routes.newKiosk, routes.chooseKiosk, routes.linkKiosk],
    },
    {
        name: '',
        routes: [
            routes.firstStage,
            routes.boxOpened,
            routes.search,
            routes.register,
            routes.login,
            routes.develop,
            routes.index,
        ],
    },
];

export const getRouteSectionName = actualRoute => {
    let section = null;
    let index = 0;
    while (!section && index < routeSection.length) {
        let indexSection = routeSection[index].routes.find(
            element => element === actualRoute
        );
        if (indexSection) {
            section = routeSection[index];
        }
        index++;
    }
    if (section) {
        return section.name;
    }
    return 'Menu';
};

export default routes;
