/* global google */

import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';

import { Marker } from 'react-google-maps';

import { DELIVERY_STATES } from '@constants/deliveryStates';
import DirectionsRendererWrapper from './DirectionsRendererWrapper';

const DistributionDirection = ({ direction, setSelected, transitMode }) => {
    const [directions, setDirections] = useState({ routes: [] });

    useEffect(() => {
        const directionsService = new google.maps.DirectionsService();

        directionsService.route(
            {
                origin: direction.origin,
                destination: direction.destination,
                travelMode: google.maps.TravelMode[transitMode],
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }, [transitMode, direction]);

    return (
        <>
            <DirectionsRendererWrapper
                direction={direction}
                directions={directions}
            />
            <Marker
                position={direction.destination}
                icon={`/location-marker-${
                    DELIVERY_STATES[direction.state]
                }.svg`}
                clickable={true}
                onClick={() => setSelected(direction)}
            />
        </>
    );
};

export default memo(DistributionDirection);

DistributionDirection.propTypes = {
    direction: PropTypes.object,
    setSelected: PropTypes.func,
    transitMode: PropTypes.string,
};
