import {
    NEW_BOX_LOADING,
    NEW_BOX_ERROR,
    DELETE_BOX_LOADING,
    DELETE_BOX_ERROR,
    DELETE_BOX_SUCCESS,
} from '../types';

const initialState = {
    newBoxLoading: false,
    newBoxError: false,
    deleteBoxLoading: false,
    deleteBoxError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case NEW_BOX_LOADING:
            return {
                ...state,
                newBoxLoading: true,
                newBoxError: false,
            };
        case NEW_BOX_ERROR:
            return {
                ...state,
                newBoxLoading: false,
                newBoxError: payload,
            };
        case DELETE_BOX_LOADING:
            return {
                ...state,
                deleteBoxLoading: true,
                deleteBoxError: false,
            };
        case DELETE_BOX_ERROR:
            return {
                ...state,
                deleteBoxLoading: false,
                deleteBoxError: payload,
            };
        case DELETE_BOX_SUCCESS:
            return {
                ...state,
                deleteBoxLoading: false,
                deleteBoxError: false,
            };
        default:
            return state;
    }
};
