import React from 'react';
import format from 'date-fns/format';
import PropTypes  from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import arLocale from 'date-fns/locale/es';

const DatePickerProvider = ({ children }) => {
    class ArLocalizedUtils extends DateFnsUtils {
        getDatePickerHeaderText(date) {
            return format(date, 'dd MM yyyy', { locale: arLocale });
        }
    }

    return (
        <MuiPickersUtilsProvider locale={arLocale} utils={ArLocalizedUtils}>
            {children}
        </MuiPickersUtilsProvider>
    );
};

export default DatePickerProvider;

DatePickerProvider.propTypes = {
    children: PropTypes.node,
}
