import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import { Typography, Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from '@material-ui/pickers';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';

import { addEditionToKiosk } from '@redux/actions/editions';
import ErrorMessage from '@sharedComponents/ErrorMessage';
import InputError from '@sharedComponents/InputError';
import { DatePickerSetup } from '../../../../utils/ConfigSetup';
import Loading from '@sharedComponents/Loading';
import { schema } from './schema';
import useStyles from './styles';

const FormEditionDetail = ({ location, history }) => {
    //VARIABLES
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        state: { product, typeDispatcher },
    } = location;

    const { newEditionLoading, newEditionError } = useSelector(
        state => state.editions
    );
    const [error, setError] = useState(newEditionError);
    useEffect(() => setError(newEditionError), [newEditionError]);
    const closeError = () => setError(false);

    //FORM
    const [date, setDate] = useState(new Date());

    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const onSubmit = data => {
        dispatch(
            addEditionToKiosk(
                product.id,
                { ...data, date },
                typeDispatcher,
                () => history.goBack(),
                product.name,
            )
        );
    };

    if (newEditionLoading) return <Loading />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.container}>
                <Typography variant='h6' component='div'>
                    Nueva edicion de {product.name}
                </Typography>
            </div>
            <Grid
                className={classes.grid}
                container
                direction='column'
                justify='center'
                alignItems='center'
                spacing={3}>
                <Grid item xs={12}>
                    <InputLabel htmlFor='price'>Precio</InputLabel>
                    <Input
                        id='price'
                        type='number'
                        name='price'
                        required
                        inputRef={register}
                        error={!!errors.price}
                        className={classes.priceInput}
                        startAdornment={
                            <InputAdornment position='start'>$</InputAdornment>
                        }
                    />
                    {errors.price && (
                        <InputError message={errors.price.message} />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor='stock'>Cantidad</InputLabel>
                    <Input
                        id='stock'
                        type='number'
                        name='stock'
                        required
                        inputRef={register}
                        error={!!errors.stock}
                    />
                    {errors.stock && (
                        <InputError message={errors.stock.message} />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <InputLabel htmlFor='editionNumber'>Nro Edicion</InputLabel>
                    <Input
                        id='editionNumber'
                        name='editionNumber'
                        required
                        inputRef={register}
                        error={!!errors.editionNumber}
                    />
                    {errors.editionNumber && (
                        <InputError message={errors.editionNumber.message} />
                    )}
                </Grid>

                <Grid item xs={12}>
                    <DatePicker
                        label='Fecha de edicion: '
                        value={date}
                        onChange={newDate => setDate(newDate)}
                        views={DatePickerSetup.views}
                        variant='dialog'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        disabled={!isValid || !isDirty}
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Crear
                    </Button>
                </Grid>
            </Grid>
            <ErrorMessage
                errorMessage={error.message}
                open={!!error}
                handleClose={closeError}
            />
        </form>
    );
};

export default FormEditionDetail;

FormEditionDetail.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
}