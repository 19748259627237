import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: theme.palette.secondary.main,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '25px',
        textAlign: 'center',
    },
    subtitle: {
        marginTop: 28,
        color: 'black',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        textAlign: 'center',
    },
    gainingsRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    ganings: {
        color: theme.palette.primary.main,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '40px',
    },
    up: {
        color: '#00B929',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '20px',
    }
}));

export default useStyles;
