import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Grid, Paper, Typography } from '@material-ui/core';

import ShoppingCart from '@material-ui/icons/ShoppingCart';
import AttachMoney from '@material-ui/icons/AttachMoney';
import Store from '@material-ui/icons/Store';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import Create from '@material-ui/icons/Create';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import { getEditionFromStore } from '@redux/actions/editions';
import {
    formatDateTimeToDmy,
    getDateFromDateOrTimestamp,
} from '../../../../utils/utils';

const ShowEditionDetail = () => {
    const { productID, editionID } = useParams();
    const classes = useStyles();
    const [edition, setEdition] = useState(null);

    useEffect(() => {
        const edition = getEditionFromStore(productID, editionID, true);
        setEdition(edition);
    }, [editionID]);

    return (
        <Container fluid className={classes.container}>
            <Paper square className={classes.paper}>
                <Typography variant='h5'>
                    {edition && edition.productName}
                </Typography>
                <Typography variant='caption'>
                    Edición {edition && edition.editionNumber}
                </Typography>
                <br />
                <Typography variant='caption'>
                    Fecha{' '}
                    {edition &&
                        formatDateTimeToDmy(
                            getDateFromDateOrTimestamp(edition.date)
                        )}
                </Typography>
            </Paper>
            <div className={classes.body}>
                <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'>
                    <Grid item xs={6} className={classes.gridItem}>
                        <Paper square className={classes.paper}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <ShoppingCart
                                        className={classes.shoppingCart}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <div className={classes.number}>
                                        {edition && edition.sells}
                                    </div>

                                    <Typography
                                        variant='caption'
                                        component='span'>
                                        vendidos
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemR}>
                        <Paper square className={classes.paper}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <AttachMoney
                                        className={classes.attachMoney}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <div className={classes.number}>
                                        {edition && edition.price}
                                    </div>

                                    <Typography
                                        variant='caption'
                                        component='span'>
                                        precio
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <Paper square className={classes.paper}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Store className={classes.store} />
                                </Grid>
                                <Grid item xs={7}>
                                    <div className={classes.number}>
                                        {edition && edition.stock}
                                    </div>

                                    <Typography
                                        variant='caption'
                                        component='span'>
                                        disponibles
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemR}>
                        <Paper square className={classes.paper}>
                            <Grid container>
                                <Grid item xs={5}>
                                    <MoveToInbox
                                        className={classes.moveToInbox}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <div className={classes.number}>
                                        {edition &&
                                            edition.sells +
                                                edition.returns +
                                                edition.stock}
                                    </div>

                                    <Typography
                                        variant='caption'
                                        component='span'>
                                        ingresados
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>

            <Link to='/product/edition/detail/form'>
                <FloatingActionButton
                    icon={<Create />}
                    aria='Editar'
                    tooltip='Editar'
                    clickHandler={() => {}}
                />
            </Link>
        </Container>
    );
};

export default ShowEditionDetail;
