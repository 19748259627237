import React, { useEffect, useRef, useState } from 'react';

import { Paper, Typography, Chip } from '@material-ui/core';
import Create from '@material-ui/icons/Create';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';

import { useStyles } from './styles';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { categoryValues } from '@constants/categories';
import { getDateFromDateOrTimestamp } from '../../../../utils/utils';
import { addDays, differenceInCalendarDays } from 'date-fns';
import WorkingOnImages from '../../../../assets/images/working-on-images.png';

const ProductDetail = () => {
    const classes = useStyles();
    const { id } = useParams();
    const { products } = useSelector(state => state.products);
    const { providers } = useSelector(state => state.providers);
    const [product, setProduct] = useState();
    const [provider, setProvider] = useState();
    const [lastEdition, setLastEdition] = useState();

    useEffect(() => {
        const prod = products.find(_prod => _prod.id === id);
        setProduct(prod);
        setProvider(
            providers.find(_provider => _provider.id === prod.provider)
        );
        const lastEditionByDate =
            prod.editions.length > 0
                ? prod.editions.sort(
                      (a, b) =>
                          getDateFromDateOrTimestamp(b.date) -
                          getDateFromDateOrTimestamp(a.date)
                  )[0]
                : null;
        setLastEdition(lastEditionByDate);
    }, []);

    //Primer Paper
    const [firstHeight, setFirstHeight] = useState(0);
    const firstPaper = useRef(null);
    useEffect(() => {
        if (firstPaper.current) {
            setFirstHeight(firstPaper.current.offsetHeight);
        }
    }, [firstPaper, product]);
    //Second Paper
    const [secondHeight, setSecondHeigth] = useState(0);
    const secondPaper = useRef(null);
    useEffect(() => {
        if (secondPaper.current && firstPaper.current) {
            setSecondHeigth(
                firstPaper.current.offsetHeight +
                    secondPaper.current.offsetHeight
            );
        }
    }, [secondPaper, firstPaper, product]);
    // Body Height
    const thirdPaper = useRef(null);
    useEffect(() => {
        if (secondPaper.current && firstPaper.current && thirdPaper.current) {
            document.body.style.height =
                firstPaper.current.offsetHeight +
                secondPaper.current.offsetHeight +
                thirdPaper.current.offsetHeight +
                100;
        }
    }, [secondPaper, firstPaper, thirdPaper, product]);

    return (
        <>
            <div className={classes.background}>
                <img
                    src={WorkingOnImages}
                    className={classes.headerImage}
                    alt='imagen header'
                />
                <Paper square className={classes.firstPaper} ref={firstPaper}>
                    <div className={classes.paperPadding}>
                        <Typography variant='h5'>
                            {product && product.name}
                        </Typography>
                        <Typography variant='caption'>
                            {product && categoryValues[product.category]}
                        </Typography>
                        <div>
                            <Typography
                                variant='caption'
                                component='div'
                                style={{ float: 'left' }}>
                                <span
                                    className={classes.bigNumber}
                                    style={{ color: 'orange' }}>
                                    {product && product.selled}
                                </span>
                                vendidos
                            </Typography>
                            <Typography
                                variant='caption'
                                component='div'
                                style={{ float: 'right' }}>
                                ultimo precio
                                <span
                                    className={classes.bigNumber}
                                    style={{ color: 'green', marginLeft: 5 }}>
                                    ${product && product.lastPrice}
                                </span>
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <Paper
                    square
                    className={classes.paper}
                    style={{ top: 300 + firstHeight + 20 }}
                    ref={secondPaper}>
                    <div className={classes.paperPadding}>
                        <Typography variant='h6'>
                            {provider && provider.company}
                        </Typography>
                        <Typography variant='caption'>Proovedor</Typography>
                        <Typography variant='caption' component='div'>
                            <span
                                className={classes.bigNumber}
                                style={{ color: 'orange' }}>
                                {lastEdition &&
                                    differenceInCalendarDays(
                                        addDays(
                                            getDateFromDateOrTimestamp(
                                                lastEdition.date
                                            ),
                                            product.returns
                                        ),
                                        new Date()
                                    )}
                            </span>
                            dias para devolucion
                        </Typography>
                        <Typography variant='caption' component='div'>
                            <span
                                className={classes.bigNumber}
                                style={{ color: 'green' }}>
                                {product && product.returns}
                            </span>
                            dias por cada periodo de devolucion
                        </Typography>
                    </div>
                </Paper>
                <Paper
                    square
                    ref={thirdPaper}
                    className={classes.paper}
                    style={{ top: 300 + secondHeight + 40 }}>
                    <div className={classes.paperPadding}>
                        <Typography variant='h6'>Detalle</Typography>
                        <Typography variant='caption' component='div'>
                            <span
                                className={classes.bigNumber}
                                style={{ color: 'green' }}>
                                {product && product.gainings}%
                            </span>
                            ganancia
                        </Typography>
                        <div className={classes.chips}>
                            <Chip
                                label='Farandula'
                                className={classes.chip}
                                style={{
                                    backgroundColor: '#b049b6',
                                    color: 'white',
                                }}
                            />
                            {product &&
                                product.tag.map(tag => (
                                    <Chip
                                        key={tag}
                                        label={tag}
                                        className={classes.chip}
                                    />
                                ))}
                        </div>
                        <div className={classes.notes}>
                            <Typography variant='caption' component='div'>
                                Notas
                            </Typography>
                            <Typography variant='body1'>
                                {product && product.note}
                            </Typography>
                        </div>
                    </div>
                </Paper>
            </div>
            <Link to='/product/detail/form'>
                <FloatingActionButton
                    icon={<Create />}
                    aria='Editar'
                    tooltip='Editar'
                    clickHandler={() => {}}
                />
            </Link>
        </>
    );
};

export default ProductDetail;
