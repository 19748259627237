import React from 'react';
import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import Box from '../Box';
import VerifyArray from '@sharedComponents/VerifyArray';
import { styles } from './styles';
import { formatDateTimeToDmy } from '../../../utils/utils';

const Boxes = ({ classes, boxes, section, from }) => {
    return (
        <List
            component='nav'
            subheader={
                <ListSubheader component='div' align='center'>
                    Historial de {section}
                </ListSubheader>
            }
            className={classes.root}>
            <VerifyArray
                array={boxes}
                defaultItem='caja'
                femenine
                redirect={{ pathname: '/form/first', state: { from } }}>
                <>
                    {boxes.map((element, index) => {
                        return (
                            <div key={index}>
                                <Box
                                    from={from}
                                    title={formatDateTimeToDmy(
                                        element.date.toDate()
                                    )}
                                    childrens={element.boxes}
                                />
                                {index + 1 !== boxes.length && <Divider />}
                            </div>
                        );
                    })}
                </>
            </VerifyArray>
        </List>
    );
};

Boxes.propTypes = {
    classes: PropTypes.object.isRequired,
    boxes: PropTypes.array.isRequired,
    section: PropTypes.string,
    from: PropTypes.string,
};

export default withStyles(styles)(Boxes);
