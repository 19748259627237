import {
    SET_CLIENTS,
    EDIT_CLIENT_SUCCSESS,
    EDIT_CLIENT_LOADING,
    EDIT_CLIENT_ERROR,
    ADD_CLIENT_SUCCSESS,
    ADD_CLIENT_LOADING,
    ADD_CLIENT_ERROR,
} from '../types';

const initialState = {
    clients: [],
    editClientLoading: false,
    editClientError: false,
    addClientLoading: false,
    addClientError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CLIENTS:
            return { ...state, clients: payload };
        case EDIT_CLIENT_SUCCSESS:
            return {
                ...state,
                providers: [...payload],
                editClientLoading: false,
                editClientError: false,
            };
        case EDIT_CLIENT_LOADING:
            return {
                ...state,
                editClientLoading: true,
                editClientError: false,
            };
        case EDIT_CLIENT_ERROR:
            return {
                ...state,
                editClientLoading: false,
                editClientError: { ...payload },
            };
        case ADD_CLIENT_SUCCSESS:
            return {
                ...state,
                providers: [...payload],
                addClientLoading: false,
                addClientError: false,
            };
        case ADD_CLIENT_LOADING:
            return { ...state, addClientLoading: true, addClientError: false };
        case ADD_CLIENT_ERROR:
            return {
                ...state,
                addClientLoading: false,
                addClientError: { ...payload },
            };
        default:
            return state;
    }
};
