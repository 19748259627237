import {
    GET_DELIVERIES_LOADING,
    GET_DELIVERIES_SUCSESS,
    GET_DELIVERIES_ERROR,
    SET_DELIVERIES,
} from '../types';

const initialState = {
    deliveries: [],
    orders: [],
    deliveriesLoading: false,
    deliveriesError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DELIVERIES:
            return {
                ...state,
                deliveries: [...payload],
            };
        case GET_DELIVERIES_SUCSESS:
            return {
                ...state,
                deliveries: [...payload.groups],
                orders: [...payload.orders],
                deliveriesLoading: false,
                deliveriesError: false,
            };
        case GET_DELIVERIES_LOADING:
            return {
                ...state,
                deliveriesLoading: true,
                deliveriesError: false,
            };
        case GET_DELIVERIES_ERROR:
            return {
                ...state,
                deliveriesLoading: false,
                deliveriesError: { ...payload },
            };
        default:
            return state;
    }
};
