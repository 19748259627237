import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllDistributionData } from '@redux/actions/distributions';
import FastNavTab from '@sharedComponents/FastNavTab';
import Loading from '@sharedComponents/Loading';
import DistributionMap from '../DistributionMap';
import Groups from '../../GroupsFolder/Groups';
import ListPart from '../ListPart';

const tabItems = [
    {
        label: 'Lista',
        component: ListPart,
    },
    {
        label: 'Mapa',
        component: DistributionMap,
    },
    {
        label: 'Grupos',
        component: Groups,
    },
];

function Distribution() {
    const dispatch = useDispatch();
    const { allDistributionDataLoading } = useSelector(
        state => state.distributions
    );

    useEffect(() => {
        dispatch(getAllDistributionData());
    }, []);

    if (allDistributionDataLoading) return <Loading />;
    return <FastNavTab tabsData={tabItems} />;
}

export default Distribution;
