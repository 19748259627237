import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { logout } from '@redux/actions';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';

const BottomAppBar = ({ classes }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleLogout = () => {
        dispatch(logout());
    };

    const handleHome = () => history.push('/inventario');
    const handleBack = () => history.goBack();

    return (
        <AppBar
            position='fixed'
            color='primary'
            style={{
                top: 'auto',
                bottom: 0,
            }}>
            <Toolbar>
                <IconButton
                    className={classes.menuButton}
                    color='inherit'
                    aria-label='Ir Atras'
                    onClick={handleBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <IconButton
                    className={classes.grow}
                    color='inherit'
                    aria-label='Home'
                    onClick={handleHome}>
                    <HomeIcon />
                </IconButton>
                <Button color='inherit'>
                    <div onClick={handleLogout}>Logout</div>
                </Button>
            </Toolbar>
        </AppBar>
    );
};

BottomAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    callBack: PropTypes.func,
    title: PropTypes.string,
};

export default withStyles(styles)(BottomAppBar);
