import { wrapKioskQuery } from './kiosk';

const _getClients = async kioskReference => {
    const snapshot = kioskReference.collection('clients').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getClientById = async (id, kioskReference) => {
    const docRef = kioskReference.collection('clients').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editClient = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('clients').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef; 
};

const _addClient = async (data, kioskReference) => {
    const clientsRef = kioskReference.collection('clients');
    const docRef = await clientsRef.add({ ...data });
    return docRef;
};

export const getClients = wrapKioskQuery(_getClients);
export const getClientById = wrapKioskQuery(_getClientById);
export const editClient = wrapKioskQuery(_editClient);
export const addClient = wrapKioskQuery(_addClient);
