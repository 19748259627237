import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { styles } from './styles';

function ItemOnInventory({ classes, name, existencies, price, image, id }) {
    return (
        <>
            <Link className={classes.container} to={`/product/show/${id}`}>
                <Grid container>
                    <Grid item xs={3}>
                        {/*
                            TODO: imagenes
                            <ButtonBase className={classes.image}>
                                <img
                                    alt='Remy Sharp'
                                    src={image ? image : NewsPNG}
                                    className={classes.img}
                                    />
                            </ButtonBase>
                        */}
                    </Grid>
                    <Grid item xs={8} container>
                        <Grid item xs container direction='column' spacing={0}>
                            <Grid item xs>
                                <Typography variant='subtitle1'>
                                    {name}
                                </Typography>
                                <Typography color='textSecondary'>
                                    Existencias: {existencies}
                                </Typography>
                                <Typography color='textSecondary'>
                                    Precio: ${price}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            className={classes.moreIcon}
                            aria-label='edit'>
                            <MoreVert />
                        </IconButton>
                    </Grid>
                </Grid>
            </Link>
            <Divider />
        </>
    );
}

ItemOnInventory.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.string,
    existencies: PropTypes.number,
};

export default withStyles(styles)(ItemOnInventory);
