import React from 'react';
import { render } from 'react-dom';
import App from '@components/App';
import 'sanitize.css/sanitize.css';

const target = document.querySelector('#root');

render(
    <App />,
    target
);
