import { combineReducers } from 'redux';

import auth from './auth';
import kiosk from './kiosk';
import notes from './notes';
import providers from './providers';
import search from './search';
import products from './products';
import editions from './editions';
import boxes from './boxes';
import inventory from './inventory';
import deliveryGroups from './deliveryGroups'
import clients from './clients'
import deliveryMan from './deliveryMan'
import distributions from './distributions'
import mainLoader from './mainLoader'
import delivery from './delivery'
import sells from './sells'

export default combineReducers({
    auth,
    kiosk,
    notes,
    providers,
    search,
    products,
    editions,
    boxes,
    inventory,
    deliveryGroups,
    clients,
    deliveryMan,
    distributions,
    mainLoader,
    delivery,
    sells
});
