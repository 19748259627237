import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { auth, db } from '../firebase';
import { getStartData } from '@redux/actions/mainLoader';
import { USER_SETED, USER_MISSING, SET_KIOSK_REFERENCE } from '@redux/types';

const useAuth = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                db.collection('users')
                    .doc(user.uid)
                    .get()
                    .then(userExtraInfo => {
                        if (userExtraInfo.exists) {
                            const completeUser = {
                                userExtraInfo: userExtraInfo.data(),
                                user,
                            };
                            dispatch({
                                type: USER_SETED,
                                payload: completeUser,
                            });

                            if(userExtraInfo.data().kiosk){
                                dispatch({
                                    type: SET_KIOSK_REFERENCE,
                                    payload: userExtraInfo.data().kiosk,
                                });
                                dispatch(getStartData());
                            }

                            history.push('/inventario');
                        } else {
                            dispatch({ type: USER_MISSING });
                        }
                    });
            } else {
                dispatch({ type: USER_MISSING });
            }
        });

        return unsubscribe;
    }, []);
};

export default useAuth;
