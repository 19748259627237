import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

import VerifyArray from '@sharedComponents/VerifyArray';
import { DatePickerSetup } from '../../../utils/ConfigSetup';
import ListOfNewsPaper from '../ListOfNewsPaper';
import { styles } from './styles';
import { getDateFromDateOrTimestamp } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { addDays } from 'date-fns';

function Periodic({ classes }) {
    const [date, setDate] = useState(new Date());
    const [sellInventory, setSellInventory] = useState([]);
    const { products } = useSelector(state => state.products);

    const updateEdition = edition => {
        const newSellInventory = sellInventory.map(element => element.id === edition.id ? edition : element);
        setSellInventory(newSellInventory);
    };

    useEffect(() => {
        if (products) {
            const filteredProducts = products.filter(
                product => product.category === 2
            );
            const editions = [];
            filteredProducts.forEach(product => {
                if (product.editions) {
                    product.editions.forEach(edition => {
                        const editionDate = getDateFromDateOrTimestamp(
                            edition.date
                        );

                        if (date >= editionDate && date <= addDays(editionDate, product.returns)) {
                            editions.push({
                                ...edition,
                                productName: product.name,
                            });
                        }
                    });
                }
            });
            setSellInventory(editions);
        }
    }, [date, products]);

    return (
        <>
            <div className={classes.datePicker}>
                <DatePicker
                    label='Fecha: '
                    value={date}
                    onChange={newDate => setDate(newDate)}
                    views={DatePickerSetup}
                    variant='dialog'
                />
            </div>
            <VerifyArray array={sellInventory} defaultItem='periodico'>
                <ListOfNewsPaper items={sellInventory} updateEdition={updateEdition} />
            </VerifyArray>
        </>
    );
}

Periodic.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Periodic);
