import { getDeliveryGroups } from '@services/deliveryGroups';
import { getDeliveryMans } from '@services/deliveryMan';
import {
    addDistribution,
    editDistribution,
    getDistributions,
} from '@services/distributions';
import store from '../store';
import {
    ALL_DISTRIBUTION_DATA_ERROR,
    ALL_DISTRIBUTION_DATA_LOADING,
    ALL_DISTRIBUTION_DATA_SUCCSESS,
    ADD_DISTRIBUTION_SUCCSESS,
    ADD_DISTRIBUTION_LOADING,
    ADD_DISTRIBUTION_ERROR,
    SET_DELIVERY_MANS,
    SET_DISTRIBUTIONS,
    SET_GROUPS,
    DELETE_DISTRIBUTION_LOADING,
    DELETE_DISTRIBUTION_SUCCSESS,
    DELETE_DISTRIBUTION_ERROR,
    EDIT_DISTRIBUTION_LOADING,
    EDIT_DISTRIBUTION_SUCCSESS,
    EDIT_DISTRIBUTION_ERROR,
} from '../types';

export const getAllDistributionData = () => {
    return async dispatch => {
        dispatch({ type: ALL_DISTRIBUTION_DATA_LOADING });
        try {
            const distributions = await getDistributions();
            const deliveryMans = await getDeliveryMans();
            const deliveryGroups = await getDeliveryGroups();

            dispatch({ type: SET_DISTRIBUTIONS, payload: [...distributions] });
            dispatch({
                type: SET_DELIVERY_MANS,
                payload: deliveryMans,
            });
            dispatch({ type: SET_GROUPS, payload: [...deliveryGroups] });

            dispatch({ type: ALL_DISTRIBUTION_DATA_SUCCSESS });
        } catch (error) {
            dispatch({ type: ALL_DISTRIBUTION_DATA_ERROR, payload: error });
        }
    };
};

export const addDistributionFromStore = (distribution, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_DISTRIBUTION_LOADING });
        try {
            await addDistribution(distribution);
            dispatch({ type: ADD_DISTRIBUTION_SUCCSESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_DISTRIBUTION_ERROR, payload: error });
        }
    };
};

export const editProductsForDistributionFromStore = (
    inDistProductRows,
    distributionID,
    callback
) => {
    return async dispatch => {
        dispatch({ type: EDIT_DISTRIBUTION_LOADING });
        try {
            const distribution = getDistributionById(distributionID);
            distribution.inDistProductRows = inDistProductRows;
            await editDistribution(distributionID, distribution);
            dispatch(editDistributionFromStore(distributionID, distribution));
            dispatch({ type: EDIT_DISTRIBUTION_SUCCSESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: EDIT_DISTRIBUTION_ERROR, payload: error });
        }
    };
};

export const deleteDistributionFromKiosk = (distributionID, callback) => {
    return async dispatch => {
        dispatch({ type: DELETE_DISTRIBUTION_LOADING });
        try {
            const distribution = getDistributionById(distributionID);
            distribution.active = false;
            distribution.endDate = new Date();
            delete distribution.id;
            await editDistribution(distributionID, distribution);
            dispatch(editDistributionFromStore(distributionID, distribution));
            dispatch({ type: DELETE_DISTRIBUTION_SUCCSESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: DELETE_DISTRIBUTION_ERROR, payload: error });
        }
    };
};

export const editDistributionFromStore = (distributionID, distribution) => {
    return dispatch => {
        const { distributions } = store.getState().distributions;
        const newDistributions = [...distributions];
        const index = newDistributions.findIndex(
            item => item.id === distributionID
        );
        newDistributions[index] = { ...distribution, id: distributionID };
        dispatch({ type: SET_DISTRIBUTIONS, payload: newDistributions });
    };
};

export const deleteDistributionsOfClientFromKiosk = clientID => {
    return async dispatch => {
        const { distributions } = store.getState().distributions;
        const distributionOfClient = distributions.filter(
            item => item.clientID === clientID && item.active
        );

        const distributionDeletesPromises = distributionOfClient.map(
            distribution => deleteDistributionWhitoutDispatch(distribution)
        );

        if (distributionDeletesPromises.length > 0) {
            const promises = distributionDeletesPromises.map(p =>
                p.catch(e => e)
            );
            const deletedDistributions = await Promise.all(promises);
            const newDistributions = [...distributions];
            deletedDistributions.forEach(distribution => {
                const index = newDistributions.findIndex(
                    item => item.id === distribution.id
                );
                newDistributions[index] = distribution;
            });
            dispatch({ type: SET_DISTRIBUTIONS, payload: newDistributions });
        }
    };
};

export const deleteDistributionsOfGroupFromKiosk = groupID => {
    return async dispatch => {
        const { distributions } = store.getState().distributions;
        const distributionOfGroup = distributions.filter(
            item => item.groupID === groupID && item.active
        );
        const distributionDeletesPromises = distributionOfGroup.map(
            distribution => deleteDistributionWhitoutDispatch(distribution)
        );
        if (distributionDeletesPromises.length > 0) {
            const promises = distributionDeletesPromises.map(p =>
                p.catch(e => e)
            );
            const deletedDistributions = await Promise.all(promises);
            const newDistributions = [...distributions];
            deletedDistributions.forEach(distribution => {
                const index = newDistributions.findIndex(
                    item => item.id === distribution.id
                );
                newDistributions[index] = distribution;
            });
            dispatch({ type: SET_DISTRIBUTIONS, payload: newDistributions });
        }
    };
};

export const deleteDistributionWhitoutDispatch = async distribution => {
    const { id } = distribution;
    distribution.active = false;
    distribution.endDate = new Date();
    delete distribution.id;
    await editDistribution(id, distribution);
    return { ...distribution, id };
};

export const getDistributionById = id => {
    const { distributions } = store.getState().distributions;
    return distributions.find(distribution => distribution.id === id);
};
