import React from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Edit from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';
import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import ClientMainInfo from './ClientMainInfo';
import ClientActiveDeliveries from './ClientActiveDeliveries';
import ClientNote from './ClientNote';

const ClientInfo = ({ client }) => {
    const classes = useStyles();
    const editIcon = <Edit />;

    return (
        <div className={classes.background}>
            <Grid
                container
                spacing={3}
                direction='column'
                justify='center'
                alignItems='center'>
                <Grid item xs>
                    <ClientMainInfo
                        telephone={client.telephone}
                        address={client.address}
                    />
                </Grid>
                <Grid item xs>
                    <ClientActiveDeliveries/>
                </Grid>
                {client.note && (
                    <Grid item xs>
                        <ClientNote note={client.note} />
                    </Grid>
                )}
            </Grid>
            <Link to={{ pathname: '/clientes/edit', client }}>
                <FloatingActionButton
                    aria='editar'
                    tooltip='editar cliente'
                    icon={editIcon}
                />
            </Link>
        </div>
    );
};

ClientInfo.propTypes = {
    client: PropTypes.object,
};

export default ClientInfo;
