import { SET_CATEGORY_FILTER, SET_TAGS_FILTER } from '../types';

const initialState = {
    category: 0,
    tags: [],
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CATEGORY_FILTER:
            return { ...state, category: payload };
        case SET_TAGS_FILTER:
            return { ...state, tags: [...payload] };
        default:
            return state;
    }
};
