import { wrapKioskQuery } from './kiosk';

const _getBoxes = async kioskReference => {
    const snapshot = kioskReference.collection('boxes').get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getBox = async (id, kioskReference) => {
    const docRef = kioskReference.collection('boxes').doc(id);
    const doc = await docRef.get();
    if (doc.exists) {
        return { ...doc.data(), id: doc.id };
    }
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editBox = async (id, data, kioskReference) => {
    const docRef = kioskReference.collection('boxes').doc(id);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef;
};

export const _addBox = async (data, kioskReference) => {
    const boxesRef = kioskReference.collection('boxes');
    const docRef = await boxesRef.add({ ...data });
    return docRef;
};

export const _deleteBox = async (id, kioskReference) => {
    const docRef = kioskReference.collection('boxes').doc(id);
    await docRef.delete();
};

export const _checkIfBoxExists = async (
    providerID,
    date,
    from,
    kioskReference
) => {

    const fromInbox = from === 'ingreso'
    const snapshot = kioskReference
        .collection('boxes')
        .where('date', '==', date.toString())
        .where('providerID', '==', providerID)
        .where('from', '==', fromInbox)
        .get();
    const cant =  (await snapshot).docs.length
    return cant > 0;
};

export const getBoxes = wrapKioskQuery(_getBoxes);
export const getBox = wrapKioskQuery(_getBox);
export const editBox = wrapKioskQuery(_editBox);
export const addBox = wrapKioskQuery(_addBox);
export const deleteBox = wrapKioskQuery(_deleteBox);
export const checkIfBoxExists = wrapKioskQuery(_checkIfBoxExists);
