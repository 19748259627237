import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import DistributionStateSelector from './DistributionStateSelector';
import VerifyArray from '@sharedComponents/VerifyArray';
import { getDeliveries } from '@redux/actions/delivery';
import DistributionFilters from './DistributionFilters';
import filterDeliveries from './filterDeliveries';
import DistList from '../DistList';

function ListPart() {
    const dispatch = useDispatch();

    const [date, setDate] = useState(new Date());
    const [deliveryMan, setDeliveryMan] = useState('');
    const [deliveryState, setDeliveryState] = useState('1');

    const { deliveryMans } = useSelector(state => state.deliveryMan);
    const { deliveries } = useSelector(state => state.delivery);

    const [filteredDeliveries, setFilteredDeliveries] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        dispatch(getDeliveries(date));
    }, [date]);

    useEffect(() => {
        const filtered = filterDeliveries(
            deliveries,
            deliveryMan,
            deliveryState
        );
        setFilteredDeliveries(filtered);
    }, [JSON.stringify(deliveries), deliveryMan, deliveryState]);

    return (
        <div>
            <DistributionFilters
                deliveryState={deliveryState}
                setDeliveryState={setDeliveryState}
                deliveryMans={[...deliveryMans, { id: '', name: 'Todos' }]}
                deliveryMan={deliveryMan}
                setDeliveryMan={setDeliveryMan}
                date={date}
                setDate={setDate}
            />

            <VerifyArray array={filteredDeliveries} defaultItem='reparto' redirect='/addDist'>
                <DistList
                    listData={filteredDeliveries}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                />
            </VerifyArray>

            {selectedItems.length > 0 ? (
                <DistributionStateSelector
                    deliveryState={deliveryState}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                />
            ) : (
                <Link to='addDist'>
                    <FloatingActionButton
                        color='secondary'
                        aria='add'
                        icon={<AddIcon />}
                        tooltip='Añadir un nuevo reparto'
                        clickHandler={() => {}}
                    />
                </Link>
            )}
        </div>
    );
}

export default ListPart;
