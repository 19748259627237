import {
    SET_PROVIDERS,
    SET_PROVIDERS_LOADING,
    SET_PROVIDERS_ERROR,
    EDIT_PROVIDERS_SUCCSESS,
    EDIT_PROVIDERS_LOADING,
    EDIT_PROVIDERS_ERROR,
    ADD_PROVIDERS_SUCCSESS,
    ADD_PROVIDERS_LOADING,
    ADD_PROVIDERS_ERROR,
} from '../types';

const initialState = {
    providersLoading: false,
    providersError: false,
    providers: [],
    editProvidersLoading: false,
    editProvidersError: false,
    addProvidersLoading: false,
    addProvidersError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PROVIDERS:
            return {
                ...state,
                providers: [...payload],
                providersLoading: false,
                providersError: false,
            };
        case SET_PROVIDERS_LOADING:
            return { ...state, providersLoading: true, providersError: false };
        case SET_PROVIDERS_ERROR:
            return {
                ...state,
                providersLoading: false,
                providersError: { ...payload },
            };
            case EDIT_PROVIDERS_SUCCSESS:
            return {
                ...state,
                providers: [...payload],
                editProvidersLoading: false,
                editProvidersError: false,
            };
        case EDIT_PROVIDERS_LOADING:
            return { ...state, editProvidersLoading: true, editProvidersError: false };
        case EDIT_PROVIDERS_ERROR:
            return {
                ...state,
                editProvidersLoading: false,
                editProvidersError: { ...payload },
            };
        case ADD_PROVIDERS_SUCCSESS:
            return {
                ...state,
                providers: [...payload],
                addProvidersLoading: false,
                addProvidersError: false,
            };
        case ADD_PROVIDERS_LOADING:
            return { ...state, addProvidersLoading: true, addProvidersError: false };
        case ADD_PROVIDERS_ERROR:
            return {
                ...state,
                addProvidersLoading: false,
                addProvidersError: { ...payload },
            };
        default:
            return state;
    }
};
