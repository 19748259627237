import { wrapKioskQuery } from './kiosk';

const _getDistributionOrders = async (groupID, kioskReference) => {
    const snapshot = kioskReference
        .collection('deliveryGroups')
        .doc(groupID)
        .collection('distributionOrders')
        .get();
    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

const _getDistributionOrderForDate = async (id, date, kioskReference) => {
    const snapshot = kioskReference
        .collection('deliveryGroups')
        .doc(id)
        .collection('distributionOrders')
        .where('date', '==', date.toString())
        .get();

    return (await snapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }))[0];
};

//Edit es diferente ya que set no devuelve nada cuando se completa
export const _editDistributionOrder = async (
    groupID,
    orderID,
    data,
    kioskReference
) => {
    const docRef = kioskReference
        .collection('deliveryGroups')
        .doc(groupID)
        .collection('distributionOrders')
        .doc(orderID);
    const updDocRef = await docRef.set({ ...data }).then(() => true);
    return updDocRef;
};

export const _addDistributionOrder = async (groupID, data, kioskReference) => {
    const deliveryGroupsRef = kioskReference
        .collection('deliveryGroups')
        .doc(groupID)
        .collection('distributionOrders');
    const docRef = await deliveryGroupsRef.add({ ...data });
    return docRef;
};

export const getDistributionOrders = wrapKioskQuery(_getDistributionOrders);
export const getDistributionOrderForDate = wrapKioskQuery(
    _getDistributionOrderForDate
);
export const editDistributionOrder = wrapKioskQuery(_editDistributionOrder);
export const addDistributionOrder = wrapKioskQuery(_addDistributionOrder);
