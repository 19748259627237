import {
    SET_DISTRIBUTIONS,
    ALL_DISTRIBUTION_DATA_ERROR,
    ALL_DISTRIBUTION_DATA_LOADING,
    ALL_DISTRIBUTION_DATA_SUCCSESS,
    ADD_DISTRIBUTION_SUCCSESS,
    ADD_DISTRIBUTION_LOADING,
    ADD_DISTRIBUTION_ERROR,
    DELETE_DISTRIBUTION_LOADING,
    DELETE_DISTRIBUTION_SUCCSESS,
    DELETE_DISTRIBUTION_ERROR,
    EDIT_DISTRIBUTION_LOADING,
    EDIT_DISTRIBUTION_SUCCSESS,
    EDIT_DISTRIBUTION_ERROR,
} from '../types';

const initialState = {
    distributions: [],
    allDistributionDataLoading: false,
    allDistributionDataError: false,
    addDistributionLoading: false,
    addDistributionError: false,
    deleteDistributionLoading: false,
    deleteDistributionError: false,
    editDistributionLoading: false,
    editDistributionError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DISTRIBUTIONS:
            return { ...state, distributions: payload };
        case ALL_DISTRIBUTION_DATA_SUCCSESS:
            return {
                ...state,
                allDistributionDataLoading: false,
                allDistributionDataError: false,
            };
        case ALL_DISTRIBUTION_DATA_LOADING:
            return {
                ...state,
                allDistributionDataLoading: true,
                allDistributionDataError: false,
            };
        case ALL_DISTRIBUTION_DATA_ERROR:
            return {
                ...state,
                allDistributionDataLoading: false,
                allDistributionDataError: payload,
            };
        case ADD_DISTRIBUTION_SUCCSESS:
            return {
                ...state,
                addDistributionLoading: false,
                addDistributionError: false,
            };
        case ADD_DISTRIBUTION_LOADING:
            return {
                ...state,
                addDistributionLoading: true,
                addDistributionError: false,
            };
        case ADD_DISTRIBUTION_ERROR:
            return {
                ...state,
                addDistributionLoading: false,
                addDistributionError: payload,
            };
        case DELETE_DISTRIBUTION_SUCCSESS:
            return {
                ...state,
                deleteDistributionLoading: false,
                deleteDistributionError: false,
            };
        case DELETE_DISTRIBUTION_LOADING:
            return {
                ...state,
                deleteDistributionLoading: true,
                deleteDistributionError: false,
            };
        case DELETE_DISTRIBUTION_ERROR:
            return {
                ...state,
                deleteDistributionLoading: false,
                deleteDistributionError: payload,
            };
        case EDIT_DISTRIBUTION_SUCCSESS:
            return {
                ...state,
                editDistributionLoading: false,
                editDistributionError: false,
            };
        case EDIT_DISTRIBUTION_LOADING:
            return {
                ...state,
                editDistributionLoading: true,
                editDistributionError: false,
            };
        case EDIT_DISTRIBUTION_ERROR:
            return {
                ...state,
                editDistributionLoading: false,
                editDistributionError: payload,
            };

        default:
            return state;
    }
};
