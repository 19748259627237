const filterDeliveries = (deliveries, deliveryMan, deliveryState) => {
    let deliveryManFiltered = deliveries;
    if (deliveryMan) {
        deliveryManFiltered = deliveries.filter(
            delivery => delivery.deliveryManID === deliveryMan
        );
    }
    const deliveryStateFiltered = deliveryManFiltered.map(delivery => ({
        ...delivery,
        dist: delivery.dist.filter(
            dist => dist.state.state === Number(deliveryState)
        ),
    }));
    return deliveryStateFiltered;
};

export default filterDeliveries
