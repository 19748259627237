import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types'

import { Button, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Delete } from '@material-ui/icons';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import { getDeliveryGroupFromStore } from '@redux/actions/deliveryGroups';
import EditDistributionPublications from './EditDistributionPublications';
import { getClientByIDFromStore } from '@redux/actions/clients';
import DeleteDistributionModal from '../DeleteDistributionModal';
import InputError from '@sharedComponents/InputError';
import Loading from '@sharedComponents/Loading';
import getDistributionErrors from './validation';
import {
    editProductsForDistributionFromStore,
    getDistributionById,
} from '@redux/actions/distributions';
import PickDateOrDay from './PickDateOrDay';
import { useStyles } from './styles';

const publicationData = {
    publication: null,
    cant: '',
};

const EditDistribution = ({ match }) => {
    const {
        params: { id },
    } = match;

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { addDistributionLoading } = useSelector(
        state => state.distributions
    );

    useEffect(() => {
        if (id) {
            const distribution = getDistributionById(id);
            const group = getDeliveryGroupFromStore(distribution.groupID);
            const client = getClientByIDFromStore(distribution.clientID);
            setGroup(group);
            setClient(client);
            setPublicationRows(
                distribution.inDistProductRows || [{ ...publicationData }]
            );
            setFromDistributionDateOrDay({
                periodicDays: distribution.periodicDays,
                isPeriodic: distribution.isPeriodic,
            }); //falta si es unicamente un dia
        }
    }, [id]);

    const [fromDistributionDateOrDay, setFromDistributionDateOrDay] = useState(
        {}
    );
    const [publicationRows, setPublicationRows] = useState([
        { ...publicationData },
    ]);
    const [errors, setErrors] = useState({});
    const [group, setGroup] = useState('');
    const [client, setClient] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const confirmEdit = () => {
        const distErrors = getDistributionErrors({
            publications: publicationRows,
        });
        if (!distErrors) {
            setErrors({});

            dispatch(
                editProductsForDistributionFromStore(publicationRows, id ,() => history.goBack())
            );
        } else {
            setErrors(distErrors);
        }
    };

    if (addDistributionLoading) return <Loading />;

    return (
        <>
            <Grid
                spacing={2}
                container
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12}>
                    <TextField
                        disabled={true}
                        className={classes.textField}
                        label='Cliente'
                        value={client.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={true}
                        value={group.name}
                        className={classes.textField}
                        label='Grupo'
                    />
                </Grid>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs={12} className={classes.table}>
                    <EditDistributionPublications
                        publicationRows={publicationRows}
                        setPublicationRows={setPublicationRows}
                    />
                </Grid>
                <Grid item xs>
                    {errors.publications && (
                        <InputError message={errors.publications} />
                    )}
                </Grid>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs={12}>
                    <PickDateOrDay
                        fromDistributionDateOrDay={fromDistributionDateOrDay}
                    />
                </Grid>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs>
                    <Button
                        variant='contained'
                        onClick={confirmEdit}
                        color='secondary'>
                        Confirmar
                    </Button>
                </Grid>
            </Grid>
            <DeleteDistributionModal
                deleteID={id}
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
            />
            <FloatingActionButton
                color='secondary'
                aria='delete'
                icon={<Delete />}
                tooltip='Inactivar el reparto'
                clickHandler={() => setOpenDeleteModal(true)}
            />
        </>
    );
};

EditDistribution.propTypes = {
    match: PropTypes.object,
};

export default EditDistribution;
