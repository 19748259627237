import {
    SET_NOTES,
    SET_NOTES_LOADING,
    SET_NOTES_ERROR,
    EDIT_NOTES_SUCCSESS,
    EDIT_NOTES_LOADING,
    EDIT_NOTES_ERROR,
    ADD_NOTES_SUCCSESS,
    ADD_NOTES_LOADING,
    ADD_NOTES_ERROR,
    DELETE_NOTES_LOADING,
    DELETE_NOTES_ERROR,
    DELETE_NOTES_SUCCSESS,
} from '../types';

const initialState = {
    notesLoading: false,
    notesError: false,
    notes: [],
    editNotesLoading: false,
    editNotesError: false,
    addNotesLoading: false,
    addNotesError: false,
    deleteNotesLoading: false,
    deleteNotesError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_NOTES:
            return {
                ...state,
                notes: [...payload],
                notesLoading: false,
                notesError: false,
            };
        case SET_NOTES_LOADING:
            return { ...state, notesLoading: true, notesError: false };
        case SET_NOTES_ERROR:
            return {
                ...state,
                notesLoading: false,
                notesError: { ...payload },
            };

        case EDIT_NOTES_SUCCSESS:
            return {
                ...state,
                editNotesLoading: false,
                editNotesError: false,
            };
        case EDIT_NOTES_LOADING:
            return { ...state, editNotesLoading: true, editNotesError: false };
        case EDIT_NOTES_ERROR:
            return {
                ...state,
                editNotesLoading: false,
                editNotesError: { ...payload },
            };
        case ADD_NOTES_SUCCSESS:
            return {
                ...state,
                addNotesLoading: false,
                addNotesError: false,
            };
        case ADD_NOTES_LOADING:
            return { ...state, addNotesLoading: true, addNotesError: false };
        case ADD_NOTES_ERROR:
            return {
                ...state,
                addNotesLoading: false,
                addNotesError: { ...payload },
            };
        case DELETE_NOTES_SUCCSESS:
            return {
                ...state,
                deleteNotesLoading: false,
                deleteNotesError: false,
            };
        case DELETE_NOTES_LOADING:
            return {
                ...state,
                deleteNotesLoading: true,
                deleteNotesError: false,
            };
        case DELETE_NOTES_ERROR:
            return {
                ...state,
                deleteNotesLoading: false,
                deleteNotesError: { ...payload },
            };
        default:
            return state;
    }
};
