import {
    SIGNUP_LOADING,
    SIGNUP_SUCCESSFULL,
    SIGNUP_FAILED,
    LOGIN_LOADING,
    LOGIN_SUCCESSFULL,
    LOGIN_FAILED,
    USER_SETED,
    USER_MISSING,
    SIGNOUT_LOADING,
    SIGNOUT_SUCCESSFULL,
    SIGNOUT_FAILED,
    LINK_KIOSK_SUCCESS,
} from '../types';

const initialState = {
    login: {
        error: false,
        loading: false,
    },
    signup: {
        error: false,
        loading: false,
    },
    user: {
        loading: true,
        current: false,
    },
    signout: {
        error: false,
        loading: false,
    },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SIGNUP_LOADING:
            return { ...state, signup: { error: false, loading: true } };
        case SIGNUP_SUCCESSFULL:
            return { ...state, signup: { error: false, loading: false } };
        case SIGNUP_FAILED:
            return { ...state, signup: { error: payload, loading: false } };
        case LOGIN_LOADING:
            return { ...state, login: { error: false, loading: true } };
        case LOGIN_SUCCESSFULL:
            return { ...state, login: { error: false, loading: false } };
        case LOGIN_FAILED:
            return { ...state, login: { error: payload, loading: false } };
        case USER_SETED:
            return { ...state, user: { current: payload, loading: false } };
        case USER_MISSING:
            return { ...state, user: { current: false, loading: false } };
        case SIGNOUT_LOADING:
            return { ...state, signout: { error: false, loading: true } };
        case SIGNOUT_SUCCESSFULL:
            return { ...state, signout: { error: false, loading: false } };
        case SIGNOUT_FAILED:
            return { ...state, signout: { error: payload, loading: false } };
        case LINK_KIOSK_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    current: {
                        ...state.user.current,
                        userExtraInfo: {
                            ...state.user.current.userExtraInfo,
                            kiosk: payload,
                        },
                    },
                },
            };
        default:
            return state;
    }
};
