import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Category from '@material-ui/icons/Category';
import Label from '@material-ui/icons/Label';
import Grid from '@material-ui/core/Grid';

import SearchComponent from '@sharedComponents/SearchComponent';
import CategoryModal from '../CategoryModal';
import TagModal from '../TagModal';
import { styles } from './styles';

function NavigationOnInventory({ classes, setSearch }) {
    const [openTagModal, setOpenTagModal] = useState(false);
    const [openCategoryModal, setOpenCategoryModal] = useState(false);
    
    return (
        <div className={classes.margin}>
            <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'>
                <Grid item xs={2}>
                    <IconButton
                        className={classes.margin}
                        onClick={() => setOpenTagModal(true)}
                        aria-label='Tags'>
                        <Label />
                    </IconButton>
                </Grid>
                <Grid item xs={2}>
                    <IconButton
                        className={classes.margin}
                        onClick={() => setOpenCategoryModal(true)}
                        aria-label='Category'>
                        <Category />
                    </IconButton>
                </Grid>
                <Grid item xs={8}>
                    <SearchComponent title='producto' setSearch={setSearch} />
                </Grid>
            </Grid>
            <CategoryModal open={openCategoryModal} setOpen={setOpenCategoryModal} />
            <TagModal open={openTagModal} setOpen={setOpenTagModal} />

        </div>
    );
}

NavigationOnInventory.propTypes = {
    classes: PropTypes.object.isRequired,
    setSearch: PropTypes.func,
};

export default withStyles(styles)(NavigationOnInventory);
