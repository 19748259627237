import { addDays, isSameDay } from 'date-fns';
import { getDateFromDateOrTimestamp } from '../../utils/utils';
import {
    CLEAR_SELL_MAGAZINE,
    SET_NEW_SELL_MAGAZINE_DAY_EDITIONS,
    SET_MAGAZINE_EDITIONS
} from '../types';
import store from '../store';

export const getMagazineDayEditions = date => {
    
    const { magazineDayEditionsForSell } = store.getState().sells;
    const dayEditions = magazineDayEditionsForSell.find(
        element => isSameDay(element.date, date)
    );

    return dayEditions ? dayEditions.magazineDayEditions : null;
};

export const createDayEditions = (date, aditionalEditionIDs = []) => {
    
    const { products } = store.getState().products;
    let editions = aditionalEditionIDs;

    products
        .filter(product => product.category === 3)
        .forEach(product => {
            if (product.editions) {
                product.editions.forEach(edition => {
                    const editionDate = getDateFromDateOrTimestamp(
                        edition.date
                    );

                    if (
                        date >= editionDate &&
                        date <= addDays(editionDate, product.returns)
                    ) {
                        editions.push(edition.id);
                    }
                });
            }
        });

    const dayEditions = {
        date,
        magazineDayEditions: editions,
    };

    store.dispatch({
        type: SET_NEW_SELL_MAGAZINE_DAY_EDITIONS,
        payload: dayEditions,
    });

    return dayEditions.magazineDayEditions;
};

export const addToDayEditions = (edition, date) => {

    const { magazineDayEditionsForSell } = store.getState().sells;

    const dayEditions = magazineDayEditionsForSell.find(
        element => isSameDay(element.date, date)
    );

    if (dayEditions) {
        dayEditions.magazineDayEditions.push(edition.id);
        const newEditionsToSell = magazineDayEditionsForSell.map(
            element =>
                isSameDay(element.date, date)
                    ? dayEditions
                    : element
        );
        
        store.dispatch({ type: SET_MAGAZINE_EDITIONS, payload: newEditionsToSell });
    } else {
        createDayEditions(date, [edition.id]);
    }

    store.dispatch({
        type: CLEAR_SELL_MAGAZINE,
    });
};

export const getEditionsFromDayEditions = dayEditions => {
    const { products } = store.getState().products;
    let editions = [];
    dayEditions.forEach(editionID => {
        products.forEach(product => {
            if (product.editions) {
                const edition = product.editions.find(
                    element => element.id === editionID
                );
                if (edition) {
                    editions.push({ ...edition, productName: product.name });
                }
            }
        });
    });
    return editions;
};
