import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography } from '@material-ui/core';

import useStyles from './styles';

const DefaultMessage = ({ item, redirect, femenine }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant='body1'>
                Parece que no hay ningun{femenine && 'a'} {item}
            </Typography>
            {redirect && (
                <Link to={redirect} className={classes.link}>
                    <Button variant='contained' color='secondary'>
                        Añade {femenine ? 'una nueva' : 'un nuevo'} {item}
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default DefaultMessage;
