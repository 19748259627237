export const capitalizeFirst = str =>
    str.day.charAt(0).toUpperCase() + str.day.slice(1);

export const formatDateTimeToYmd = dateTime => {
    var month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
    var date = ('0' + dateTime.getDate()).slice(-2);
    var year = dateTime.getFullYear();
    return year + '-' + month + '-' + date;
};

export const formatDateTimeToDmy = dateTime => {
    var month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
    var date = ('0' + dateTime.getDate()).slice(-2);
    var year = dateTime.getFullYear();
    return date + '-' + month + '-' + year;
};

export const dayOfTheWeekSpanish = [
    'domingo',
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado',
];

export const loadScript = (src, position, id) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
};

export const isSameDay = (date1, date2) => {
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    );
}

export const getDateFromDateOrTimestamp = (dateOrTimestamp) => {
    if (dateOrTimestamp instanceof Date) {
        return dateOrTimestamp
    } else {
        const date = dateOrTimestamp.toDate()
        return date; 
    }
}