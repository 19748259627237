import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    map: {
        height: '400px',
        width: '100%',
    },
    googleMap: { width: '100%', height: '100%', position: 'relative' },
    mapWrapper: { width: '100%', height: '100%', position: 'absolute' },
    mapContainerElement: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    mapElement: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    mapLoadingElement: { width: '1000px', height: '1000px' },
    transitModeSelectorDiv: {
        zIndex: 10,
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        padding: theme.spacing(2),
    },
    groupSelectorDiv: {
        zIndex: 10,
        position: 'absolute',
        top: theme.spacing(12),
        left: theme.spacing(2),
        padding: theme.spacing(2),
    },
    iconSpan: {
        paddingRight: theme.spacing(1),
    }
}));

export default useStyles;
