export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_PROVIDERS_LOADING = 'SET_PROVIDERS_LOADING';
export const SET_PROVIDERS_ERROR = 'SET_PROVIDERS_ERROR';

export const EDIT_PROVIDERS_SUCCSESS = 'EDIT_PROVIDERS_SUCCSESS';
export const EDIT_PROVIDERS_LOADING = 'EDIT_PROVIDERS_LOADING';
export const EDIT_PROVIDERS_ERROR = 'EDIT_PROVIDERS_ERROR';

export const ADD_PROVIDERS_SUCCSESS = 'ADD_PROVIDERS_SUCCSESS';
export const ADD_PROVIDERS_LOADING = 'ADD_PROVIDERS_LOADING';
export const ADD_PROVIDERS_ERROR = 'ADD_PROVIDERS_ERROR';
