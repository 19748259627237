import React from 'react';
import PropTypes from 'prop-types'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core';

import ModalBody from '@sharedComponents/ModalBody';

const DeleteBoxModal = ({ open, handleClose, handleAccept }) => {
    return (
        <Dialog
            open={open}
            setOpen={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='delete-edition-modal'>
            <DialogTitle id='label-modal'>Eliminar una caja</DialogTitle>
            <DialogContent>
                <ModalBody>
                    <Typography variant='body1'>
                        Estas seguro que quieres eliminar esta caja? esta accion
                        eliminara tanto la caja como las ediciones que contiene.
                    </Typography>
                </ModalBody>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color='primary'>
                    Cancelar
                </Button>
                <Button onClick={handleAccept} color='secondary'>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteBoxModal;

DeleteBoxModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleAccept: PropTypes.func.isRequired,
}

