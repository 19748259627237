import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import { addDistributionFromStore } from '@redux/actions/distributions';
import EditDistributionClientSelector from './EditDistributionClientSelector';
import EditDistributionGroupSelector from './EditDistributionGroupSelector';
import EditDistributionPublications from './EditDistributionPublications';
import InputError from '@sharedComponents/InputError';
import Loading from '@sharedComponents/Loading';
import getDistributionErrors from './validation';
import GroupAdd from '../../GroupsFolder/Add';
import PickDateOrDay from './PickDateOrDay';
import { useStyles } from './styles';

const publicationData = {
    inDistProduct: null,
    amount: '',
};

const AddDistribution = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { addDistributionLoading } = useSelector(
        state => state.distributions
    );

    const [errors, setErrors] = useState({});
    const [showAddGroupModal, setShowAddGroupModal] = useState(false);
    const [publicationRows, setPublicationRows] = useState([
        { ...publicationData },
    ]);
    const [group, setGroup] = useState('');
    const [client, setClient] = useState('');
    const [dateOrDayValue, setDateOrDayValue] = useState({});

    const handleAddGroup = () => setShowAddGroupModal(true);

    const confirmDistribution = () => {
        const distErrors = getDistributionErrors({
            group,
            client,
            publications: publicationRows,
        });

        if (!distErrors) {
            setErrors({})

            const distribution = {
                ...dateOrDayValue,
                active: true,
                groupID: group.id,
                clientID: client.id,
                address: client.address,
                coordinates: client.coordinates,
                deliveryManID: group.deliveryManID,
                inDistProductRows: publicationRows,
            };

            dispatch(
                addDistributionFromStore(distribution, () => history.goBack())
            );
        } else {
            setErrors(distErrors);
        }
    };

    if (addDistributionLoading) return <Loading />;

    return (
        <>
            <Grid
                spacing={2}
                container
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12}>
                    <EditDistributionClientSelector
                        error={errors.clientSelector}
                        client={client}
                        setClient={setClient}
                    />
                </Grid>
                <Grid item xs={12}>
                    <EditDistributionGroupSelector
                        error={errors.groupSelector}
                        group={group}
                        setGroup={setGroup}
                        handleAddGroup={handleAddGroup}
                    />
                </Grid>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs={12} className={classes.table}>
                    <EditDistributionPublications
                        publicationRows={publicationRows}
                        setPublicationRows={setPublicationRows}
                    />
                </Grid>
                <Grid item xs>
                    {errors.publications && (
                        <InputError message={errors.publications} />
                    )}
                </Grid>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs={12}>
                    <PickDateOrDay setDateOrDayValue={setDateOrDayValue} />
                </Grid>
                <Grid item xs />
                <Grid item xs />
                <Grid item xs>
                    <Button
                        variant='contained'
                        onClick={confirmDistribution}
                        color='secondary'>
                        Confirmar
                    </Button>
                </Grid>
            </Grid>
            <GroupAdd open={showAddGroupModal} setOpen={setShowAddGroupModal} />
        </>
    );
};

AddDistribution.propTypes = {};

export default AddDistribution;
