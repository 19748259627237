import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    sendButton: {
        marginTop: theme.spacing(3),
    },
    grid: {
        marginTop: theme.spacing(3),
    },
    password: {
        maxWidth: '196px',
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    textLink: {
        color: 'inherit',
        textDecoration: 'inherit',
    },
    formHelper: {
        textAlign: 'right',
    },
    register: {
        marginTop: theme.spacing(10),
    },
    linkRegister: {
        fontWeight: 'bold',
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    },

    FormHelperText: {
        color: 'red',
    },
}));

export default useStyles;
