import React, { useState } from 'react';

import Add from '@material-ui/icons/Add';

import GroupAdd from '../Add';
import GroupDelete from '../Delete';
import ListOfGroups from './ListOfGroups';
import useSearch from '@hooks/searchHook';
import VerifyArray from '@sharedComponents/VerifyArray';
import SearchComponent from '@sharedComponents/SearchComponent';
import useGetActiveStoreArray from '@hooks/getActiveStoreArrayHook';
import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';

const GroupList = () => {

    const activeDeliveryGroups = useGetActiveStoreArray(
        'deliveryGroups',
        'deliveryGroups',
        new Date()
    );

    const [filteredGroups, setSearch] = useSearch(activeDeliveryGroups);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const [deleteID, setDeleteID] = useState(null);
    const [editID, setEditID] = useState(null);

    const handleEdit = id => {
        setEditID(id);
        setOpenEdit(true);
    };

    const handleDelete = id => {
        setDeleteID(id);
        setOpenDelete(true);
    };

    return (
        <>
            <SearchComponent title='grupo' setSearch={setSearch} flex />
            <VerifyArray array={filteredGroups} defaultItem='grupo'>
                <ListOfGroups
                    groups={filteredGroups}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />
            </VerifyArray>
            <FloatingActionButton
                aria='add'
                tooltip='Añade un grupo'
                icon={<Add />}
                clickHandler={() => setOpenAdd(true)}
            />
            <GroupAdd
                edit
                open={openEdit}
                editID={editID}
                setOpen={setOpenEdit}
            />
            <GroupAdd open={openAdd} setOpen={setOpenAdd} />
            <GroupDelete
                open={openDelete}
                setOpen={setOpenDelete}
                deleteID={deleteID}
            />
        </>
    );
};

export default GroupList;
