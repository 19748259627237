import * as yup from 'yup';

export const schema = yup.object().shape({
    name: yup
        .string()
        .required('Ingrese un nombre de kiosko')
        .max(25, 'Máximo 25 caracteres'),
    city: yup
        .string()
        .required('Ingrese una ciudad')
        .max(100, 'Máximo 100 caracteres'),
});
