import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import Search from '@material-ui/icons/Search';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import VerifyArray from '@sharedComponents/VerifyArray';
import { DatePickerSetup } from '../../../utils/ConfigSetup';
import { SET_SELL_MAGAZINE } from '@redux/types';
import ListOfNewsPaper from '../ListOfNewsPaper';
import {
    addToDayEditions,
    createDayEditions,
    getEditionsFromDayEditions,
    getMagazineDayEditions,
} from '@redux/actions/sells';
import { styles } from './styles';

function Magazines({ classes }) {
    const [date, setDate] = useState(new Date());
    const [sellInventory, setSellInventory] = useState([]);
    const [
        sellInventoryDayEditionsIDS,
        setSellInventoryDayEditionsIDS,
    ] = useState(null);
    const { sellMagazine } = useSelector(state => state.search);

    const updateEdition = edition => {
        const newSellInventory = sellInventory.map(element =>
            element.id === edition.id ? edition : element
        );
        setSellInventory(newSellInventory);
    };

    useEffect(() => {
        let ids = getMagazineDayEditions(date);
        if (!ids) {
            ids = createDayEditions(date);
        }
        setSellInventoryDayEditionsIDS(ids);
    }, [date]);

    useEffect(() => {
        if (sellMagazine) {
            addToDayEditions(sellMagazine, date);
        }
    }, [sellMagazine]);

    useEffect(() => {
        if (sellInventoryDayEditionsIDS) {
            setSellInventory(
                getEditionsFromDayEditions(sellInventoryDayEditionsIDS)
            );
        }
    }, [sellInventoryDayEditionsIDS]);

    return (
        <>
            <div className={classes.datePicker}>
                <DatePicker
                    label='Fecha: '
                    value={date}
                    onChange={newDate => setDate(newDate)}
                    views={DatePickerSetup}
                    variant='dialog'
                />
            </div>
            <VerifyArray
                array={sellInventory}
                defaultItem='producto'
                redirect={{
                    pathname: '/search',
                    state: {
                        simpleList: false,
                        typeDispatcher: SET_SELL_MAGAZINE,
                    },
                }}>
                <ListOfNewsPaper
                    items={sellInventory}
                    updateEdition={updateEdition}
                />
            </VerifyArray>
            <Link
                to={{
                    pathname: '/search',
                    state: {
                        simpleList: false,
                        typeDispatcher: SET_SELL_MAGAZINE,
                    },
                }}>
                <FloatingActionButton
                    color='secondary'
                    icon={<Search />}
                    aria='search'
                    tooltip='Buscar y agregar un nuevo producto'
                />
            </Link>
        </>
    );
}

Magazines.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Magazines);
