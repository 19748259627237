import { useEffect, useState } from 'react'
import useFetch from '@hooks/fetchHook';
import { getBoxes } from '@services/boxes';
import { formatBoxes } from './helpers' 

const useBoxes = ({ from }) => {
    const [{ data, isLoading }] = useFetch(getBoxes);
    const [boxes, setBoxes] = useState(null);

    useEffect(() => {
        if (data) {
            setBoxes(formatBoxes(data, from));
        }
    }, [data]);

    return { boxes, isLoading };
}

export default useBoxes;