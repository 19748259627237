import React from 'react';
import { useHistory } from 'react-router';

import { Button, Typography } from '@material-ui/core';

import useStyles from './styles';

const ChooseKiosk = () => {
    const history = useHistory();
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography variant='h5' className={classes.title}>
                Elige
            </Typography>

            <div className={classes.buttonsContainer}>
                <Button
                    onClick={() => history.push('/kiosk/add')}
                    variant='outlined'
                    color='secondary'
                    className={classes.button}>
                    Crear un nuevo kiosko
                </Button>

                <Button
                    onClick={() => history.push('/kiosk/link')}
                    variant='outlined'
                    color='secondary'
                    className={classes.button}>
                    Ingresar a un kiosco existente
                </Button>
            </div>
        </div>
    );
};

export default ChooseKiosk;
