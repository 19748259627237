import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import Geocode from 'react-geocode';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import AdressPlaceGeocoder from '@sharedComponents/AdressPlaceSelector';
import { addClientFromStore } from '@redux/actions/clients';
import Loading from '@sharedComponents/Loading';
import { schema } from './schema';
import useStyles from './styles';

const NewClient = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        kiosk: { city },
    } = useSelector(state => state.kiosk);

    const [place, setPlace] = useState(null);
    const { addClientLoading } = useSelector(state => state.clients);

    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const onSubmit = data => {
        Geocode.fromAddress(place.description).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                dispatch(
                    addClientFromStore(
                        {
                            ...data,
                            coordinates: { lat, lng },
                            address: place.description,
                        },
                        () => history.push('/clientes')
                    )
                );
            },
            error => {
                console.error(error);
            }
        );
    };

    if (addClientLoading) return <Loading />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                direction='column'
                justify='center'
                alignItems='center'
                spacing={3}>
                <Grid item xs className={classes.margin}>
                    <TextField
                        name='name'
                        label='Nombre'
                        required
                        error={!!errors.name}
                        inputRef={register}
                        helperText={errors.name && errors.name.message}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        name='telephone'
                        label='Telefono'
                        error={!!errors.telephone}
                        inputRef={register}
                        helperText={
                            errors.telephone && errors.telephone.message
                        }
                    />
                </Grid>
                <Grid item xs>
                    <AdressPlaceGeocoder
                        city={city}
                        value={place}
                        setValue={setPlace}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        className={classes.note}
                        name='note'
                        label='Nota'
                        multiline
                        error={!!errors.note}
                        inputRef={register}
                        helperText={errors.note && errors.note.message}
                    />
                </Grid>
                <Grid item xs>
                    <Button
                        disabled={
                            !isValid || !isDirty || addClientLoading || !place
                        }
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Crear
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

NewClient.propTypes = {};

export default NewClient;
