import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { inactivateProviderFromStore } from '@redux/actions/providers';
import Loading from '@sharedComponents/Loading';

export default function ProvidersDelete({ open, handleClose, providerID }) {
    const dispatch = useDispatch();

    const { editProvidersLoading } = useSelector(state => state.providers);

    const handleConfirm = () => {
        dispatch(inactivateProviderFromStore(providerID, handleClose));
    };

    if (editProvidersLoading) return <Loading />;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>
                ¿Seguro que quieres dar de baja este proovedor?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    Esta accion no eliminara los registros que tengan relacion
                    con este proovedor, sin embargo no podras crear nuevos
                    registros, editar, ni ver este proovedor.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button color='secondary' onClick={handleConfirm} autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ProvidersDelete.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    providerID: PropTypes.number,

}