import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
   container: {
       textAlign: 'center',
       paddingTop: theme.spacing(10)
   },
   button: {
       marginTop: theme.spacing(2)
   }

}));