import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Geocode from 'react-geocode';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    InputLabel,
} from '@material-ui/core';

import {
    addDeliveryGroupToKiosk,
    editDeliveryGroupToKiosk,
    getDeliveryGroupFromStore,
} from '@redux/actions';
import { schema } from './schema';
import { useStyles } from './styles';
import Loading from '@sharedComponents/Loading';
import AdressPlaceSelector from '@sharedComponents/AdressPlaceSelector';

const GroupAdd = ({ open, setOpen, edit, editID }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);
    const { kiosk : { city } } = useSelector(state => state.kiosk);

    const [place, setPlace] = useState(null);
    const [adress, setAdress] = useState('');
    const { deliveryMans } = useSelector(state => state.deliveryMan);
    const [deliveryMan, setDeliveryMan] = useState(null);

    const { addEditDeliveryGroupLoading } = useSelector(
        state => state.deliveryGroups
    );

    const { register, handleSubmit, errors, formState, reset } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const calculateDisabled = (() =>
        edit ? false : !isValid || !isDirty || !deliveryMan)();

    const onSubmit = data => {
        Geocode.fromAddress(place.description).then(
            response => {
                const { lat, lng } = response.results[0].geometry.location;
                dispatch(
                    edit
                        ? editDeliveryGroupToKiosk(editID, {
                              ...data,
                              deliveryManID: deliveryMan.id,
                              coordinates: { lat, lng },
                              address: place.description,
                          })
                        : addDeliveryGroupToKiosk({
                              ...data,
                              deliveryManID: deliveryMan.id,
                              coordinates: { lat, lng },
                              address: place.description,
                              active: true,
                          })
                );
            },
            error => {
                console.error(error);
            }
        );
    };

    useEffect(() => {
        if (edit && editID) {
            const { name, address, deliveryManID } = getDeliveryGroupFromStore(
                editID
            );
            const _deliveryMan = deliveryMans.find(
                deliveryMan => deliveryMan.id === deliveryManID
            );
            setDeliveryMan({ ..._deliveryMan });
            setAdress(address);
            reset({ name });
        }
    }, [edit, editID, reset]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='añadir-editar-grupo'>
            <DialogTitle id='añadir-editar-grupo'>
                {edit ? 'Editar' : 'Añadir'} grupo
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {addEditDeliveryGroupLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className={classes.nameInput}>
                                <InputLabel htmlFor='name'>
                                    Nombre del grupo
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    error={!!errors.name}
                                    required
                                    id='name'
                                    type='text'
                                    name='name'
                                    inputRef={register}
                                    helperText={
                                        errors.name && errors.name.message
                                    }
                                />
                            </div>
                            <div className={classes.addressInput}>
                                <AdressPlaceSelector
                                    city={city}
                                    fullWidth
                                    initialInputValue={edit ? adress : null}
                                    value={place}
                                    setValue={setPlace}
                                />
                            </div>
                            <div className={classes.deliveryManInput}>
                                <Autocomplete
                                    options={[
                                        { name: 'Todos', id: '' },
                                        ...deliveryMans,
                                    ]}
                                    value={deliveryMan}
                                    onChange={(e, value) =>
                                        setDeliveryMan(value)
                                    }
                                    getOptionLabel={option => option.name}
                                    className={classes.flex}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            id='deliveryMan'
                                            className={classes.textField}
                                            label='Repartidor'
                                        />
                                    )}
                                />
                            </div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancelar
                    </Button>
                    <Button
                        type='submit'
                        disabled={calculateDisabled}
                        onClick={handleClose}
                        color='secondary'>
                        Confirmar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default GroupAdd;

GroupAdd.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    edit: PropTypes.bool,
    editID: PropTypes.string,
};
