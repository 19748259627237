import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: { paddingTop: 50 },
    title: { textAlign: 'center', paddingBottom: 30 },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '40%',
        maxWidth: 300,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '5%',
        marginRight: '5%',
    },
}));

export default useStyles;
