import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import { getClientByIDFromStore } from '@redux/actions/clients';
import NavTabs from '@sharedComponents/NavTab';
import ClientHistory from '../ClientHistory';
import ClientInfo from '../ClientInfo';
import useStyles from './styles';

const tabItems = [
    {
        label: 'Informacion',
        href: 'page1',
        component: ClientInfo,
    },
    {
        label: 'Historial',
        href: 'page2',
        component: ClientHistory,
    },
];

const ClientProfile = ({ match }) => {
    const classes = useStyles();
    const {
        params: { id },
    } = match;

    const [client, setClient] = useState(null);

    useEffect(() => setClient(getClientByIDFromStore(id)), [id]);

    return (
        <>
            <div className={classes.title}>
                <Typography variant='h6'>{client && client.name}</Typography>
            </div>
            <NavTabs tabsData={tabItems} props={{ client }} />
        </>
    );
};

ClientProfile.propTypes = {
    match: PropTypes.object,
};

export default ClientProfile;
