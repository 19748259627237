import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    title: {
        display: 'flex',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
        margin: 0,
    },
    button: {
        color: theme.palette.primary.contrastText,
        padding: 0,
        margin: 0,
    },
}));

export default useStyles;
