import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Phone from '@material-ui/icons/Phone';

import useStyles from '../styles';

const ClientMainInfo = ({ telephone, address }) => {
    const classes = useStyles();
    return (
        <Paper square className={classes.paper}>
            <div className={classes.infoSeparator}>
                <Typography component='div' variant='subtitle1'>
                    {telephone}
                    <a
                        href={`https://wa.me/${telephone}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.a}>
                        <IconButton size='small'>
                            <img
                                alt='whatsapp icon'
                                src='https://img.icons8.com/material-outlined/24/000000/whatsapp.png'
                            />
                        </IconButton>
                    </a>
                    <a href={`tel:${telephone}`} className={classes.a}>
                        <IconButton size='small'>
                            <Phone />
                        </IconButton>
                    </a>
                </Typography>
            </div>
            <div className={classes.infoSeparator}>
                <Typography component='p' variant='subtitle1'>
                    {address}
                </Typography>
            </div>
        </Paper>
    );
};

export default ClientMainInfo;

ClientMainInfo.propTypes = {
    telephone: PropTypes.string,
    address: PropTypes.string
}
