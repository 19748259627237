import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({
    component: Component,
    unlinked,
    ...rest
}) {
    const currentUser = useSelector(state => state.auth.user.current);

    return (
        <Route
            {...rest}
            render={props => {
                if (!currentUser) {
                    return <Redirect to='/login' />;
                }
                if (!currentUser.userExtraInfo.kiosk && !unlinked) {
                    return <Redirect to='/kiosk/choose' />;
                }
                return <Component {...props} />;
            }}
        />
    );
}
