import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grid: {
        marginTop: theme.spacing(5),
    },
    container: {
        marginTop: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    priceInput: {
        width: '182px',
    },
    sendButton: {
        marginTop: theme.spacing(3),
    },
}));

export default useStyles;
