import React from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';


const PublicTopAppBar = ({title, classes}) => {
    return (
        <div className={classes.root}>
            <AppBar position='static'>
                <Toolbar>
                    <Typography
                        variant='h6'
                        color='inherit'
                        className={classes.grow}>
                        {title}
                    </Typography>
                    <Button color='inherit'>
                        <Link to='/login' className={classes.textLink}>
                            Login
                        </Link>
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default withStyles(styles)(PublicTopAppBar)

PublicTopAppBar.propTypes = {
    title: PropTypes.string,
    classes: PropTypes.object,
}