export const noteContent = text => {
    const dots = '...';
    let paragraph = text.slice(0, 71);
    if (text.length > 71) paragraph = paragraph.concat(dots);

    return paragraph;
};

export const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
};
