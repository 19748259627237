import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    GoogleMap,
    withGoogleMap,
    InfoWindow,
    Marker,
} from 'react-google-maps';

import DistributionDirection from '../DistributionDirection/index.js';
import useStyles from './styles';

const defaultCenter = { lat: -37.993542, lng: -57.564828 }; //Mar del plata

const Map = ({ transitMode, activeRouteGroup }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(null);
    const [selectedStart, setSelectedStart] = useState(null);
    const [center, setCenter] = useState(defaultCenter);

    useEffect(() => {
        if (activeRouteGroup && activeRouteGroup.length > 0) {
            setCenter(activeRouteGroup[0].origin)
        }
    }, [JSON.stringify(activeRouteGroup)]); // Necesario para que cambie cuando cambia el orden

    return (
        <GoogleMap
            classes={classes.googleMap}
            defaultCenter={center}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
            }}>
            {activeRouteGroup.length > 0 && (
                <Marker
                    position={activeRouteGroup[0].origin}
                    icon={`/location-marker-start.svg`}
                    clickable={true}
                    onClick={() => setSelectedStart(activeRouteGroup[0].origin)}
                />
            )}

            {activeRouteGroup.map(direction => (
                <DistributionDirection
                    transitMode={transitMode}
                    key={direction.clientName}
                    setSelected={setSelected}
                    direction={direction}
                />
            ))}

            {selectedStart && (
                <InfoWindow
                    position={selectedStart}
                    onCloseClick={() => setSelectedStart(null)}>
                    <div>
                        <p>Inicio de la ruta</p>
                    </div>
                </InfoWindow>
            )}

            {selected && (
                <InfoWindow
                    position={selected.destination}
                    onCloseClick={() => setSelected(null)}>
                    <div>
                        <p>Cliente: {selected.clientName}</p>
                        <p>Entregar: {selected.delivery[0]}</p>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
};

const WrappedMap = withGoogleMap(Map);

Map.propTypes = {
    transitMode: PropTypes.string,
    activeRouteGroup: PropTypes.array,
};

export default WrappedMap;
