import React from 'react';
import { Route } from 'react-router-dom';

import Archive from '@material-ui/icons/Archive';
import AttachMoney from '@material-ui/icons/AttachMoney';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import AssignmentReturned from '@material-ui/icons/AssignmentReturned';
import BarChart from '@material-ui/icons/BarChart';
import Inbox from '@material-ui/icons/Inbox';
import Notes from '@material-ui/icons/Notes';
import Settings from '@material-ui/icons/Settings';
import Timeline from '@material-ui/icons/Timeline';
import Undo from '@material-ui/icons/Undo';

import BoxOpened from '@pages/InFolder/BoxOpened';
import Configurations from '@pages/ConfigFolder/Configurations';
import BoxForm from '@pages/InFolder/BoxForm';
import In from '@pages/InFolder/In';
import Inventory from '@pages/InventoryFolder/Inventory';
import Sells from '@pages/SellsFolder/Sells';
import MagazineSeachList from '@pages/SellsFolder/MagazineSearchList';
import Returns from '@pages/ReturnsFolder/Returns';
import Distribution from '@pages/DistributionFolder/Distribution';
import Clients from '@pages/ClientsFolder/Clients';
import NewProduct from '@pages/ProductsFolder/Product/NewProduct';
import Note from '@pages/NotesFolder/Note';
import NewClient from '@pages/ClientsFolder/NewClient';
import ClientProfile from '@pages/ClientsFolder/ClientProfile';
import Login from '@pages/Login';
import Register from '@pages/Register';

import Providers from '@pages/ProvidersFolder/Providers';
import ProvidersForm from '@pages/ProvidersFolder/ProvidersForm';
import ProvidersDetail from '@pages/ProvidersFolder/ProvidersDetail';
import ProvidersEdit from '@pages/ProvidersFolder/ProvidersEdit';
import Reports from '@pages/ReportsFolder/Reports';
import NotesComponent from '@pages/NotesFolder/Notes';
import EditDistribution from '@pages/DistributionFolder/EditDistibution';
import AddDistribution from '@pages/DistributionFolder/AddDistribution';
import { NotFoundPage } from '@sharedComponents/NotFoundPage';
import ShowProduct from '@pages/ProductsFolder/Product/ShowProduct';
import FormEditionDetail from '@pages/ProductsFolder/Edition/FormEditionDetail';
import ShowEditionDetail from '@pages/ProductsFolder/Edition/ShowEditionDetail';
import NoteEdit from '@pages/NotesFolder/NoteEdit';
import EditClient from '@pages/ClientsFolder/EditClient';
import NewKisok from '@pages/KioskFolder/NewKiosk';
import ChooseKiosk from '@pages/KioskFolder/ChooseKiosk';

import routes from './routes';
import PrivateRoute from './PrivateRoute';
import LinkExistentKiosk from '@pages/KioskFolder/LinkExistentKiosk';

export const ReturnRoutes = () => {
    return RoutesConfig.map((element, index) => {
        if (element.private) {
            return (
                <PrivateRoute
                    unlinked={element.unlinked}
                    exact={!element.exact}
                    path={element.route}
                    component={element.component}
                    key={index}
                />
            );
        } else {
            return (
                <Route
                    exact={!element.exact}
                    path={element.route}
                    component={element.component}
                    key={index}
                />
            );
        }
    });
};

export const RoutesConfig = [
    {
        route: routes.inventario,
        name: 'Inventario',
        icon: <Inbox />,
        inLayout: true,
        component: Inventory,
        private: true,
    },
    {
        route: routes.newProduct,
        inLayout: false,
        component: NewProduct,
        private: true,
    },
    {
        route: routes.ingreso,
        name: 'Ingreso',
        inLayout: true,
        icon: <Archive />,
        component: In,
        private: true,
    },
    {
        route: routes.addDist,
        inLayout: false,
        component: AddDistribution,
        private: true,
    },
    {
        route: routes.editDist,
        inLayout: false,
        component: EditDistribution,
        private: true,
    },
    {
        route: routes.ventas,
        name: 'Ventas',
        inLayout: true,
        icon: <AttachMoney />,
        component: Sells,
        private: true,
    },
    {
        route: routes.devoluciones,
        name: 'Devoluciones',
        inLayout: true,
        icon: <Undo />,
        component: Returns,
        private: true,
    },
    {
        route: routes.search,
        exact: true,
        inLayout: false,
        component: MagazineSeachList,
        private: true,
    },
    {
        route: routes.reparto,
        name: 'Reparto',
        inLayout: true,
        icon: <Timeline />,
        component: Distribution,
        private: true,
    },
    {
        route: routes.clientes,
        name: 'Clientes',
        inLayout: true,
        icon: <AssignmentInd />,
        component: Clients,
        private: true,
    },
    {
        route: routes.newClient,
        inLayout: false,
        component: NewClient,
        private: true,
    },
    {
        route: routes.clientProfile,
        inLayout: false,
        component: ClientProfile,
        private: true,
    },
    {
        route: routes.proovedores,
        name: 'Proveedores',
        inLayout: true,
        icon: <AssignmentReturned />,
        component: Providers,
        private: true,
    },
    {
        route: routes.crearProveedor,
        inLayout: false,
        component: ProvidersForm,
        private: true,
    },
    {
        route: routes.detalleProveedor,
        inLayout: false,
        component: ProvidersDetail,
        private: true,
    },
    {
        route: routes.editarProveedor,
        inLayout: false,
        component: ProvidersEdit,
        private: true,
    },
    {
        route: routes.notas,
        name: 'Notas',
        inLayout: true,
        icon: <Notes />,
        component: NotesComponent,
        private: true,
    },
    {
        route: routes.nota,
        inLayout: false,
        component: Note,
        private: true,
    },
    {
        route: routes.firstStage,
        inLayout: false,
        component: BoxForm,
        private: true,
    },
    {
        route: routes.boxOpened,
        inLayout: false,
        component: BoxOpened,
        private: true,
    },
    {
        route: routes.login,
        inLayout: false,
        component: Login,
        private: false,
    },
    {
        route: routes.register,
        inLayout: false,
        component: Register,
        private: false,
    },
    {
        route: routes.showProduct,
        inLayout: false,
        component: ShowProduct,
        private: true,
    },
    {
        route: routes.formEditionDetail,
        inLayout: false,
        component: FormEditionDetail,
        private: true,
    },
    {
        route: routes.config,
        inLayout: true,
        component: Configurations,
        name: 'Configuraciones',
        icon: <Settings />,
        private: true,
    },
    {
        route: routes.index,
        inLayout: false,
        component: Login,
        private: false,
    },
    {
        route: routes.showEditionDetail,
        inLayout: false,
        component: ShowEditionDetail,
        private: true,
    },
    {
        route: routes.notaEdit,
        inLayout: false,
        component: NoteEdit,
        private: true,
    },
    {
        route: routes.editClient,
        inLayout: false,
        private: true,
        component: EditClient,
    },
    {
        route: routes.newKiosk,
        inLayout: false,
        component: NewKisok,
        private: true,
        unlinked: true,
    },
    {
        route: routes.chooseKiosk,
        inLayout: false,
        component: ChooseKiosk,
        private: true,
        unlinked: true,
    },
    {
        route: routes.linkKiosk,
        inLayout: false,
        component: LinkExistentKiosk,
        private: true,
        unlinked: true,
    },
    {
        inLayout: false,
        component: NotFoundPage,
        private: false,
    },
];
