import { useState, useEffect } from 'react'; 
import { useSelector } from 'react-redux';
import { getIfValidArrayOfItem } from '@redux/actions';

const useGetActiveStoreArray = (store, arrayName, date) => {
    const [activeStoreArray, setActiveStoreArray] = useState([]);
    const array = useSelector(state => state[store][arrayName]);
    
    useEffect(() => {
        const active = getIfValidArrayOfItem(array, date)
        setActiveStoreArray(active)
    }, [array])

    return activeStoreArray
}

export default useGetActiveStoreArray;