import React from 'react';
import PropTypes from 'prop-types';

import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';

import useStyles from '../styles';

const deliveries = {
    actives: [
        { item: 'Clarin', dates: 'lun-mar-mie' },
        { item: 'Nacion', dates: 'jue-sab-lun' },
        { item: 'Clarin', dates: '17/08/2019' },
    ],
};

const ClientActiveDeliveries = ({ clientID }) => {
    const classes = useStyles();
    return (
        <Paper square className={classes.paper}>
            <List
                component='nav'
                aria-labelledby='nested-list-subheader'
                subheader={
                    <ListSubheader component='div' id='nested-list-subheader'>
                        Repartos Activos
                    </ListSubheader>
                }>
                {deliveries.actives.map((item, index) => {
                    return (
                        <ListItem button key={item.item + index}>
                            <ListItemText
                                primary={item.item}
                                secondary={item.dates}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
};

export default ClientActiveDeliveries;

ClientActiveDeliveries.propTypes = {
    clientID: PropTypes.string,
};
