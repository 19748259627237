import React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import { TextField } from '@material-ui/core'
import { Add } from '@material-ui/icons';

import useGetActiveStoreArray from '@hooks/getActiveStoreArrayHook';
import InputError from '@sharedComponents/InputError';
import { useStyles } from '../styles';

const EditDistributionGroupSelector = ({ handleAddGroup, group, setGroup, error }) => {
    const classes = useStyles();

    const activeDeliveryGroups = useGetActiveStoreArray(
        'deliveryGroups',
        'deliveryGroups',
        new Date()
    );

    return (
        <>
            <Autocomplete
                id='group'
                options={activeDeliveryGroups}
                getOptionLabel={option => option.name}
                className={classes.flex}
                value={group}
                onChange={(event, newValue) => setGroup(newValue)
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        className={classes.textField}
                        label='Grupo'
                    />
                )}
            />
            <IconButton
                aria-label='add-group'
                className={classes.addButton}
                onClick={handleAddGroup}>
                <Add />
            </IconButton>
            {error && <InputError message={error}/>}
        </>
    );
};

export default EditDistributionGroupSelector;

EditDistributionGroupSelector.propTypes = {
    handleAddGroup: PropTypes.func,
    group: PropTypes.object,
    setGroup: PropTypes.func,
    error: PropTypes.string
}