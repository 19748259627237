import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'

import Send from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import ErrorMessage from '@sharedComponents/ErrorMessage';
import { DatePickerSetup } from '../../../utils/ConfigSetup';
import { addBoxToKiosk } from '@redux/actions/boxes';
import useValidItems from '@hooks/validItemsHook';
import Loading from '@sharedComponents/Loading';
import useForm from '@hooks/formHook';
import Selector from '../Selector';
import { styles } from './styles';

const BoxForm = ({ classes, history, location }) => {
    const { state } = location;
    const dispatch = useDispatch();

    const [data, setData] = useForm({ selectedDate: new Date(), provider: '' });
    const { providers } = useSelector(state => state.providers);
    const activeProviders = useValidItems(providers, data.selectedDate)

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [items, setItems] = useState(null);
    const { selectedDate, provider, showError, errorMessage } = data;

    useEffect(() => {
        const providerCompanies = activeProviders.map(provider => provider.company);
        setItems(providerCompanies);
    }, [activeProviders]);

    const getIdOfProvider = providerName => {
        const provider = activeProviders.find(prov => prov.company === providerName);
        return provider.id;
    };

    const handleDateChange = date => {
        setData({ ...data, selectedDate: date });
    };

    const handleProviderChange = provider => {
        setData({ ...data, provider: provider });
    };

    const handleClick = async () => {
        setIsSubmitting(true);
        if (provider !== '') {
            let selectedDate = data.selectedDate;
            selectedDate.setHours(0, 0, 0)
            
            const providerID = getIdOfProvider(provider);

            /* Para una implementacion futura buscar si la caja existe,
             por ahora se ve que esta buggeado o hay algun problema con la query, 
             me rindo por ahora, fueron 14 dias de dolor y sufrimiento!!!!
            const boxExists = await checkIfBoxExists(
                providerID,
                selectedDate,
                state.from
            ); */

            if (true) {
                const box = {
                    date: selectedDate,
                    providerName: provider,
                    providerID,
                    inbox: state.from === 'ingreso',
                    inBoxEditions: [],
                    image: '',
                };

                const callback = (boxID, inBoxEditions) => {
                    setIsSubmitting(false);
                    history.push({
                        pathname: '/caja',
                        state: {
                            boxID,
                            inBoxEditions,
                            date: data.date,
                            provider,
                            providerID,
                            quantity: state.from === 'returns',
                            from: state.from,
                        },
                    });
                };

                dispatch(addBoxToKiosk(box, callback));
            } else {
                setIsSubmitting(false);
                setData({
                    ...data,
                    showError: true,
                    errorMessage:
                        'La caja ya existe en esta fecha bajo ese proveedor!',
                });
            }
        } else {
            setIsSubmitting(false);
            setData({
                ...data,
                showError: true,
                errorMessage: 'Debe seleccionar un proovedor',
            });
        }
    };

    if (!items) return <Loading />;
    return (
        <>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'>
                <ErrorMessage
                    errorMessage={errorMessage}
                    open={showError}
                    handleClose={() => setData({ ...data, showError: false })}
                />

                <Grid item xs={12} className={classes.datePicker}>
                    <DatePicker
                        label='Fecha: '
                        value={selectedDate}
                        onChange={handleDateChange}
                        views={DatePickerSetup.views}
                        variant='dialog'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Selector
                        onChange={handleProviderChange}
                        value={provider}
                        name='Proveedor'
                        items={items}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        disabled={isSubmitting}
                        variant='contained'
                        color='secondary'
                        className={classes.button}
                        onClick={handleClick}>
                        Crear
                        <Send className={classes.rightIcon} />
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default withStyles(styles)(BoxForm);

BoxForm.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
};