import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addKioskFromStore } from '@redux/actions/kiosk';
import Loading from '@sharedComponents/Loading';
import { schema } from './schema';
import useStyles from './styles';

const NewKisok = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { addKioskLoading } = useSelector(state => state.kiosk);

    const { register, handleSubmit, errors, formState } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const onSubmit = data => {
        dispatch(addKioskFromStore(data, () => history.push('/inventario')));
    };

    if (addKioskLoading) return <Loading />;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                spacing={5}
                direction='column'
                justify='center'
                alignItems='center'
                className={classes.grid}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant='h5'>Crear Kiosco</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='name'>Nombre</InputLabel>
                    <TextField
                        error={!!errors.name}
                        required
                        id='name'
                        type='text'
                        name='name'
                        inputRef={register}
                        helperText={errors.name && errors.name.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel htmlFor='city'>Ciudad</InputLabel>
                    <TextField
                        error={!!errors.city}
                        required
                        id='city'
                        type='text'
                        name='city'
                        inputRef={register}
                        helperText={errors.city && errors.city.message}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        disabled={!isValid || !isDirty}
                        type='submit'
                        variant='contained'
                        color='secondary'
                        size='large'
                        className={classes.sendButton}>
                        Crear
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default NewKisok;
