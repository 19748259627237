import checkErrorsClientSelector from './EditDistributionClientSelector/validation';
import checkErrorsGroupSelector from './EditDistributionGroupSelector/validation';
import checkErrorPublicationsSelector from './EditDistributionPublications/validation';

const getDistributionErrors = ({ publications, group, client }) => {
    const errors = {
        ...checkErrorsClientSelector(client),
        ...checkErrorPublicationsSelector(publications),
        ...checkErrorsGroupSelector(group),
    };

    if (Object.keys(errors).length > 0) {
        return errors;
    } else {
        return false;
    }
};

export default getDistributionErrors;
