import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Button } from '@material-ui/core';
import { useStyles } from './styles';

export const NotFoundPage = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography variant='h2'>404</Typography>
            <Typography variant='subtitle1'>
                Parece que no hay nada aqui
            </Typography>

            <Button
                variant='contained'
                className={classes.button}
                component={Link}
                to='/'
                color='secondary'>
                Volver al inicio
            </Button>
        </div>
    );
};
