export const styles = theme => ({
    datePicker: {
        display: 'block',
        align: 'center',
        textAlign: 'center',
        padding: theme.spacing(3),
    },
    hour: {
        height: theme.spacing(3),
    },
    selector: {
        marginBottom: theme.spacing(3),
    },
    queonda: {
        display: 'block',
        textTransform: 'none',
    },
    selectorTipo: { paddingLeft: 20 },
    selectedItem: {
        width: 'fit-content',
        zIndex: 10,
        position: 'absolute',
        bottom: 90,
        backgroundColor: '#eeeeee',
        color: '#f50057',
    },
    selectedCuantitySpan: { marginRight: 20, marginLeft: 20 },
    selectedMenuItem: { zIndex: 11 },
    inputWidth: { width: 182 },
    selectorContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
