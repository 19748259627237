import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    grid: {
        fontWeight: 500,
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10,
    },
    gridSelected: {
        fontWeight: 500,
        paddingBottom: 15,
        paddingLeft: 10,
        paddingRight: 10,
    },
    selector: {
        marginBottom: theme.spacing(3),
    },
    selectorTipo: { paddingLeft: 20 },
    selectedItem: {
        width: 'fit-content',
        zIndex: 10,
        position: 'absolute',
        bottom: 90,
        backgroundColor: '#eeeeee',
        color: '#f50057',
    },
    selectedCuantitySpan: { marginRight: 20, marginLeft: 20 },
    selectedMenuItem: { zIndex: 11 },
    selectorContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteIndicator: {
        width: 15,
        height: 15,
        borderRadius: 7.5,
        left: 'calc(100% - 25px)',
        top: 25,
        position: 'relative',
    },
}));
