import { editBox, getBox } from '@services/boxes';
import {
    addEdition,
    deleteEdition,
    editEdition,
} from '@services/editions';
import { getDocByPath } from '@services/kiosk';
import store from '../store';
import {
    NEW_EDITION_ERROR,
    NEW_EDITION_LOADING,
    DELETE_EDITION_ERROR,
    DELETE_EDITION_LOADING,
    DELETE_EDITION_OK,
} from '../types';
import {
    editProductFromStore,
    editProductFromStoreAndKiosk,
    getProductByIDFromStore,
} from './products';

export const addEditionToKiosk = (
    productID,
    edition,
    typeDispatcher,
    callback,
    productName
) => {
    return async dispatch => {
        dispatch({ type: NEW_EDITION_LOADING });
        try {
            const data = await addEdition(productID, {
                ...edition,
                productID,
                returns: 0,
                sells: 0,
            });
            const responseEdition = await getDocByPath(data.path);

            const product = getProductByIDFromStore(productID);
            const newProduct = {
                ...product,
                existences: product.existences + responseEdition.stock,
                lastPrice: responseEdition.price,
                editions: [...product.editions, responseEdition],
            };
            await dispatch(editProductFromStoreAndKiosk(newProduct));

            dispatch({
                type: typeDispatcher,
                payload: { ...responseEdition, productName, productID },
            });
            callback();
        } catch (error) {
            dispatch({ type: NEW_EDITION_ERROR, payload: error });
        }
    };
};

export const removeEditionFromKiosk = (productID, editionID, boxID) => {
    return async dispatch => {
        dispatch({ type: DELETE_EDITION_LOADING });
        try {
            await deleteEdition(productID, editionID);
            const box = await getBox(boxID);
            box.inBoxEditions = box.inBoxEditions.filter(
                inBoxEdition => inBoxEdition.editionID !== editionID
            );
            delete box.id;
            await editBox(boxID, box);
            dispatch({ type: DELETE_EDITION_OK });
        } catch (error) {
            dispatch({ type: DELETE_EDITION_ERROR, payload: error });
        }
    };
};

export const editEditionFromKioskAndStore = edition => {
    return async dispatch => {
        const product = getProductByIDFromStore(edition.productID);
        const newProduct = {
            ...product,
            editions: product.editions
                ? product.editions.map(editionInProduct => {
                      if (editionInProduct.id === edition.id) {
                          return edition;
                      }
                      return editionInProduct;
                  })
                : [],
        };
        await editEditionFromKiosk({ ...edition });
        dispatch(editProductFromStore({ ...newProduct }));
    };
};

export const updateSellsForEdition = (
    sells,
    stock,
    diferential,
    editionToSell
) => {
    return async dispatch => {

        const product = getProductByIDFromStore(editionToSell.productID);

        const productSells =
            editionToSell.sells > sells
                ? product.selled - diferential
                : product.selled + diferential;

        const productExistences =
            editionToSell.sells > sells
                ? product.existences + diferential
                : product.existences - diferential;

        product.selled = productSells;
        product.existences = productExistences;

        const edition = { ...editionToSell, sells, stock };
        delete edition.productName;

        await dispatch(editProductFromStoreAndKiosk({ ...product }));
        await dispatch(editEditionFromKioskAndStore(edition));
    };
};

export const editEditionFromKiosk = async edition => {
    const productID = edition.productID;
    const editionID = edition.id;
    delete edition.id;
    delete edition.productID;
    await editEdition(productID, editionID, edition);
};

export const getEditionFromStore = (productID, editionID, addName = false) => {
    const { products } = store.getState().products;
    const product = products.find(product => product.id === productID);
    const edition = product.editions.find(edition => edition.id === editionID);
    if (addName && edition) {
        edition.productName = product.name;
    }
    return edition;
};
