import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: 40,
        marginRight: 40,
    },
    welcome: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),

        color: theme.palette.primary.main,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        textAlign: 'center',
    },
}));

export default useStyles;
