import React from 'react'
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

const GridCorrection = ({ notes }) => {
    if (notes.length % 2) return <Grid item xs={5} />;
    else return null;
};

export default GridCorrection;

GridCorrection.propTypes = {
    notes: PropTypes.array
}
