const tags = [
    'Guerra',
    'Autos',
    'Noticias',
    'Farandula',
    'Moda',
    'Costura',
    'Para armar',
    'Coleccion',
    'Historia',
    'Niños',
];

export default tags