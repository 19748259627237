export const SET_NOTES = 'SET_NOTES'
export const SET_NOTES_LOADING = 'SET_NOTES_LOADING' 
export const SET_NOTES_ERROR = 'SET_NOTES_ERROR' 

export const EDIT_NOTES_SUCCSESS = 'EDIT_NOTES_SUCCSESS'
export const EDIT_NOTES_LOADING = 'EDIT_NOTES_LOADING' 
export const EDIT_NOTES_ERROR = 'EDIT_NOTES_ERROR' 

export const ADD_NOTES_SUCCSESS = 'ADD_NOTES_SUCCSESS'
export const ADD_NOTES_LOADING = 'ADD_NOTES_LOADING' 
export const ADD_NOTES_ERROR = 'ADD_NOTES_ERROR'

export const DELETE_NOTES_SUCCSESS = 'DELETE_NOTES_SUCCSESS'
export const DELETE_NOTES_LOADING = 'DELETE_NOTES_LOADING' 
export const DELETE_NOTES_ERROR = 'DELETE_NOTES_ERROR'