import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import {
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import ProvidersDelete from '../ProvidersDelete';

const ProvidersList = ({ providers }) => {
    const history = useHistory();

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [providerDeleteID, setProviderDeleteID] = useState(null);

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleOpenDeleteModal = providerID => {
        setProviderDeleteID(providerID);
        setOpenDeleteModal(true);
    };

    const handleEdit = id => {
        history.push(`/proveedores/editar/${id}`);
    };

    const handleClickListItem = id => {
        history.push(`/proveedores/${id}`);
    };

    return (
        <>
            <List>
                {providers.map((item, index) => {
                    return (
                        <div key={`provider[${item.company}]`}>
                            <ListItem
                                button
                                onClick={() => handleClickListItem(item.id)}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'h6',
                                    }}
                                    primary={item.company}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={() => handleEdit(item.id)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            handleOpenDeleteModal(item.id)
                                        }>
                                        <Delete />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {index !== providers.length - 1 && <Divider />}
                        </div>
                    );
                })}
            </List>
            <ProvidersDelete
                open={openDeleteModal}
                handleClose={handleCloseDeleteModal}
                providerID={providerDeleteID}
            />
        </>
    );
};

ProvidersList.propTypes = {
    providers: PropTypes.array,
};

export default ProvidersList;
