import { useState, useEffect, useReducer } from 'react';
import { dataFetchReducer, TYPES } from './reducer';

const useFetch = (query, initialData, callback) => {
    const [request, setRequest] = useState(query);
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: initialData,
    });

    useEffect(() => {
        if (request) {
            let didCancel = false;
            const fetchData = async () => {
                dispatch({ type: TYPES.FETCH_INIT });
                try {
                    const data = await request;
                    if (callback) {
                        callback(data);
                    }
                    if (!didCancel) {
                        dispatch({ type: TYPES.FETCH_SUCCESS, payload: data });
                    }
                } catch (error) {
                    if (!didCancel) dispatch({ type: TYPES.FETCH_FAILURE });
                }
            };
            fetchData();
            return () => {
                didCancel = true;
            };
        }
    }, [request]);

    const setData = data => {
        dispatch({ type: TYPES.FETCH_SUCCESS, payload: data });
    };

    return [state, setRequest, setData];
};

export default useFetch;
