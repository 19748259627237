import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Add from '@material-ui/icons/Add';

import FloatingActionButton from '@sharedComponents/FloatingButton/FloatingActionButton';
import SearchComponent from '@sharedComponents/SearchComponent';
import VerifyArray from '@sharedComponents/VerifyArray';
import useValidItems from '@hooks/validItemsHook'
import useSearch from '@hooks/searchHook';
import ProvidersList from '../ProvidersList';

function Providers() {
    const { providers } = useSelector(state => state.providers);
    const activeProviders = useValidItems(providers)
    const [filteredProviders, setSearch] = useSearch(activeProviders, 'company');

    return (
        <>
            <SearchComponent title='proovedor' setSearch={setSearch} flex />
            <VerifyArray
                array={filteredProviders}
                defaultItem='proovedor'
                redirect='/proveedores/crearproveedor'>
                <ProvidersList providers={filteredProviders} />
            </VerifyArray>
            <Link to='/proveedores/crearproveedor'>
                <FloatingActionButton
                    icon={<Add />}
                    aria='Add a provider'
                    tooltip='Add a new Provider'
                />
            </Link>
        </>
    );
}

export default Providers;
