export const SET_DISTRIBUTIONS = 'SET_DISTRIBUTIONS'

export const ALL_DISTRIBUTION_DATA_LOADING = 'ALL_DISTRIBUTION_DATA_LOADING'
export const ALL_DISTRIBUTION_DATA_SUCCSESS = 'ALL_DISTRIBUTION_DATA_SUCCSESS'
export const ALL_DISTRIBUTION_DATA_ERROR = 'ALL_DISTRIBUTION_DATA_ERROR'

export const ADD_DISTRIBUTION_SUCCSESS = 'ADD_DISTRIBUTION_SUCCSESS'
export const ADD_DISTRIBUTION_LOADING = 'ADD_DISTRIBUTION_LOADING'
export const ADD_DISTRIBUTION_ERROR = 'ADD_DISTRIBUTION_ERROR'

export const DELETE_DISTRIBUTION_LOADING = 'DELETE_DISTRIBUTION_LOADING'
export const DELETE_DISTRIBUTION_SUCCSESS = 'DELETE_DISTRIBUTION_SUCCSESS'
export const DELETE_DISTRIBUTION_ERROR = 'DELETE_DISTRIBUTION_ERROR'

export const EDIT_DISTRIBUTION_LOADING = 'EDIT_DISTRIBUTION_LOADING'
export const EDIT_DISTRIBUTION_SUCCSESS = 'EDIT_DISTRIBUTION_SUCCSESS'
export const EDIT_DISTRIBUTION_ERROR = 'EDIT_DISTRIBUTION_ERROR'