import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './styles';
import CuantityCont from '@sharedComponents/CuantityCont';
import {
    formatDateTimeToDmy,
    getDateFromDateOrTimestamp,
} from '../../../utils/utils';
import { useDispatch } from 'react-redux';
import { updateSellsForEdition } from '@redux/actions/editions';

function NewsPaperItem({ classes, editionToSell, updateEdition }) {

    const dispatch = useDispatch()
    const handleSetCount = sells => {

        sells = Math.ceil(sells);
        if (sells < 0) sells = 0;
        else if (sells > 999) sells = 999;

        const diferential =
            editionToSell.sells > sells
                ? editionToSell.sells - sells
                : sells - editionToSell.sells;

        let stock =
            editionToSell.sells > sells
                ? editionToSell.stock + diferential
                : editionToSell.stock - diferential;

        if (stock < 0) {
            sells = sells + stock;
            stock = 0;
        }

        updateEdition({ ...editionToSell, sells, stock });
        dispatch(
            updateSellsForEdition(
                sells,
                stock,
                diferential,
                editionToSell
            )
        );
    };

    return (
        <Grid item xs={12} md={6} className={classes.container}>
            <List>
                <ListItem>
                    <ListItemText
                        primary={editionToSell.productName}
                        secondary={`Fecha: ${formatDateTimeToDmy(
                            getDateFromDateOrTimestamp(editionToSell.date)
                        )}`}
                    />
                    <ListItemSecondaryAction>
                        <CuantityCont count={editionToSell.sells} setCount={handleSetCount} />
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            <Divider />
        </Grid>
    );
}

NewsPaperItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewsPaperItem);
