import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    InputLabel,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDeliveryManToKiosk,
    editDeliveryManToKiosk,
    getDeliveryManFromStore,
} from '@redux/actions';
import Loading from '@sharedComponents/Loading';

const DeliveryManAdd = ({ open, setOpen, edit, editID }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleClose = () => setOpen(false);

    const { addEditDeliveryManLoading } = useSelector(
        state => state.deliveryMan
    );

    const { register, handleSubmit, errors, formState, reset } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { isDirty, isValid } = formState;

    const onSubmit = data => {
        dispatch(
            edit
                ? editDeliveryManToKiosk(editID, data)
                : addDeliveryManToKiosk(data)
        );
    };

    useEffect(() => {
        if (edit && editID) {
            const { name } = getDeliveryManFromStore(editID);
            reset({ name });
        }
    }, [edit, editID, reset]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            aria-labelledby='añadir-editar-repartidor'>
            <DialogTitle id='añadir-editar-repartidor'>
                {edit ? 'Editar' : 'Añadir'} repartidor
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {addEditDeliveryManLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className={classes.nameInput}>
                                <InputLabel htmlFor='name'>
                                    Nombre del repartidor
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    error={!!errors.name}
                                    required
                                    id='name'
                                    type='text'
                                    name='name'
                                    inputRef={register}
                                    helperText={
                                        errors.name && errors.name.message
                                    }
                                />
                            </div>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancelar
                    </Button>
                    <Button
                        type='submit'
                        disabled={!isValid || !isDirty}
                        onClick={handleClose}
                        color='secondary'>
                        Confirmar
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DeliveryManAdd;

DeliveryManAdd.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    edit: PropTypes.bool,
    editID: PropTypes.string,
};
