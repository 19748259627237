import {
    GET_DELIVERIES_ERROR,
    GET_DELIVERIES_LOADING,
    GET_DELIVERIES_SUCSESS,
    SET_DELIVERIES,
} from '../types';
import { dayOfTheWeekSpanish } from '../../utils/utils';
import {
    addDistributionState,
    editDistributionState,
    getDistributionStateForDate,
} from '@services/distributionStates';
import store from '../store';
import {
    editProductFromStoreAndKiosk,
    getLastEditionOfProduct,
    getLastEditionOfProducts,
} from './products';
import { DELIVERY_STATES } from '@constants/deliveryStates';
import { getClientByIDFromStore } from './clients.js';
import { getDistributionOrderForDate } from '@services/distributionOrders';
import { getIfValidArrayOfItem, getIfValidItem } from '.';
import { DELIVERED } from '@constants/distributionStates';
import { getProductByIDFromStore } from './products';
import { editEditionFromKioskAndStore } from './editions';

// GET DELIVERIES
const setStateInDist = async (dist, date) => {
    let state = await getDistributionStateForDate(dist.id, date);
    console.log(state);
    if (!state) {
        state = {
            editions: [],
            date,
            state: 1,
        };
    } else {
        state = { ...state, date: state.date.toDate() };
    }
    return { ...dist, state };
};

const getDistributionsForGroup = async (groupID, date) => {
    const dayOfTheWeek = dayOfTheWeekSpanish[date.getDay()];
    const { distributions } = store.getState().distributions;

    const groupFiltered = distributions.filter(
        dist => dist.groupID === groupID
    );

    const dateFiltered = groupFiltered.filter(dist => {
        if (dist.isPeriodic) {
            const includesDayOfTheWeek = dist.periodicDays.includes(
                dayOfTheWeek
            );
            const validDist = getIfValidItem(dist, date); // si el reparto esta activo o si esta inactivo pero la fecha de baja es >= fecha

            return includesDayOfTheWeek && validDist;
        } else {
            return true; //por ahora, despues tiene que retornar si es el dia exacto
        }
    });

    const statePromises = dateFiltered.map(dist => setStateInDist(dist, date));
    let distsWithState = [];
    if (statePromises.length > 0) {
        const promises = statePromises.map(p => p.catch(e => e));
        await Promise.all(promises).then(distsWithStateResponse => {
            distsWithState = distsWithStateResponse;
        });
    }

    return distsWithState;
};

const buildGroupPromise = async (group, date) => {
    const dist4Group = await getDistributionsForGroup(group.id, date);
    return { ...group, dist: dist4Group };
};

const getFilteredGroups = async date => {
    const { deliveryGroups } = store.getState().deliveryGroups;

    const validDeliveryGroups = getIfValidArrayOfItem(deliveryGroups, date);

    const distributionsForGroupPromises = validDeliveryGroups.map(group =>
        buildGroupPromise(group, date)
    );
    let buildedGroups = [];
    if (distributionsForGroupPromises.length > 0) {
        const promises = distributionsForGroupPromises.map(p =>
            p.catch(e => e)
        );
        await Promise.all(promises).then(_buildedGroups => {
            buildedGroups = [..._buildedGroups];
        });
    }
    const filteredGroups = buildedGroups.filter(group => group.dist.length > 0);

    return filteredGroups;
};

//ORDER
const getCorrectOrder = async (date, groupID) => {
    let correctOrder;
    const dateOrder = await getDistributionOrderForDate(groupID, date);
    if (dateOrder) {
        correctOrder = dateOrder;
    } else {
        const lastWeekDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - 7
        );
        const lastWeekOrder = await getDistributionOrderForDate(
            groupID,
            lastWeekDate
        );
        if (lastWeekOrder) {
            correctOrder = lastWeekOrder;
        } else {
            const yesterdayDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() - 1
            );
            const yesterdayOrder = await getDistributionOrderForDate(
                groupID,
                yesterdayDate
            );
            if (yesterdayOrder) {
                correctOrder = yesterdayOrder;
            } else {
                correctOrder = null;
            }
        }
    }
    return correctOrder;
};

const buildOrderPromise = async (date, group) => {
    const databaseOrder = await getCorrectOrder(date, group.id);

    const groupDists = [...group.dist];
    const newOrder = [];
    let newID = null;

    if (databaseOrder) {
        databaseOrder.order.forEach(distributionID => {
            const distIndex = groupDists.findIndex(
                distribution => distribution.id === distributionID
            );
            if (distIndex > -1) {
                newOrder.push(distributionID);
                groupDists.splice(distIndex, 1);
            }
        });

        if (databaseOrder.date.toDate().getDate() === date.getDate()) {
            newID = databaseOrder.id;
        }
    }

    newOrder.push(...groupDists.map(group => group.id));

    const order = {
        id: newID,
        date: date,
        order: newOrder,
        groupID: group.id,
    };

    return order;
};

const getBuildedOrders = async (date, filteredGroups) => {
    const distributionOrderPromises = filteredGroups.map(group =>
        buildOrderPromise(date, group)
    );
    let buildedOrders = [];
    if (distributionOrderPromises.length > 0) {
        const promises = distributionOrderPromises.map(p => p.catch(e => e));
        await Promise.all(promises).then(_buildedOrders => {
            buildedOrders = [..._buildedOrders];
        });
    }

    return buildedOrders;
};

const orderGroups = (filteredGroups, buildedOrders) => {
    const orderedGroups = filteredGroups.map(group => {
        const orderForGroup = buildedOrders.find(
            order => order.groupID === group.id
        );
        const orderedDist = [];
        orderForGroup.order.forEach(distributionID => {
            const dist = group.dist.find(
                distribution => distribution.id === distributionID
            );
            orderedDist.push(dist);
        });
        return { ...group, dist: orderedDist };
    });
    return orderedGroups;
};

//MAIN GET DELIVERY
export const getDeliveries = date => {
    date.setHours(0, 0, 0);
    return async dispatch => {
        dispatch({ type: GET_DELIVERIES_LOADING });
        try {
            const filteredGroups = await getFilteredGroups(date);
            const buildedOrders = await getBuildedOrders(date, filteredGroups);
            const orderedGroups = orderGroups(filteredGroups, buildedOrders);

            dispatch({
                type: GET_DELIVERIES_SUCSESS,
                payload: { groups: orderedGroups, orders: buildedOrders },
            });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_DELIVERIES_ERROR, payload: error });
        }
    };
};

//CHANGE DELIVERY STATES
const changeExistentDeliveryState = async (delivery, newState) => {
    const state = {
        productEditions: delivery.state.productEditions,
        date: delivery.state.date,
        state: Number(newState),
    };
    await editDistributionState(delivery.id, delivery.state.id, state);
    return { ...delivery, state: { ...state, id: delivery.state.id } };
};

const createNewDeliveryState = async (delivery, newState) => {
    const productList = delivery.inDistProductRows.map(
        inDistProductRow => inDistProductRow.inDistProduct.productID
    );
    const lastEditionsOfProducts = await getLastEditionOfProducts(productList);

    const state = {
        date: delivery.state.date,
        state: Number(newState),
        productEditions: lastEditionsOfProducts,
    };
    const stateAdded = await addDistributionState(delivery.id, state);
    return { ...delivery, state: { ...state, id: stateAdded.id } };
};

export const changeDeliveryStates = (
    deliveries,
    deliveryState,
    newState,
    callBack
) => {
    return async dispatch => {
        try {
            const deliveryStatePromises = deliveries.map(delivery => {
                if (delivery.state.id) {
                    return changeExistentDeliveryState(delivery, newState);
                } else {
                    return createNewDeliveryState(delivery, newState);
                }
            });

            if (deliveryStatePromises.length > 0) {
                const promises = deliveryStatePromises.map(p =>
                    p.catch(e => e)
                );
                await Promise.all(promises).then(response => {
                    dispatch(changeDeliveriesInStore(response));
                    dispatch(
                        updateDeliverySells(response, deliveryState, newState)
                    );
                    if (callBack) {
                        callBack();
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateDeliverySells = (deliveries, deliveryState, newState) => {
    return async dispatch => {
        try {
            const deliveryStatePromises = deliveries.map(delivery =>
                dispatch(
                    updateSellsForDelivery(delivery, deliveryState, newState)
                )
            );

            if (deliveryStatePromises.length > 0) {
                const promises = deliveryStatePromises.map(p =>
                    p.catch(e => e)
                );
                await Promise.all(promises);
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateSellsForDelivery = (delivery, deliveryState, newState) => {
    return async dispatch => {
        const addToSells = newState === DELIVERED;
        const removeSells = deliveryState === DELIVERED && !addToSells;
  
        if (addToSells || removeSells) {
            const productList = delivery.inDistProductRows.map(
                inDistProductRow =>
                    dispatch(
                        updateInDistProductRowsSell(
                            inDistProductRow,
                            removeSells
                        )
                    )
            );
            if (productList.length > 0) {
                const promises = productList.map(p => p.catch(e => e));
                await Promise.all(promises);
            }
        }
    };
};

export const updateInDistProductRowsSell = (inDistProductRow, removeSells) => {
    return async dispatch => {
        const amount = Number(inDistProductRow.amount);
        const { productID } = inDistProductRow.inDistProduct;
        const product = getProductByIDFromStore(productID);
        const lastEdition = await getLastEditionOfProduct(productID);

        if (removeSells) {
            product.selled -= amount;
            product.existences += amount;

            lastEdition.sells -= amount;
            lastEdition.stock += amount;
        } else {
            product.selled += amount;
            product.existences -= amount;

            lastEdition.sells += amount;
            lastEdition.stock -= amount;
        }

        await dispatch(editProductFromStoreAndKiosk(product));
        await dispatch(editEditionFromKioskAndStore(lastEdition));
    };
};

export const changeDeliveriesInStore = mutatedDist => {
    return dispatch => {
        let { deliveries } = store.getState().delivery;

        mutatedDist.forEach(mutatedDist => {
            deliveries.forEach((delivery, deliveryIndex) => {
                const distIndex = delivery.dist.findIndex(
                    dist => dist.id === mutatedDist.id
                );
                if (distIndex !== -1) {
                    deliveries[deliveryIndex].dist[distIndex] = {
                        ...mutatedDist,
                    };
                }
            });
        });
        dispatch({ type: SET_DELIVERIES, payload: deliveries });
    };
};

//BUILD ACTIVE ROUTE GROUP

export const buildActiveRouteGroup = (deliveries, groupID) => {
    const group = deliveries.find(delivery => delivery.id === groupID);
    let routes = [];
    if (group && group.dist.length > 0) {
        routes = group.dist.map((distribution, index) => ({
            origin:
                index === 0
                    ? group.coordinates
                    : group.dist[index - 1].coordinates,
            destination: distribution.coordinates,
            clientName: getClientByIDFromStore(distribution.clientID).name,
            delivery: distribution.inDistProductRows.map(
                inDistProductRow => inDistProductRow.inDistProduct.productName
            ),
            state: [2, 3, 4].includes(distribution.state.state)
                ? DELIVERY_STATES.visited
                : DELIVERY_STATES.next,
        }));
        const activeIndex = routes.findIndex(
            distribution => distribution.state === DELIVERY_STATES.next
        );
        if (activeIndex !== -1) {
            routes[activeIndex].state = DELIVERY_STATES.active;
        }
        routes = routes.filter((distribution, index) =>
            activeIndex === -1
                ? true
                : !(
                      index > activeIndex &&
                      distribution.state === DELIVERY_STATES.visited
                  )
        );
    }
    return routes;
};
