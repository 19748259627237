import {
    ADD_EDIT_DELIVERY_GROUP_LOADING,
    ADD_EDIT_DELIVERY_GROUP_ERROR,
    ADD_EDIT_DELIVERY_GROUP_SUCCESS,
    DELETE_DELIVERY_GROUP_LOADING,
    DELETE_DELIVERY_GROUP_ERROR,
    DELETE_DELIVERY_GROUP_SUCCESS,
    SET_GROUPS,
} from '../types';

const initialState = {
    addEditDeliveryGroupLoading: false,
    addEditDeliveryGroupError: false,
    deliveryGroups: [],
    deleteDeliveryGroupLoading: false,
    deleteDeliveryGroupError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_EDIT_DELIVERY_GROUP_LOADING:
            return {
                ...state,
                addEditDeliveryGroupLoading: true,
                addEditDeliveryGroupError: false,
            };
        case ADD_EDIT_DELIVERY_GROUP_ERROR:
            return {
                ...state,
                addEditDeliveryGroupLoading: false,
                addEditDeliveryGroupError: payload,
            };
        case ADD_EDIT_DELIVERY_GROUP_SUCCESS:
            return {
                ...state,
                addEditDeliveryGroupLoading: false,
                addEditDeliveryGroupError: false,
                deliveryGroups: [...payload],
            };
        case SET_GROUPS:
            return {
                ...state,
                deliveryGroups: [...payload],
            };
        case DELETE_DELIVERY_GROUP_LOADING:
            return {
                ...state,
                deleteDeliveryGroupLoading: true,
                deleteDeliveryGroupError: false,
            };
        case DELETE_DELIVERY_GROUP_ERROR:
            return {
                ...state,
                deleteDeliveryGroupLoading: false,
                deleteDeliveryGroupError: payload,
            };
        case DELETE_DELIVERY_GROUP_SUCCESS:
            return {
                ...state,
                deleteDeliveryGroupLoading: false,
                deleteDeliveryGroupError: false,
            };
        default:
            return state;
    }
};
