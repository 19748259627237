export * from './auth';
export * from './deliveryGroups';
export * from './deliveryMan';

export const getIfValidArrayOfItem = (array, date) => {
    return array.filter(item => getIfValidItem(item, date));
};

export const getIfValidItem = (item, date) => {
    const active = item.active;
    let endDateIsPreviousToDate = false;
    if (date && item.endDate) {
        if (item.endDate instanceof Date) {
            endDateIsPreviousToDate = item.endDate.getTime() >= date.getTime();
        } else {
            const fecha = item.endDate.toDate()
            endDateIsPreviousToDate = fecha.getTime() >= date.getTime();
        }
    }

    return active || endDateIsPreviousToDate;
};
