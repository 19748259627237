import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    tags: {
        marginBottom: theme.spacing(15),
        paddingRight: theme.spacing(2),
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    chipsContainer: {
        maxWidth: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));
