import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import Loading from '@sharedComponents/Loading';
import { loadScript } from '../../utils/utils';
import credentials from '../../credentials';

const googleURL = `https://maps.googleapis.com/maps/api/js?v=3.ex´&libraries=geometry,drawing,places&key=${
    credentials.mapsKey
}`;

const MainLoader = ({ children }) => {
    const history = useHistory();
    const loaded = useRef(false);

    const { isComplete, isLoading } = useSelector(state => state.mainLoader);

    useEffect(() => {
        if (isComplete) {
            history.push('/inventario');
        }
    }, [isComplete]);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                googleURL,
                document.querySelector('head'),
                'google-maps'
            );
        }

        loaded.current = true;
    }

    return <>{isLoading ? <Loading /> : children}</>;
};

export default MainLoader;

MainLoader.propTypes = {
    children: PropTypes.node,
}