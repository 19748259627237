import { useState, useEffect } from 'react';

import { getIfValidArrayOfItem } from '@redux/actions';

const useValidItems = (items, date) => {
    const [activeItems, setActiveItems] = useState([]);

    useEffect(() => {
        setActiveItems(getIfValidArrayOfItem(items, date));
    }, [items, date]);

    return activeItems;
};

export default useValidItems;
