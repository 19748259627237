import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    Select,
    Chip,
    MenuItem,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { SET_TAGS_FILTER } from '@redux/types';
import { getStyles, MenuProps } from './helpers';
import tags from '@constants/tags';
import { useStyles } from './styles';

const TagModal = ({ open, setOpen }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const handleClose = () => setOpen(false);

    const { tags: _tags } = useSelector(state => state.inventory);
    const [tag, setTag] = useState(_tags);

    const handleFilter = () => {
        dispatch({ type: SET_TAGS_FILTER, payload: tag });
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='sm'
                aria-labelledby='label-modal'>
                <DialogTitle id='label-modal'>
                    Filtrar por Etiquetas
                </DialogTitle>
                <DialogContent>
                    <FormControl className={classes.tags} fullWidth={true}>
                        <InputLabel>Etiquetas</InputLabel>
                        <Select
                            multiple
                            value={tag}
                            onChange={e => setTag(e.target.value)}
                            input={<Input />}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip
                                            key={value}
                                            label={value}
                                            className={classes.chip}
                                        />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}>
                            {tags.map(name => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, tag, theme)}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancelar
                    </Button>
                    <Button onClick={handleFilter} color='secondary'>
                        Filtrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TagModal;

TagModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
};
