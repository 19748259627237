import React from 'react';
import NavTabs from '@sharedComponents/NavTab';
import GroupList from '../List'
import DeliveryMan from '../../DeliveryManFolder/DeliveryMan'

const tabItems = [
    {
        label: 'Grupos',
        component: GroupList,
    },
    {
        label: 'Repartidores',
        component: DeliveryMan,
    },
];

const Groups = () => {
    return <NavTabs tabsData={tabItems} subNavTab />;
};

export default Groups;
