import { addNote, deleteNote, editNote, getNotes } from '@services/notes';
import store from '../store';

import {
    SET_NOTES,
    SET_NOTES_ERROR,
    SET_NOTES_LOADING,
    ADD_NOTES_LOADING,
    ADD_NOTES_SUCCSESS,
    ADD_NOTES_ERROR,
    EDIT_NOTES_LOADING,
    EDIT_NOTES_SUCCSESS,
    EDIT_NOTES_ERROR,
    DELETE_NOTES_LOADING,
    DELETE_NOTES_SUCCSESS,
    DELETE_NOTES_ERROR,
} from '../types';

export const getNotesSorted = () => {
    return async dispatch => {
        dispatch({ type: SET_NOTES_LOADING });
        try {
            const notesResponse = await getNotes();
            const notes = notesResponse.sort((a, b) => b.date - a.date);
            dispatch({ type: SET_NOTES, payload: notes });
        } catch (error) {
            dispatch({ type: SET_NOTES_ERROR, payload: error });
        }
    };
};

export const getNoteByIDFromStore = noteID => {
    const { notes } = store.getState().notes;
    return notes.find(_note => _note.id === noteID);
};

export const editNoteFromStore = (id, text, callback) => {
    return async dispatch => {
        dispatch({ type: EDIT_NOTES_LOADING });
        try {
            await editNote(id, { text, date: new Date() });
            dispatch({ type: EDIT_NOTES_SUCCSESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: EDIT_NOTES_ERROR, payload: error });
        }
    };
};

export const addNoteFromStore = (text, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_NOTES_LOADING });
        try {
            await addNote({ text, date: new Date() });
            dispatch({ type: ADD_NOTES_SUCCSESS });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_NOTES_ERROR, payload: error });
        }
    };
};
 
export const deleteNotesArray = (notes, callback) => {
    return async dispatch => {
        dispatch({ type: DELETE_NOTES_LOADING });
        try {
            const deletePromises = notes.map(noteID => deleteNote(noteID));
            if (deletePromises.length > 0) {
                const promises = deletePromises.map(p => p.catch(e => e));
                await Promise.all(promises);
                dispatch({ type: DELETE_NOTES_SUCCSESS });
                if (callback) {
                    callback();
                }
            }
        } catch (error) {
            dispatch({ type: DELETE_NOTES_ERROR, payload: error });
        }
    };
};
