import {
    addProvider,
    editProvider,
    getProviders,
} from '@services/providers';
import store from '../store';
import {
    SET_PROVIDERS,
    SET_PROVIDERS_ERROR,
    SET_PROVIDERS_LOADING,
    EDIT_PROVIDERS_LOADING,
    EDIT_PROVIDERS_SUCCSESS,
    EDIT_PROVIDERS_ERROR,
    ADD_PROVIDERS_LOADING,
    ADD_PROVIDERS_SUCCSESS,
    ADD_PROVIDERS_ERROR,
} from '../types';

export const getProvidersFromStore = () => {
    return async dispatch => {
        dispatch({ type: SET_PROVIDERS_LOADING });
        try {
            const providers = await getProviders();
            dispatch({ type: SET_PROVIDERS, payload: providers });
        } catch (error) {
            console.error(error);
            dispatch({ type: SET_PROVIDERS_ERROR, payload: error });
        }
    };
};

export const getProviderByIDFromStore = providerID => {
    const { providers } = store.getState().providers;
    return providers.find(_provider => _provider.id === providerID);
};

export const inactivateProviderFromStore = (providerID, callback) => {
    return dispatch => {
        const provider = getProviderByIDFromStore(providerID)

        delete provider.id;
        provider.active = false;
        provider.endDate = new Date();

        dispatch(editProviderFromStore(providerID, provider, callback));
    };
};

export const editProviderFromStore = (id, data, callback) => {
    return async dispatch => {
        dispatch({ type: EDIT_PROVIDERS_LOADING });
        try {
            await editProvider(id, data);
            let { providers } = store.getState().providers;
            const providerIndex = providers.findIndex(prov => prov.id === id);
            providers[providerIndex] = { ...data, id };
            dispatch({ type: EDIT_PROVIDERS_SUCCSESS, payload: providers });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: EDIT_PROVIDERS_ERROR, payload: error });
        }
    };
};

export const addProviderFromStore = (data, callback) => {
    return async dispatch => {
        dispatch({ type: ADD_PROVIDERS_LOADING });
        data.active = true;
        try {
            const provider = await addProvider(data);
            const { providers } = store.getState().providers;
            providers.push({ ...data, id: provider.id });
            dispatch({ type: ADD_PROVIDERS_SUCCSESS, payload: providers });
            if (callback) {
                callback();
            }
        } catch (error) {
            dispatch({ type: ADD_PROVIDERS_ERROR, payload: error });
        }
    };
};
