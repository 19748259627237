export const CREATE_NEW_EDITION_INBOX_MAGAZINE =
    'CREATE_NEW_EDITION_INBOX_MAGAZINE';
export const NEW_EDITION_LOADING = 'NEW_EDITION_LOADING';
export const NEW_EDITION_ERROR = 'NEW_EDITION_ERROR';
export const CLEAR_NEW_EDITION_INBOX_MAGAZINE =
    'CLEAR_NEW_EDITION_INBOX_MAGAZINE';
export const CLEAR_OUTBOX_MAGAZINE = 'CLEAR_OUTBOX_MAGAZINE';
export const DELETE_EDITION_LOADING = 'DELETE_EDITION_LOADING';
export const DELETE_EDITION_ERROR = 'DELETE_EDITION_ERROR';
export const DELETE_EDITION_OK = 'DELETE_EDITION_OK';
export const EDIT_EDITION_LOADING = 'EDIT_EDITION_LOADING';
export const EDIT_EDITION_OK = 'EDIT_EDITION_OK';
export const EDIT_EDITION_ERROR = 'EDIT_EDITION_ERROR';
