import { wrapKioskQuery } from './kiosk';

export const _getEditions = async (productID, kioskReference) => {
    const productRef = kioskReference.collection('products').doc(productID);
    const editionsSnapshot = productRef.collection('editions').get();
    return (await editionsSnapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
}

const _addEdition = async (productID, data, kioskReference) => {
    const productRef = kioskReference.collection('products').doc(productID);
    const editionsRef = productRef.collection('editions');
    const docRef = await editionsRef.add({ ...data });
    return docRef;
};

const _deleteEdition = async (productID, editionID, kioskReference) => {
    const productRef = kioskReference.collection('products').doc(productID);
    const editionRef = productRef.collection('editions').doc(editionID);
    await editionRef.delete()
};

const _editEdition = async (productID, editionID, data, kioskReference) => {
    const productRef = kioskReference.collection('products').doc(productID);
    const editionRef = productRef.collection('editions').doc(editionID);
    await editionRef.update(data);
}

const _getLastEdition = async (productID, kioskReference) => {
    const productRef = kioskReference.collection('products').doc(productID);
    const lastEditionSnapshot = productRef.collection('editions').orderBy('date', 'desc').limit(1).get();
    return (await lastEditionSnapshot).docs.map(doc => ({ ...doc.data(), id: doc.id }));
};

export const addEdition = wrapKioskQuery(_addEdition);
export const deleteEdition = wrapKioskQuery(_deleteEdition);
export const getLastEdition = wrapKioskQuery(_getLastEdition);
export const getEditions = wrapKioskQuery(_getEditions);
export const editEdition = wrapKioskQuery(_editEdition);